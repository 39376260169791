import { useFormikContext } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import LabelRequired from '../../../../../component/LabelRequired';
import TextField from '../../../../../component/TextField';
import { IDanhSachDanhMuc } from '../../../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import { KEY_DS_DANH_MUC_TIEP_DON } from '../../../../../utils/Constant';
import { getIndexedDBItem } from '../../../../../utils/IndexedDB';
import ICD10 from '../../../../components/ICD10';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';

const ThongTinBenh = () => {
	const { values, setFieldValue, errors, setValues } = useFormikContext<any>();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const [listICD10, setListICD10] = useState<any>([]);
	const [listComorbidity, setListComorbidity] = useState<any[]>([]);
	const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);
	const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
	const [selectionModeICD10, setSelectionModeICD10] = useState<
		"checkbox" | "radio"
	>("radio");

	const handleOpenSelectICD10 = () => {
		setShouldOpenModalSelectICD10(true);
	};

	useEffect(() => {
		getDanhMucICD10();
	}, []);

	const getDanhMucICD10 = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setListICD10(danhMuc.listICD10);
	};

	return (
		<>
			<div className="d-flex justify-content-between pt-3 w-80">
				<div className="spaces mb-4 d-flex align-items-center">
					<TextField
						label="Ngày y lệnh"
						name="ngayYLenh"
						labelClassName="ms-0 min-w-120-px"
						value={moment(
							values?.ngayYLenh
								? values?.ngayYLenh
								: new Date()
						).format('YYYY-MM-DDTHH:mm')}
						type="datetime-local"
						onChange={(e: any) => setFieldValue('ngayYLenh', e.target.value)}
					/>
					<FormCheck
						className="ms-3 mt-1"
						label="Thu tiền"
						type="checkbox"
						name="thuTien"
						checked={values.thuTien}
						onChange={e => setFieldValue('thuTien', e.target.checked)}
						isInvalid={!!errors.thuTien}
					/>
				</div>
				<Button
					className="bg-white spaces W-85 py-0 pr-5 mt-4"
					onClick={handleOpenSelectICD10}
				>
					<u className="fw-bold text-pri fs-13px">Chọn bệnh</u>
				</Button>
			</div>

			<div className="d-flex flex-wrap w-80">
				<div className="spaces position-relative">
					<TextField
						label="Chẩn đoán"
						className="spaces w-100"
						inputClassName="w-100"
						name="provisionalDiagnosis"
						labelClassName="ms-0 min-w-120-px"
						onChange={(e: any) =>
							setFieldValue(e.target.name, e.target.value.toUpperCase())
						}
					/>
					<div className="spaces width-4">
						<h6
							className="position-absolute muc-huong cursor-pointer"
							onClick={() => {
								if (benhNhanInfo?.thongTinKhamBenh.isExamined) {
									setShouldOpenModalICD10(true);
									setSelectionModeICD10("radio");
								}
							}}
						>
							<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
						</h6>
					</div>
				</div>
				<div className="flex-grow-1">
					<TextField
						inputClassName="w-100"
						name="provisionalDiagnosisLabel"
						value={
							Boolean(listICD10.length)
								? listICD10.find(
										(item: any) =>
											item.code === values.provisionalDiagnosis?.toUpperCase()
								  )?.name
								: ''
						}
						labelClassName="ms-0 min-w-95px"
					/>
				</div>
			</div>

			<div className="spaces mt-4 w-80">
				<div className="d-flex">
					<div className="d-flex">
						<LabelRequired
							label="Bệnh kèm theo"
							className="d-inline-block spaces min-w-120-px text-truncate"
						/>
					</div>
					<div className="spaces position-relative w-100">
						<TextField
							className="w-100 d-flex flex-column align-items-start"
							inputClassName="flex-auto spaces h-25 radius-3px pre-line"
							name="comorbidity"
							labelClassName="ms-0 min-w-145px"
							readOnlyText
						/>
					</div>
				</div>
			</div>

			<ICD10
				shouldOpenModalICD10={shouldOpenModalICD10}
				setShouldOpenModalICD10={setShouldOpenModalICD10}
				shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
				setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
				listICD10={listICD10}
				listComorbidity={listComorbidity}
				setListComorbidity={setListComorbidity}
				selectionModeICD10={selectionModeICD10}
				data={values}
				onChange={(value) => setValues({
					...values,
					...value,
				})}
			/>
		</>
	);
};

export default ThongTinBenh;
