import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RowMouseEventHandlerParams, TableCellProps } from 'react-virtualized';
import { useDebounce } from '../../../../../_metronic/helpers';
import { ConfirmDialog } from '../../../component/ConfirmDialog';
import CustomIconButton from '../../../component/custom-icon-button/CustomIconButton';
import InputSearch from '../../../component/InputSearch';
import SimpleTable, { columnNamesType } from '../../../component/table/simple-table/SimpleTable';
import { CODE_CONTEXT_BENH_NHAN } from '../../../phan-he-chuyen-khoa/constants/PhanHeChuyenKhoaConstants';
import { CODE, RESPONSE_MESSAGE } from '../../../utils/Constant';
import { localStorageItem } from '../../../utils/LocalStorage';
import { IMauKetQuaCDHAItem } from '../../models/ModelsPhanHeCDHAVaTDCN';
import {
	getDanhSachMauKetQuaCDHA,
	deleteMauKetQuaCDHA,
} from '../../services/PhanHeCDHAVaTDCNService';

type Props = {
	handleClose: () => void;
	selectMauKetQua: (row: any) => void;
	thongTinPhieu: {
		termId: string;
	};
	onEdit?: (row: any) => void;
};

interface ColumnType extends columnNamesType {
	textAlign?: string;
}

const DanhSachMauKetQuaModal = ({ handleClose, selectMauKetQua, thongTinPhieu, onEdit }: Props) => {
	const [selectedRow, setSelectedRow] = useState<any>();
	const [tableData, setTableData] = useState<IMauKetQuaCDHAItem[]>([]);
	const [keySearch, setKeySearch] = useState('');
	const [deleteRow, setDeleteRow] = useState<{
		open: boolean;
		data: any;
	}>({
		open: false,
		data: null,
	});

	const userInfo = localStorageItem.get('current-user');
	const keySearchDebounce = useDebounce(keySearch, 700);

	useEffect(() => {
		getTableData(keySearchDebounce);
	}, [keySearchDebounce]); 

	const getTableData = async (keySearch: string = '') => {
		try {
			const { code, data } = await getDanhSachMauKetQuaCDHA({
				createdBy: userInfo?.sub,
				name: keySearch,
				termId: thongTinPhieu.termId,
				pageIndex: 1,
				pageSize: 50,
			});

			if (CODE.SUCCESS === code && data) {
				setTableData(data.content);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
		const inputValue = event.target.value;
		setKeySearch(inputValue.trim());
	};

	const onRenderCell = (columnData: any, dataKey: string, rowData: any) => {
		return rowData[dataKey];
	};

	const onDeleteMauKetQua = async () => {
		try {
			const { code } = await deleteMauKetQuaCDHA(deleteRow.data?.id);
			if (CODE.SUCCESS === code) {
				toast.success('Xóa mẫu kết quả thành công');
				setDeleteRow({
					open: false,
					data: null,
				});
				getTableData();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const columnMauDSKetQuaThucHien: ColumnType[] = useMemo<ColumnType[]>(() => {
		return [
			{
				name: 'Chọn',
				field: 'radio',
				width: 80,
				textAlign: 'center',
			},
			{
				name: 'Thao tác',
				field: '',
				width: 100,
				textAlign: 'center',
				cellRenderer: ({ rowData }: TableCellProps) => {
					return (
						<div className="d-flex justify-content-center">
							<CustomIconButton
								variant="edit"
								title="Sửa mẫu kết quả"
								onClick={() => {
									onEdit?.(rowData);
									handleClose();
								}}
							>
								<i className="bi bi-pencil-square text-navy"></i>
							</CustomIconButton>
							<CustomIconButton
								variant="delete"
								onClick={() => {
									setDeleteRow({
										open: true,
										data: rowData,
									});
								}}
								title="Xóa mẫu kết quả"
							>
								<i className="bi bi-trash3-fill text-danger"></i>
							</CustomIconButton>
						</div>
					);
				},
			},
			{
				name: 'Tên mẫu',
				field: 'name',
				width: 400,
				minWidth: 400,
			},
		];
	}, []);

	return (
		<Modal show animation centered className="dialog-background" size="lg" onHide={handleClose}>
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Chọn mẫu kết quả dịch vụ</Modal.Title>
				<button className="btn-close" onClick={handleClose}></button>
			</Modal.Header>

			<Modal.Body className="py-16 px-24 spaces">
				<InputSearch placeholder="Tìm kiếm" handleChange={handleChange} className="mb-2" />
				<SimpleTable
					data={tableData}
					columns={columnMauDSKetQuaThucHien}
					height={400}
					width={1000}
					scrollable={true}
					setDataChecked={selectedRows => setSelectedRow(selectedRows[0])}
					dataChecked={selectedRow ? [selectedRow] : []}
					uniquePrefix="id"
					handleRenderCell={onRenderCell}
					onRowClick={({ rowData }: RowMouseEventHandlerParams) => {
						setSelectedRow(rowData);
					}}
				/>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end">
				<Button className="btn-danger" onClick={handleClose}>
					Đóng
				</Button>
				<Button
					className="btn-fill"
					onClick={() => {
						selectMauKetQua?.(selectedRow.content);
					}}
				>
					<span>Lưu</span>
				</Button>
			</Modal.Footer>

			{deleteRow.open && (
				<ConfirmDialog
					className="z-index-1060"
					show={deleteRow.open}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa mẫu kết quả này không ?"
					yes="Có"
					close="Không"
					onCloseClick={() => setDeleteRow({ open: false, data: null })}
					onYesClick={onDeleteMauKetQua}
				/>
			)}
		</Modal>
	);
};

export default DanhSachMauKetQuaModal;
