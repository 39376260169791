import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';

type Props = {
	open: boolean;
	handleClose: () => void;
	imgSrcs: any[];
	setImageSrcs: (imgSrcs: any) => void;
}

const WebcamCapture = (props: Props) => {
	const { open, handleClose, setImageSrcs, imgSrcs } = props;
	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: "user"
	};
	const [isError, setIsError] = useState<boolean>(false);

	const webcamRef = useRef<Webcam>(null);
	const capture = useCallback(
		() => {
			let arrImg = [...imgSrcs];
			const imageSrc = webcamRef.current?.getScreenshot();
			arrImg.push({ src: imageSrc });
			setImageSrcs(arrImg);
		},
		[webcamRef, imgSrcs]
	);

	return (
		<>
			<Modal show={open} onHide={handleClose} size="lg" centered contentClassName="h-100">
				<Modal.Header className="p-4 header-modal header-modal-cdha">
					<Modal.Title>Kết quả chẩn đoán hình ảnh</Modal.Title>
					<button className="btn-close" onClick={handleClose}></button>
				</Modal.Header>
				<Modal.Body className="dialog-body border-bottom spaces p-10">
					<div className="d-flex flex-column">
						<div className="justify-content-start spaces mb-5">
							<Button
								className="btn-fill spaces min-w-70px"
								onClick={capture}
								disabled={isError}
							>
								Chụp ảnh
							</Button>
						</div>
						<Webcam
							audio={false}
							// height={600}
							ref={webcamRef}
							screenshotFormat="image/jpeg"
							// width={800}
							videoConstraints={videoConstraints}
							onUserMediaError={error => {
								if (error) {
									console.error(error);
									toast.warn('Không có thiết bị nào được kết nối. Vui lòng thử lại!');
									setIsError(true);
								}
							}}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default WebcamCapture;