import { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";

import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { AppContext } from "../appContext/AppContext";
import FilterSidebar from "../component/filter-sidebar/FilterSidebar";
import TextField from "../component/TextField";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  KEY,
  KEY_DS_DANH_MUC_TIEP_DON,
  ORDER_TYPE_ID,
  RESPONSE_MESSAGE,
} from "../utils/Constant";
import BangDsDichVuCDHA from "./components/BangDsDichVuCdha";
import BangDsPhieuCDHA from "./components/BangDsPhieuCdha";
import {
  LIST_ORDER,
  LIST_PHIEU_IN,
  TYPE_SAVE_CDHA,
  WORK_FLOW_STATUS_CDHA,
} from "./constants/Constants";
import { IBenhNhanV3CDHA } from "./models/ModelsPhanHeCDHAVaTDCN";
import ModalThucHienCDHA from "./components/modals/ModalThucHienCDHA";
import "./PhanHeCDHAVaTDCN.scss";
import {
  calculateAge,
  formatDateAdvanceToString,
  formatDateToYYYYMMDD,
} from "../utils/FormatUtils";
import {
  createObsValue,
  getInforBnCDHA,
  getInforPhieuCDHA,
  getRispacsGroups,
  getStatusSummary,
  inPhieuKetQuaCLS,
  returnResult,
  updateTimes,
} from "./services/PhanHeCDHAVaTDCNService";
import WfButton from "../workflows/components/WfButton";
import { WfConstTracker } from "../workflows/Constant";
import {
  getWfWorkflows,
  updateWfWorkflows,
} from "../workflows/services/WfWorkflowsService";
import { getIndexedDBItem } from "../utils/IndexedDB";
import ButtonInPhieu from "../component/button-in-phieu";
import {
  inPhieuChiDinhCLS,
} from "../phan-he-kham-benh/services/PhanHeTiepDonServer";
import LabelRequired from "../component/LabelRequired";
import FormInPhieuDialog from "../component/button-in-phieu/components/PrintDialog";
import { IInPhieuParams } from "../models/params";

const LIST_FORMAL = [
  {
    id: 1,
    value: "1",
    name: "Ngày y lệnh",
    code: "indicationTime",
  },
  {
    id: 3,
    value: "3",
    name: "Ngày thực hiện",
    code: "executionStartTime",
  },
  {
    id: 4,
    value: "4",
    name: "Ngày trả kết quả",
    code: "resultReportingTime",
  },
];
const PhanHeCDHAVaTDCN = () => {
  const { setIsLoading } = useContext(AppContext);
  const [shouldOpenModalHuyThucHien, setShouldOpenModalHuyThucHien] =
    useState<boolean>(false);
  const [shouldOpenModalHuyKetQua, setShouldOpenModalHuyKetQua] =
    useState<boolean>(false);
  const [dataSearched, setDataSearched] = useState<IBenhNhanV3CDHA[]>([]);
  const [openModalInPhieu, setOpenModalInPhieu] = useState(false);
  const [templatePrint, setTemplatePrint] = useState<any>();
  const [openModalThucHien, setOpenModalThucHien] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [dsDichVu, setDsDichVu] = useState<any[]>([]);
  const [isViewModal, setIsViewModal] = useState(true);
  const [statusSummary, setStatusSummary] = useState<any>([]);
  const [workFlowStatus, setWorkFlowStatus] = useState("");
  const [workFlowNewStatus, setWorkFlowNewStatus] = useState<any>(null);
  const [listICD10, setListICD10] = useState<any>([]);
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");
  const [openPrintResultDialog, setOpenPrintResultDialog] = useState(false);
  const [typeSave, setTypeSave] = useState<any>(null)
  const handleDoubleClick = () => {
    setOpenModalThucHien(true);
  };
  const [forceRender, setForceRender] = useState<boolean>(false);
  const getPatientCDHA = async (filter: any) => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 9999999,
      keyword: filter?.keyword,
      isBhyt: filter?.typeCheckBHYT ? true : false,
      isVienPhi: filter?.typeCheckVP ? true : false,
      sortBy: filter?.formal?.code,
      descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
      fromDate: formatDateToYYYYMMDD(filter.period?.fromDate),
      toDate: formatDateToYYYYMMDD(filter.period?.toDate),
      filterBy: filter?.filterBy || null,
      filterValue: filter?.filterValue || null
    };
    try {
      let { data } = await getRispacsGroups(searchObject);
      if (data?.code === CODE.SUCCESS) {
        setDataSearched(data?.data?.groups || [] );
        setStatusSummary(data?.data?.statusSummary || []);
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  const [infoBnSelectedCdha, setInfoBnSelectedCDHA] =
    useState<IBenhNhanV3CDHA>();
  const handleThucHienCDHA = (currStatusId: any) => {
    if (infoBnSelectedCdha) {
      setInfoBnSelectedCDHA({
        ...infoBnSelectedCdha,
        chanDoanHinhAnh: [
          {
            ...infoBnSelectedCdha?.chanDoanHinhAnh?.[0],
            nguoiThucHien: "Quản trị hệ thống",
            ngayThucHien: moment().format("HH:mm DD/MM/YYYY"),
          },
        ],
      } as IBenhNhanV3CDHA);
      if (currStatusId === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code) {
        handleSubmitForm({}, currStatusId);
      } else {
        handleGetInfoBn(infoBnSelectedCdha, true);
      }
      setWorkFlowStatus(currStatusId);
      doSearch(filter);
    } else {
      toast.warning("Vui lòng chọn bệnh nhân thực hiện!");
    }
  };

  const getWorkFlowStatus = async () => {
    try {
      let tracker = WfConstTracker.RISPACS;
      let objectId = infoBnSelectedCdha?.id || "";
      let { data } = await getWfWorkflows(tracker, objectId);
      if (data?.code === CODE.SUCCESS) {
        setWorkFlowNewStatus(data?.data?.newStatusId);
        setWorkFlowStatus(data?.data?.currStatusCode)
      }
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  useEffect(() => {
    if (infoBnSelectedCdha?.id) {
      getWorkFlowStatus();
    }
  }, [infoBnSelectedCdha]);

  useEffect(() => {
    setIsViewModal(
      workFlowStatus !== WORK_FLOW_STATUS_CDHA.DANG_THUC_HIEN.code
    );
  }, [workFlowStatus]);
  const handleSubmitForm = async (values: any, currentId?: any) => {
    let dataSubmit = {
      personId: infoBnSelectedCdha?.patient?.personId,
      orderId: infoBnSelectedCdha?.orders[0]?.orderId,
      orderGroupId: infoBnSelectedCdha?.orderGroupId,
      values: {
        result: values?.noiDungMauKetQua || "",
        startTime: values?.startTime || null,
        endTime: values?.endTime || null,
        executionTime: values?.executionTime || "",
        minimumTime: values?.minimumTime || "",
        conclusion: values?.conclusion || "",
      },
    };
    try {
      setIsLoading(true);
      let { data } = await createObsValue(dataSubmit);
      toast.success(
        currentId === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code
          ? "Hủy thực hiện thành công"
          : "Thực hiện thành công"
      );
      values?.handleGetResult && values?.handleGetResult();
      handleGetInfoBn(infoBnSelectedCdha);
      if(values?.actionType) {
        setTypeSave(values?.actionType)
      }
      
      if (values?.actionType === TYPE_SAVE_CDHA.SAVE_PRINT_RETURN.code) {
        handleReturnResult();
      }
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReturnResult = async () => {
    try {
      let objectParams = {
        orderId: infoBnSelectedCdha?.id,
        resultReportingTime: true,
      };
      let { data: dataResult } = await returnResult(infoBnSelectedCdha?.id);
      let { data: dataUpdateTimes } = await updateTimes(objectParams);
      changeStatus();
    } catch (error) {
      console.error(error);
    }
  };

  const changeStatus = async () => {
    let { data } = await updateWfWorkflows(WfConstTracker.RISPACS, {
      objectId: infoBnSelectedCdha?.id || "",
      status: workFlowNewStatus,
    });
    if (data?.data) {
      doSearch(filter);
      setWorkFlowStatus(WORK_FLOW_STATUS_CDHA.DA_TRA_KQ.code);
      setForceRender((prev) => !prev);
    }
  };

  const handleHuyThucHien = () => {
    setInfoBnSelectedCDHA({
      ...infoBnSelectedCdha,
      chanDoanHinhAnh: [
        {
          ...infoBnSelectedCdha?.chanDoanHinhAnh?.[0],
          nguoiThucHien: "",
          ngayThucHien: "",
        },
      ],
    } as IBenhNhanV3CDHA);
    toast.warning(
      "Bệnh nhân có số phiếu " +
        infoBnSelectedCdha?.chanDoanHinhAnh?.[0]?.soPhieu +
        " đã huỷ thực hiện CDHA"
    );
    handleCloseHuyThucHien();
  };

  const handleTraKetQuaCDHA = () => {
    setInfoBnSelectedCDHA({
      ...infoBnSelectedCdha,
      chanDoanHinhAnh: [
        {
          ...infoBnSelectedCdha?.chanDoanHinhAnh?.[0],
          nguoiTraKetQua: "Quản trị hệ thống",
          ngayTraKetQua: moment().format("HH:mm DD/MM/YYYY"),
          noiTraKetQua: "Phòng khám đa khoa",
        },
      ],
    } as IBenhNhanV3CDHA);
  };

  const handleHuyKetQua = () => {
    setInfoBnSelectedCDHA({
      ...infoBnSelectedCdha,
      chanDoanHinhAnh: [
        {
          ...infoBnSelectedCdha?.chanDoanHinhAnh?.[0],
          nguoiTraKetQua: "",
          ngayTraKetQua: "",
          noiTraKetQua: "",
        },
      ],
    } as IBenhNhanV3CDHA);
    toast.warning(
      "Bệnh nhân có số phiếu " +
        infoBnSelectedCdha?.chanDoanHinhAnh?.[0]?.soPhieu +
        " đã huỷ kết quả CDHA"
    );
    handleCloseHuyKetQua();
  };

  const handleCloseHuyThucHien = () => {
    setShouldOpenModalHuyThucHien(false);
  };

  const handleCloseHuyKetQua = () => {
    setShouldOpenModalHuyKetQua(false);
  };

  const handlePrint = (key: string) => {
    setOpenModalInPhieu(true);
    switch (key) {
      case LIST_PHIEU_IN.PHIEU_CHI_DINH.key:
        setTemplatePrint(LIST_PHIEU_IN.PHIEU_CHI_DINH);
        break;
      default:
        break;
    }
  };

  const handleGetInfoBn = async (item: any, isShowModal?: boolean) => {
    setIsLoading(true);
    try {
      let { data } = await getInforBnCDHA(item?.id);
      let { data: dataPhieu } = await getInforPhieuCDHA(item?.id);
      if (CODE.SUCCESS === data?.code && dataPhieu?.code === CODE.SUCCESS) {
        let icdXPrimary = listICD10?.find(
          (item: any) => item?.code === dataPhieu?.data?.icdXPrimary
        );
        let dsDichVu = dataPhieu?.data?.orders?.map((item: any) => {
          return {
            ...item?.term,
            orderId: item?.orderId,
            obs: item?.obs,
          };
        });
        isShowModal && dataPhieu?.data?.executionStartTime && !dataPhieu?.data?.resultReportingTime && setOpenModalThucHien(true);
        setInfoBnSelectedCDHA({
          ...item,
          ...data?.data,
          ...dataPhieu?.data,
          icdXPrimaryName: icdXPrimary?.name,
        });
        setDsDichVu(dsDichVu);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataToPrint = (data: any) => {
    return {
      hoTen: data?.hoTen,
      gioiTinh: data?.gioiTinh,
      tuoi: data?.age,
      namSinh: data?.age ? moment().year() - data?.age : "",
      thoiGianLapPhieu: moment().format("HH:mm DD/MM/YYYY"),
    };
  };
  const doSearch = (data: any) => {
    let dataSearch = {
      ...filter,
      ...data
    }
    setFilter(dataSearch);
    getPatientCDHA(dataSearch);
  };

  const handleGetListICD10 = async () => {
    try {
      let res = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    const handlePressKeyShortcuts = (e: KeyboardEvent) => {
      if (e.key === "F5") {
        e.preventDefault();
      }
    };
  
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F5:
        e.preventDefault();
        setKeyOpenPhieuIn("F5");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  const PRINT_PARAMS = {
    visitId: infoBnSelectedCdha?.visit?.id,
    patientId: infoBnSelectedCdha?.patient?.id,
    orderGroupId: infoBnSelectedCdha?.orderGroupId,
    orderTypeId: ORDER_TYPE_ID.CDHA,
    risPacsGroupId: infoBnSelectedCdha?.id
  };

  return (
    <>
      <div className="reception-list bg-gray">
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white pt-6">
            <FilterSidebar
              title="Danh sách phiếu"
              formalList={LIST_FORMAL}
              onSearch={doSearch}
              listMenuItem={[]}
              handleSelectOption={() => {}}
            />
            <div className="flex-1 spaces pt-10">
              <BangDsPhieuCDHA
                setInfoPhieuCDHA={handleGetInfoBn}
                dataSearched={dataSearched}
                statusSummary={statusSummary}
                doSearch={doSearch}
                filter={filter}
              />
            </div>
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <div className="d-flex">
              <div className="spaces w-60 border bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin phiếu
                </h4>
              </div>
              <div className="spaces w-40 border bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin bệnh nhân
                </h4>
              </div>
            </div>
            <div className="d-flex spaces mb-0">
              <div className="spaces w-60 border bg-white spaces px-10 py-5">
                <div className="bg-white">
                  <div className="d-flex spaces mb-5">
                    <LabelRequired
                      label="Mã phiếu:"
                      className="spaces w-17 fw-bold"
                    />
                    <div className="spaces flex-grow-1 w-83 text-visit-infor">
                      {infoBnSelectedCdha?.code || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <LabelRequired
                      label="Chỉ định:"
                      className="spaces w-17 fw-bold"
                    />
                    <div className="spaces flex-grow-1 w-83 text-visit-infor text-truncate">
                      {infoBnSelectedCdha?.indicationPerson
                        ? `${infoBnSelectedCdha?.indicationPerson}`
                        : "..."}{" "}
                      -{" "}
                      {infoBnSelectedCdha?.indicationTime
                        ? moment(infoBnSelectedCdha?.indicationTime).format(
                            "HH:mm DD/MM/YYYY"
                          )
                        : "..."}{" "}
                      -{" "}
                      {infoBnSelectedCdha?.deptIndicationDeptName
                        ? infoBnSelectedCdha?.deptIndicationDeptName
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <LabelRequired
                      label="Thực hiện:"
                      className="spaces w-17 fw-bold"
                    />
                    <div className="spaces flex-grow-1 w-83 text-visit-infor text-truncate">
                      {infoBnSelectedCdha?.executor || "..."} -{" "}
                      {infoBnSelectedCdha?.executionStartTime
                        ? formatDateAdvanceToString(
                            infoBnSelectedCdha?.executionStartTime
                          )
                        : "..."}{" "}
                      - {infoBnSelectedCdha?.deptFulfillDeptName || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <LabelRequired
                      label="Kết quả:"
                      className="spaces w-17 fw-bold text-danger"
                    />
                    <div className="spaces flex-grow-1 w-83 text-danger text-truncate">
                      {infoBnSelectedCdha?.resultReporter || "..."} -{" "}
                      {infoBnSelectedCdha?.resultReportingTime
                        ? formatDateAdvanceToString(
                            infoBnSelectedCdha?.resultReportingTime
                          )
                        : "..."}{" "}
                      - {infoBnSelectedCdha?.deptResultReportingDept || "..."}
                    </div>
                  </div>
                </div>
              </div>
              <div className="spaces w-40 border bg-white spaces px-10 py-5">
                <div className="d-flex spaces mb-5">
                  <LabelRequired
                    label="Bệnh nhân:"
                    className="spaces w-25 fw-bold"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {infoBnSelectedCdha?.patient?.code || "..."} -{" "}
                    {infoBnSelectedCdha?.patient?.personName || "..."} -{" "}
                    {infoBnSelectedCdha?.patient?.gender || "..."} -{" "}
                    {infoBnSelectedCdha?.patient?.dobYear
                      ? calculateAge(
                          infoBnSelectedCdha?.patient?.dobDay,
                          infoBnSelectedCdha?.patient?.dobMonth,
                          infoBnSelectedCdha?.patient?.dobYear
                        )
                      : "..."}{" "}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <LabelRequired
                    label="Mã vào khám:"
                    className="spaces w-25 fw-500"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {infoBnSelectedCdha?.visit?.code || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-5">
                  <LabelRequired
                    label="Chẩn đoán:"
                    className="spaces w-25 fw-bold"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {infoBnSelectedCdha?.provisionalDiagnosisName || "..."}
                  </div>
                </div>
              </div>
            </div>
            <div className="spaces bg-white">
              <BangDsDichVuCDHA
                dataDanhSachDVCdha={dsDichVu}
                infoBnSelectedCdha={infoBnSelectedCdha}
                setDsDichVu={setDsDichVu}
                handleDoubleClick={handleDoubleClick}
                isViewModal={isViewModal}
                handleGetInfoBn={handleGetInfoBn}
              />
            </div>
            <div className="d-flex flex-start spaces px-12 py-16 gap-10 bg-white">
              {/* <Button
                className="btn-fill spaces min-w-100px"
                onClick={() => {
                  handleThucHienCDHA();
                }}
              >
                Thực hiện
              </Button> */}
              {infoBnSelectedCdha?.id && (
                <WfButton
                  tracker={WfConstTracker.RISPACS}
                  objectId={infoBnSelectedCdha?.id || ""}
                  additionalFunc={handleThucHienCDHA}
                  forceRender={forceRender}
                ></WfButton>
              )}
              {infoBnSelectedCdha?.visit?.id &&
                infoBnSelectedCdha?.patient?.id && workFlowStatus === WORK_FLOW_STATUS_CDHA.DA_TRA_KQ.code && (
                  <ButtonInPhieu<IInPhieuParams>
                    className="spaces mx-3"
                    label="In kết quả (F5)"
                    fetchExport={inPhieuKetQuaCLS}
                    params={PRINT_PARAMS}
                    keyOpenPhieuIn={keyOpenPhieuIn}
                    keyBtn={KEY.F5}
                    setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                  />
                )}
            </div>
          </div>
        </Row>
      </div>
      {openModalThucHien && (
        <ModalThucHienCDHA
          handleClose={() => {
            setOpenModalThucHien(false);
          }}
          handleTraKetQua={() => {}}
          infoBenhNhan={infoBnSelectedCdha}
          isViewModal={isViewModal}
          handleSubmitForm={handleSubmitForm}
        />
      )}
      {typeSave && (
        <FormInPhieuDialog<IInPhieuParams>
          show={typeSave}
          onHide={() => {
            setTypeSave(null);
          }}
          fetchExport={inPhieuKetQuaCLS}
          params={PRINT_PARAMS}
        />
      )}
    </>
  );
};

export { PhanHeCDHAVaTDCN };
