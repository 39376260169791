import clsx from 'clsx';
import React, {
	ChangeEvent,
	KeyboardEvent,
	MouseEvent as ReactMouseEvent,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { IconButtonSave } from '../../../component/IconSvg';

const ACTIONS = {
	PEN: 'pen',
	ERASER: 'eraser',
	CLEAR: 'clear',
	TEXT: 'text',
	IMAGE: 'image',
	INSERT_IMAGE: 'insert-image',
} as const;

type ActionKeys = keyof typeof ACTIONS;
type ToolAction = typeof ACTIONS[ActionKeys];

type Props = {
	value: string;
	open: boolean;
	onClose: () => void;
	onSave: (data: string) => void;
};

type Tool = {
	label: string;
	icon: ReactNode;
	action: ToolAction;
};

type ToolProperty = {
	lineWidth: number;
	fontSize: number;
	color: string;
};

type ToolItemProps = {
	icon: ReactNode;
	label: string;
	isSelected: boolean;
	className?: string;
	containerClassName?: string;
	onClick?: (e: ReactMouseEvent<HTMLElement>) => void;
};

const DEFAULT_COLORS = ['#000000', '#e7e7e7', '#063970', '#873e23', '#abdbe3', '#fff100'];

const TOOLS: Tool[] = [
	{ label: 'Vẽ tự do', icon: <i className="bi bi-brush"></i>, action: ACTIONS.PEN },
	{ label: 'Mở ảnh', icon: <i className="bi bi-image"></i>, action: ACTIONS.IMAGE },
	{ label: 'Viết chữ', icon: <i className="bi bi-type"></i>, action: ACTIONS.TEXT },
	{
		label: 'Chèn ảnh',
		icon: <i className="bi bi-file-earmark-image"></i>,
		action: ACTIONS.INSERT_IMAGE,
	},
	{ label: 'Tẩy xóa', icon: <i className="bi bi-eraser"></i>, action: ACTIONS.ERASER },
	{ label: 'Xóa hết', icon: <i className="bi bi-x"></i>, action: ACTIONS.CLEAR },
];

const ToolItem = ({
	icon,
	label,
	isSelected,
	className,
	containerClassName,
	onClick,
}: ToolItemProps) => {
	return (
		<Card
			className={clsx('drawable-tool-item', containerClassName, {
				selected: isSelected,
			})}
			onClick={(e: ReactMouseEvent<HTMLElement>) => onClick?.(e)}
		>
			<Card.Body className={clsx('d-flex align-items-center spaces px-6', className)}>
				<span className="spaces pr-4">{icon}</span>
				{label}
			</Card.Body>
		</Card>
	);
};

const ModalLuocDoPTTT = ({ open, value, onClose, onSave }: Props) => {
	const [isDraw, setIsDraw] = useState(false);
	const [selectedTool, setSelectedTool] = useState<ToolAction>(ACTIONS.PEN);
	const [property, setProperty] = useState<ToolProperty>({
		lineWidth: 4,
		color: DEFAULT_COLORS[0],
		fontSize: 14,
	});

	const containerRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const textboxRef = useRef<HTMLInputElement>(null);

	const context = canvasRef.current?.getContext('2d');

	useEffect(() => {
		if (!canvasRef.current || !containerRef.current) return;

		let canvasWrapperElement = containerRef.current;
		canvasRef.current.width = canvasWrapperElement.clientWidth;
		canvasRef.current.height = canvasWrapperElement.clientHeight;
	}, []);

	useEffect(() => {
		if (value && canvasRef.current) {
			const context = canvasRef.current.getContext('2d');
			const image = new Image();
			image.src = value;
			image.addEventListener('load', () => {
				context && context.drawImage(image, 0, 0);
			});
		}
	}, [value, canvasRef]);

	useEffect(() => {
		if (selectedTool !== 'text') {
			onResetTextbox();
		}

		if (selectedTool !== 'eraser' && canvasRef.current) {
			canvasRef.current.style.cursor = 'default';
		}
	}, [selectedTool]);

	const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files?.length || !canvasRef.current) return;

		const imageURI = URL.createObjectURL(files[0]);
		const image = new Image();

		image.src = imageURI;

		image.onload = () => {
			context?.drawImage(
				image,
				0,
				0,
				canvasRef.current?.width ?? 0,
				canvasRef.current?.height ?? 0
			);
		};
		e.target.value = '';
	};

	const onChangePropertyValue = (name: string, value: any) => {
		setProperty({
			...property,
			[name]: value,
		});
	};

	const generateAction = (action: ToolAction) => {
		const mapActionFn: Record<ToolAction, () => void> = {
			pen: () => {},
			eraser: () => {},
			text: () => {},
			image: onImageOpen,
			'insert-image': onImageInsert,
			clear: onClear,
		};
		return mapActionFn[action];
	};

	const onImageOpen = () => {
		inputFileRef.current?.click();
	};

	const onImageInsert = () => {
		if (!inputFileRef.current) return;

		inputFileRef.current.click();
	};

	const onTextInsert = (e: ReactMouseEvent) => {
		if (!textboxRef.current) return;

		textboxRef.current.hidden = false;
		textboxRef.current.style.left = `${e.nativeEvent.offsetX}px`;
		textboxRef.current.style.top = `${e.nativeEvent.offsetY}px`;
	};

	const onClear = () => {
		context?.clearRect(0, 0, canvasRef.current?.width ?? 0, canvasRef.current?.height ?? 0);
	};

	const onResetTextbox = () => {
		if (!textboxRef.current) return;
		textboxRef.current.value = '';
		textboxRef.current.hidden = true;
	};

	const onCanvasMouseDown = (e: ReactMouseEvent) => {
		if (!['pen', 'eraser', 'text'].includes(selectedTool)) {
			return;
		}

		setIsDraw(true);
		const target = e.currentTarget as HTMLCanvasElement;
		const context = target.getContext('2d');
		if (!context) return;

		context.lineCap = 'round';
		context.lineJoin = 'round';

		if (selectedTool === 'pen') {
			context.lineWidth = property.lineWidth;
			context.strokeStyle = property.color;
		}

		if (selectedTool === 'eraser') {
			context.globalCompositeOperation = 'source-over';
			context.strokeStyle = '#ffffff';
			context.lineWidth = 20;
			target.style.cursor = 'cell';
		}

		context.beginPath();
		context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);

		if (selectedTool === 'text') {
			onTextInsert(e);
		}
	};

	const onCanvasMouseMove = (e: ReactMouseEvent) => {
		if (!['pen', 'eraser'].includes(selectedTool)) {
			return;
		}

		if (!isDraw) return;

		const coord = e.nativeEvent;
		const context = (e.target as HTMLCanvasElement).getContext('2d');
		if (!context) return;

		context.lineTo(coord.offsetX, coord.offsetY);
		context.stroke();
	};

	const onCanvasMouseUp = (e: ReactMouseEvent) => {
		isDraw && setIsDraw(false);
	};

	const onTextBoxMouseDown = (e: ReactMouseEvent) => {
		e.stopPropagation();

		const onDrag = (mousemoveEvent: MouseEvent) => {
			const element = mousemoveEvent.target as HTMLInputElement;
			element.style.left = `${mousemoveEvent.offsetX}px`;
			element.style.top = `${mousemoveEvent.offsetY}px`;
		};

		const onDragStop = () => {
			document.removeEventListener('mousemove', onDrag);
			document.removeEventListener('mouseup', onDragStop);
		};

		document.addEventListener('mousemove', onDrag);
		document.addEventListener('mouseup', onDragStop);
	};

	const onTextBoxKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter' && context) {
			const element = e.target as HTMLInputElement;
			context.font = `${property.fontSize}px "Roboto", sans-serif`;
			context.fillText(element.value, element.offsetLeft, element.offsetTop);
			onResetTextbox();
		}
	};

	const onSubmit = () => {
		if (!canvasRef.current) return;
		const dataURL = canvasRef.current.toDataURL();
		onSave(dataURL);
	};

	return (
		<Modal show={open} size="xl" animation centered className="dialog-background">
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Lược đồ PTTT</Modal.Title>
				<Button className="btn-close bg-white" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="spaces p-4 min-h-450">
				<div className="d-flex">
					<div className="spaces flex-1 min-w-500 mr-4" ref={containerRef}>
						<canvas
							ref={canvasRef}
							onMouseDown={onCanvasMouseDown}
							onMouseMove={onCanvasMouseMove}
							onMouseUp={onCanvasMouseUp}
						></canvas>
						<Form.Control
							className={clsx(
								'spaces w-150px h-26 position-absolute z-index-1',
								'border-dashed border-hover bg-light'
							)}
							size="sm"
							hidden
							ref={textboxRef}
							onMouseDown={onTextBoxMouseDown}
							onKeyDown={onTextBoxKeyDown}
						/>
					</div>

					<div className="spaces max-w-250">
						<input
							type="file"
							accept="image/*"
							hidden
							ref={inputFileRef}
							onChange={onImageUpload}
						/>

						<div className="spaces px-8 py-4 border">
							<Form.Label>Công cụ</Form.Label>
							<div className="d-flex flex-wrap gap-2">
								{TOOLS.map(tool => (
									<ToolItem
										key={tool.action}
										icon={tool.icon}
										label={tool.label}
										isSelected={tool.action === selectedTool}
										containerClassName="max-w-50"
										onClick={() => {
											setSelectedTool(tool.action);
											generateAction(tool.action)();
										}}
									/>
								))}
							</div>
						</div>

						<div className="spaces px-4 py-8 mt-10 border d-flex align-items-center">
							<Form.Label className="spaces min-w-150 m-0" htmlFor="lineWidth">
								Kích thước nét vẽ
							</Form.Label>
							<Form.Control
								className="customs-input spaces px-4 text-center"
								name="lineWidth"
								id="lineWidth"
								type="number"
								value={property.lineWidth}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onChangePropertyValue(e.target.name, e.target.value);
								}}
							/>
						</div>

						<div className="spaces px-4 py-8 mt-10 border d-flex align-items-center">
							<Form.Label className="spaces min-w-150 m-0" htmlFor="fontSize">
								Kích thước chữ
							</Form.Label>
							<Form.Control
								className="customs-input spaces px-4 text-center"
								name="fontSize"
								id="fontSize"
								type="number"
								value={property.fontSize}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onChangePropertyValue(e.target.name, e.target.value);
								}}
							/>
						</div>

						<div className="spaces px-4 py-8 mt-10 border">
							<Form.Label>Màu sắc</Form.Label>
							<div className="d-flex mb-4">
								{DEFAULT_COLORS.map(color => (
									<Button
										key={color}
										style={{
											backgroundColor: color,
											boxShadow:
												color === property.color
													? '0 0 0 3px var(--kt-info)'
													: 'none',
										}}
										className="spaces w-15px h-30px mx-4"
										onClick={() => {
											onChangePropertyValue('color', color);
										}}
									/>
								))}
							</div>
							<div className="d-flex align-items-center">
								<Form.Label htmlFor="colorInput" className="spaces mb-0 pr-8">
									Chọn màu
								</Form.Label>
								<Form.Control
									type="color"
									id="colorInput"
									title="Chọn màu"
									name="color"
									className="spaces w-100px p-5"
									value={property.color}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										onChangePropertyValue(e.target.name, e.target.value);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end">
				<Button className="btn-outline" onClick={onClose}>
					Đóng
				</Button>
				<Button className="btn-fill" onClick={onSubmit}>
					<IconButtonSave />
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalLuocDoPTTT;
