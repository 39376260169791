import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { createDSChiDinhDV, updateDSChiDinhDV, validateChiDinhDV } from "../../services/ChiDinhDVService";
import FormChiDinhDichVu from "./FormChiDinhDichVu";
import { changeStatus, getStatusByTrackerAndObjectIds } from "../../../workflows/services/WfWorkflowsService";
import { WfConstTrackerClinical } from "../../../workflows/Constant";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { useIntl } from "react-intl";
import moment from "moment";
import { formatDateDTO } from "../../../utils/FormatUtils";
import { ConfirmDialog } from "../../../component/ConfirmDialog";

interface Props {
  show?: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  dieuTri?: boolean;
  isSuaPhieu?: boolean;
}

const PageChiDinhDichVu: FC<Props> = (props) => {
  let { show = true, handleClose, isSuaPhieu = false } = props;
  const intl = useIntl();
  const { setIsLoading } = useContext(AppContext);
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } = useContext(PhanHeTiepDonContext);
  const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  let { patient, visit, encounter, orderGroupId, orderId } = benhNhanInfo?.thongTinKhamBenh || {};
  const formikRef = useRef<FormikProps<any>>(null);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState<boolean>(false);
  const [validateMessage, setValidateMessage] = useState<string>("");

  const handleSubmit = async (values: any) => {
    let encounterDiagnosisDto = {
      ...values?.encounterDiagnosis,
      patientId: patient?.id,
    };
    let ordersDto = values?.orders?.map((item: any) => {
      return {
        termId: item?.id,
        fulfillDeptCode: item?.attributes?.deptLaboratoryCode,
        fulfillDeptName: item?.attributes?.deptLaboratoryName,
        objectTypeId: item?.visitObjectType?.id,
        objectTypeCode: item?.visitObjectType?.code,
        objectTypeName: item?.visitObjectType?.name,
        notes: item?.notes,
      }
    });
    let termIds = ordersDto?.map((item: any) => item?.termId);
    let validateData = {
      termIds, 
      clinicalOrderId: orderId
    };
    let userInfo = {
      indicationPerson: currentUser?.name,
      requestDeptName: currentUser?.departments?.[0]?.name || null
    };
    let createData = {
      ...userInfo,
      encounterId: encounter?.id,
      patientId: patient?.id,
      visitId: visit?.id,
      indicationTime: values?.indicationTime || formatDateDTO(new Date()),
      encounterDiagnosisDto,
      ordersDto,
    };
    let updateData = {
      encounterDiagnosis: encounterDiagnosisDto,
      orders: ordersDto,
      orderGroupId
    };
    try { 
      setIsLoading(true);
      if (!validateMessage) {
        let { data: validateResponse } = await validateChiDinhDV(validateData);
        if (validateResponse?.code === CODE.SUCCESS) {
          setValidateMessage("");
        } else {
        let errorMessage = validateResponse?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
          setValidateMessage(errorMessage);
          setShouldOpenConfirmDialog(true);
          return;
        }
      };
      let { data } = isSuaPhieu
        ? await updateDSChiDinhDV(updateData)
        : await createDSChiDinhDV(createData);
      if (CODE.SUCCESS === data?.code) {
        let orderGroupIds = data?.data?.orderGroupIds?.join(",");
        let statusRes = await getStatusByTrackerAndObjectIds(WfConstTrackerClinical.ORDER_GROUP, orderGroupIds);
        let statusData = statusRes?.data?.data?.map((item: any) => ({
          objectId: item?.objectId,
          status: item?.newStatusId,
        }));
        await changeStatus(WfConstTrackerClinical.ORDER_GROUP, statusData);
        setBenhNhanInfo({
          ...benhNhanInfo,
          shouldUpdate: !benhNhanInfo?.shouldUpdate,
        });
        statusChangedEventFunc?.();
        toast.success("Cập nhật thành công");
        handleClose(false);
      } else {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    encounterDiagnosis: Yup.object().shape({
      provisionalDiagnosis: Yup.string().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    }),
    indicationTime: Yup.date().nullable().min(visit?.dateStarted, `Ngày y lệnh không được nhỏ hơn ngày vào viện (${moment(visit?.dateStarted).format("DD/MM/YYYY - HH:mm")})`),
  });

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Modal
      className="page-full"
      fullscreen
      dialogClassName="h-modal"
      size="xl"
      show={show}
      animation={false}
      centered
      backdropClassName="spaces top-50"
    >
      <Formik<any>
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          return errors;
        }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        <Form id="form-thuoc" className="spaces h-calc-vh-50" onKeyDown={handleKeyPress}>
          <FormChiDinhDichVu
            handleClose={handleClose}
            isSuaPhieu={isSuaPhieu}
          />
          <ConfirmDialog
            className="z-index-1060"
            show={shouldOpenConfirmDialog}
            title="Thông báo"
            message={validateMessage}
            yes="Có"
            close="Không"
            onCloseClick={() => {
              setValidateMessage("");
              setShouldOpenConfirmDialog(false);
            }}
            onYesClick={() => formikRef.current?.submitForm()}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default PageChiDinhDichVu;
