import axios from 'axios';
import { OctResponse } from '../../models/OctResponse';
import { IDichVuChuaCoKetQua, IDonThuocMauRequest, IDonThuocMauSearchParams, IInPhieuChiDinhThuocParams, IKhoThuoc, ISearchThuocParams, IThuoc } from '../models/ThuocModels';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["wmsApi"] || process.env.REACT_APP_WMS_API_URL;
const CIS_BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

export const getDanhSachKhoThuoc = async (): Promise<IKhoThuoc[]> => {
	const { data } = await axios.get<OctResponse<IKhoThuoc[]>>(
		`${BASE_URL}/item/get-lst-ware-house`
	);
	return data.data;
};

export const saveDonThuoc = async (params: any): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/save`,
		params
	);
	return data;
};

export const deleteDonThuoc = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.delete<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/delete-by-id/${id}`
	);
	return data;
};

export const getDrugListById = async (params: object): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-order/search`,
		params
	);
	return data;
};

export const getDetailDonThuoc = async (drugId: string): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/get-by-id/${drugId}`
	);
	return data;
};

export const getDrugGroupListByVisitId = async ({
	visitId,
	type,
}: {
	visitId: string;
	type: string;
}): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/get-list-by-visit-id/${visitId}/and-type/${type}`
	);
	return data;
};

export const getDetailThuoc = async (thuocId: string): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(`${BASE_URL}/item/${thuocId}`);
	return data;
};

export const printPhieuChiDinhThuoc = async (params: IInPhieuChiDinhThuocParams) => {
	return await axios.post<Blob>(`${CIS_BASE_URL}/medical-item-group/export`, params, {
		responseType: 'blob',
	});
};

export const getDichVuChuaCoKetQua = async ({
	patientId,
	visitId,
}: {
	patientId: string;
	visitId: string;
}): Promise<OctResponse<IDichVuChuaCoKetQua[]>> => {
	const { data } = await axios.get<OctResponse<IDichVuChuaCoKetQua[]>>(
		`${CIS_BASE_URL}/medical-item-group/get-no-result-order-by-patient-id` +
		`/${patientId}/and-visit-id/${visitId}`
	);
	return data;
};

export const getDanhSachDonThuocCu = async (): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/`
	);
	return data;
};

// --- Đơn thuốc mẫu ---
export const saveDonThuocMau = async (params: IDonThuocMauRequest): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${CIS_BASE_URL}/order-set/save`,
		params
	);
	return data;
};

export const getDetailDonThuocMau = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/order-set/get-by-id/${id}`,
	);
	return data;
};

export const deleteDonThuocMau = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.delete<OctResponse<any>>(
		`${CIS_BASE_URL}/order-set/delete-by-id/${id}`,
	);
	return data;
};

export const getDanhSachDonThuocMau = async (params: IDonThuocMauSearchParams): Promise<any> => {
	const { data } = await axios.post<any>(`${CIS_BASE_URL}/order-set/search`, params);
	return data;
};
// -----

export const huyGuiDonThuoc = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.patch<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/cancel-items/${id}`,
	);
	return data;
};