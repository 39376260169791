import moment from "moment";
import { useFormikContext } from "formik";
import { FC, SetStateAction, useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../../../appContext/AppContext";
import AutocompleteObjectV2 from "../../../../../component/AutocompleteObjectV2";
import LabelRequired from "../../../../../component/LabelRequired";
import TextField from "../../../../../component/TextField";
import { CODE, DEFAULT_PAGE_INDEX, KEY_DS_DANH_MUC_TIEP_DON, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../../../../utils/Constant";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../../../components/tab-quan-ly-hop-dong/QuanLyHopDong";
import { getByIdNhanVien, getDanhSachCategoriService, getListGoiKhamDichVu } from "../../../../services/KhamDoanHopDongServices";
import { AddNhanVien } from "./models/ThemNhanVienModel";
import "../QuanLyHopDong.scss";
import TableTienSuBanThan from "../../../tab-kham-lam-sang/tab-kham-benh/TableTienSuBanThan";
import { IDanhSachDanhMuc } from "../../../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../../../../utils/IndexedDB";

type Props = {
    thongTinHD: any;
    dataTienSuBanThan: any;
    setDataTienSuBanThan: SetStateAction<any>;
};

const FormThemMoiNhanVien: FC<Props> = (props) => {
    const { thongTinHD, dataTienSuBanThan, setDataTienSuBanThan } = props;
    const { setIsLoading } = useContext(AppContext);
    const { isClickOpenEditNV, dataEditNV, TTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setValues, setFieldValue, values, errors, touched } = useFormikContext<AddNhanVien>();
    const [danhSachTTHN, setDanhSachTTHN] = useState<any>([]);
    const [dsGoiKham, setDsGoiKham] = useState<any>([]);
    const [showTableTienSuBanThan, setShowTableTienSuBanThan] = useState<boolean>(false);
    const [listGioiTinh, setListGioiTinh] = useState<any>([]);

    useEffect(() => {
        isClickOpenEditNV && handlerEditNhanVien(dataEditNV)
    }, [isClickOpenEditNV]);

    useEffect(() => {
        getDanhSachTTHN();
        handleGetDsDanhMuc();
    }, []);

    useEffect(() => {
        TTHopDong?.id && getDanhSachGoiKham();
    }, [thongTinHD?.id]);

    const getDanhSachGoiKham = async (filter?: any) => {
        if (TTHopDong?.id) {
            setIsLoading(true);
            let searchObject = {
                pageIndex: DEFAULT_PAGE_INDEX,
                pageSize: MAX_PAGE_SIZE,
                keyword: filter?.keyword,
                contractId: TTHopDong?.id,
                isIncludeTerms: false,
            };
            try {
                const { data } = await getListGoiKhamDichVu(searchObject);
                if (CODE.SUCCESS === data?.code) {
                    setDsGoiKham(data?.data?.content || []);

                } else {
                    toast.warning(RESPONSE_MESSAGE.ERROR);
                }
            } catch {
                toast.warning(RESPONSE_MESSAGE.ERROR);
            } finally {
                setIsLoading(false);
            }
        } else {
            setDsGoiKham([]);
        }
    };

    const handlerEditNhanVien = async (value: any) => {
        try {
            const { data } = await getByIdNhanVien(value?.id);
            if (CODE.SUCCESS === data?.code) {
                let resultData = data?.data
                resultData = {
                    ...data?.data,
                    maritalStatus: {
                        id: data?.data?.maritalStatusId,
                        name: data?.data?.maritalStatusName
                    },
                    division: {
                        id: data?.data?.divisionId,
                        name: data?.data?.divisionName
                    },
                    genderObj: {
                        id: data?.data?.genderId,
                        name: data?.data?.gender
                    },
                    group: {
                        id: data?.data?.groupId,
                        name: data?.data?.groupName
                    },
                    examPackage: {
                        id: data?.data?.examPackageId,
                        name: data?.data?.examPackageName
                    },

                }
                setDataTienSuBanThan(resultData.personalMedicals || []);
                setValues({
                    ...resultData,
                    medicalHistoryFamily: resultData?.obs?.familyMedicalHistory || resultData?.medicalHistoryFamily,
                    medicalHistory:  resultData?.obs?.medicalHistory || resultData?.medicalHistory,
                });
            } else {
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangeVisit = (name: string, value: any) => {
        value && setFieldValue(name, value);
    };

    const getDanhSachTTHN = async () => {
        setIsLoading(true);
        let searchObject = {
            pageIndex: DEFAULT_PAGE_INDEX,
            pageSize: MAX_PAGE_SIZE,
            type: 39,
        };
        try {
            const { data } = await getDanhSachCategoriService(searchObject);

            if (CODE.SUCCESS === data?.code) {
                setDanhSachTTHN(data?.data?.content || []);
            } else {
                toast.warning(RESPONSE_MESSAGE.ERROR);
            }
        } catch {
            toast.warning(RESPONSE_MESSAGE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitTable = (values: any) => {
        const newValues = values?.filter((item: any) => 
            item.diseaseName ||
            item.discoveryYear || 
            item.occDiseaseName || 
            item.occDiscoveryYear
        );
        setDataTienSuBanThan(newValues);
        setShowTableTienSuBanThan(false);
    };

    const tienSuBenhString = dataTienSuBanThan.map((item: any) => 
        [item.diseaseName, item.discoveryYear, item.occDiseaseName, item.occDiscoveryYear]
          .filter(value => value !== null && value !== undefined)
          .join(' - ')
      ).join('; ');

    const handleGetDsDanhMuc = async () => {
    try {
        const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
        setListGioiTinh(responseDsDanhmuc?.listGioiTinh);
    } catch (error) {
        console.error(error);
    }
    };
      
    return (
        <div className="d-flex">
            <div className=" border-right-0 border-left-0 border-top-0  spaces ">

                <Row className="mx-0 pl-0">
                    <Col xs="4" className="mt-2 mb-5">
                        <TextField
                            label="Mã bệnh nhân"
                            name="patientCode"
                            labelClassName="min-w-165px"
                            disabled
                        />
                    </Col>
                    <Col xs="4" className="mt-2 mb-5">
                        <TextField
                             label={<div>
                                Mã nhân viên
                                <span className="text-danger">(*)</span>
                            </div>}
                            name="code"
                            labelClassName="min-w-165px"
                        />
                    </Col>
                    <Col xs="4" className="mt-2 mb-5">
                        <TextField
                            label={<div>
                                Tên nhân viên
                                <span className="text-danger">(*)</span>
                            </div>}
                            name="name"
                            labelClassName="min-w-140px"
                        />
                    </Col>
                    <Col xs="4" className="mb-5">
                        <TextField
                            label={<div>
                                Ngày sinh
                                <span className="text-danger">(*)</span>
                            </div>}
                            name="dob"
                            type="date"
                            labelClassName=" min-w-165px"
                            value={values?.dob ? moment(values?.dob).format("YYYY-MM-DD") : ""}
                        />
                    </Col>
                    <Col xs="4" className=" flex mb-5">
                        <LabelRequired isRequired className="min-w-165px" label="Giới tính" />
                        <AutocompleteObjectV2
                            options={listGioiTinh || []}
                            value={values?.genderObj || ""}
                            name="genderObj"
                            onChange={(selectedOption) =>
                                handleChangeVisit("genderObj", selectedOption)
                            }
                            touched={touched?.genderObj}
                            errors={errors?.genderObj}
                            isClearable={false}
                            className="spaces h-29 autocomplete-custom-tiep-nhan"
                        />
                    </Col>
                    <Col xs="4" className=" mb-5">
                        <TextField
                            label="Địa chỉ"
                            name="address"
                            labelClassName="min-w-140px"
                        />
                    </Col>
                    <Col xs="4" className="flex mb-5">
                        <LabelRequired className="min-w-165px" label="Tình trạng hôn nhân" />
                        <AutocompleteObjectV2
                            options={danhSachTTHN || []}
                            name="maritalStatus"
                            value={values?.maritalStatus || ""}
                            onChange={(selectedOption) =>
                                handleChangeVisit("maritalStatus", selectedOption)
                            }
                            isClearable={false}
                            className="spaces autocomplete-custom-tiep-nhan h-29"
                        />
                    </Col>

                    <Col xs="4" className="mb-5">
                        <TextField
                            label={<div>
                                Số điện thoại
                                <span className="text-danger">(*)</span>
                            </div>}
                            type="number"
                            name="phone"
                            labelClassName="min-w-165px"
                        />
                    </Col>
                    <Col xs="4" className="mb-5">
                        <TextField
                            label={<div>
                                CMND/CCCD
                                <span className="text-danger">(*)</span>
                            </div>}
                            name="idNumber"
                            labelClassName="min-w-140px"
                        />
                    </Col>
                    <Col xs="4" className="mb-5">
                        <TextField
                            label="Ngày cấp CMND/CCCD"
                            name="issuingDate"
                            type="date"
                            labelClassName=" min-w-165px"
                            value={values?.issuingDate ? moment(values?.issuingDate).format("YYYY-MM-DD") : ""}
                        />
                    </Col>
                    <Col xs="4" className=" mb-5">
                        <TextField
                            label="Nơi cấp CMND/CCCD"
                            name="issuingPlace"
                            labelClassName="min-w-165px"
                            value={values?.issuingPlace || ""}
                        />
                    </Col>
                    <Col xs="4" className=" mb-5 ">
                        <TextField
                            label="Hộ khẩu thường chú"
                            name="addressPermanent"
                            labelClassName="min-w-140px"
                        />
                    </Col>
                    <Col xs="4" className=" mb-5 ">
                        <TextField
                            label="Nghề nghiệp"
                            name="occupation"
                            labelClassName="min-w-165px"
                        />
                    </Col>
                    <Col xs="4" className="mb-5">
                        <TextField
                            label="Ngày bắt đầu làm việc"
                            name="workStartDate"
                            type="date"
                            labelClassName=" min-w-165px"
                            value={values?.workStartDate ? moment(values?.workStartDate).format("YYYY-MM-DD") : ""}
                        />
                    </Col>
                    <Col xs="4" className=" flex mb-5">
                        <LabelRequired isRequired className="min-w-140px" label="Gói khám" />
                        <AutocompleteObjectV2
                            options={dsGoiKham || []}
                            value={values?.examPackage || ""}
                            name="examPackage"
                            onChange={(selectedOption) =>
                                handleChangeVisit("examPackage", selectedOption)
                            }
                            touched={touched?.examPackage}
                            errors={errors?.examPackage}
                            isClearable={false}
                            className="spaces h-29 autocomplete-custom-tiep-nhan"
                        />
                    </Col>
                    <Col xs="4" className=" mb-5">
                        <TextField
                            label={<div>
                                Ngày khám dự kiến
                                <span className="text-danger">(*)</span>
                            </div>}
                            name="examExpectedDate"
                            type="date"
                            labelClassName="min-w-165px"
                            value={values?.examExpectedDate ? moment(values?.examExpectedDate).format("YYYY-MM-DD") : ""}
                        />
                    </Col>
                    <Col xs="4" className=" mb-5">
                        <div className="position-relative">
                            <TextField
                                className="cursor-pointer"
                                label="Tiền sử bệnh bản thân"
                                name="medicalHistory"
                                value={tienSuBenhString}
                                labelClassName="min-w-165px"
                                inputClassName="spaces pr-24"
                                readOnly={true}
                                onClick={(e: any) => setShowTableTienSuBanThan(true)}
                            />
                            <i
                                onClick={(e: any) => setShowTableTienSuBanThan(true)}
                                className="bi bi-three-dots custom-icon-text-field cursor-pointer"
                            ></i>
                        </div>
                    </Col>

                    <Col xs="4" className="mb-5">
                        <TextField
                            label={<div className="mb-10">
                                Tiền sử bệnh
                            </div>}
                            name="medicalHistory"
                            labelClassName="min-w-140px"
                            className="min-height-60px"
                            as="textarea"
                            value={values?.medicalHistory || ""}
                        />
                    </Col>
                    <Col xs="4" className="mb-5">
                        <TextField
                            label={<div className="mb-10">
                                Tiền sử gia đình
                            </div>}
                            name="medicalHistoryFamily"
                            labelClassName="min-w-165px"
                            className="min-height-60px"
                            as="textarea"
                            value={values?.medicalHistoryFamily || ""}
                        />
                    </Col>
                    <Col xs="4" className="">
                        <TextField
                            label="Công việc trước đây"
                            name="preWork"
                            labelClassName=" min-w-165px"
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                centered
                show={showTableTienSuBanThan}
                size="xl"
                onHide={() => setShowTableTienSuBanThan(false)}
            >
                <Modal.Header closeButton>
                    <h4 className="text-title-kls fw-bold fs-5">Tiền sử bệnh bản thân</h4>
                </Modal.Header>
                <div className="spaces px-10">
                    <TableTienSuBanThan
                        independentForm={true}
                        data={dataTienSuBanThan}
                        height={300}
                        onSubmit={handleSubmitTable}
                        onHide={() => setShowTableTienSuBanThan(false)}
                    />
                </div>
            </Modal>
        </div>

    );
};
export default FormThemMoiNhanVien;

