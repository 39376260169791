import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { forwardRef } from 'react';

// TinyMCE core
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';
// Plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/vi';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
// Theme
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

type Props = Omit<IAllProps, 'licenseKey' | 'apiKey'>

const RichTextEditor = forwardRef<Editor, Props>((props, ref) => {
	return (
		<Editor
			ref={ref}
			licenseKey="gpl"
			init={{
				branding: false,
				promotion: false,
				height: '100%',
				menubar: 'file edit view insert format tools table',
				plugins: 'preview importcss searchreplace autolink '+
					'directionality code visualblocks visualchars '+
					'fullscreen image table ' +
					'insertdatetime advlist lists wordcount help ' +
					'quickbars link media',
				toolbar: 'undo redo | ' + 
					'blocks fontfamily fontsize | bold italic underline ' + 
					'strikethrough | align numlist bullist | image ' + 
					'table | lineheight outdent indent| forecolor backcolor ' + 
					'removeformat | code fullscreen preview',
				content_style:
					'body { font-family: Times New Roman, Helvetica, Arial, sans-serif; font-size: 14pt }',
				toolbar_mode: 'sliding',
				contextmenu: 'image table',
				resize: true,
				image_advtab: true,
				object_resizing: true,
				skin: 'default',
				skin_url: 'default',
				content_css: ['/tinymce/skin/default.css', '/tinymce/skin/oxide.css'],
				language: 'vi',
				language_url: '/tinymce/lang/vi.js',
				language_load: true,
				image_title: true,
				file_picker_types: 'image media',
				file_picker_callback: callback => {
					const input = document.createElement('input');
					input.setAttribute('type', 'file');
					input.setAttribute('accept', 'audio/*, video/*, image/*');
					input.addEventListener('change', (e: Event) => {
						const target = e.target as HTMLInputElement;
						const files = target.files;
						const file = files?.[0];

						if (!file) return;

						const URI = URL.createObjectURL(file);
						callback(URI, {
							title: file.name,
							alt: file.name,
						});
					});
					input.click();
				},
			}}
			{...props}
		/>
	);
});

export default RichTextEditor;
