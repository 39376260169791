import { Formik } from "formik";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import TextField from "../../component/TextField";
import BangLayMauBenhPham from "../components/tab-lay-mau-benh-pham/BangLayMauBenhPham";
import DsBenhNhan from "../components/tab-lay-mau-benh-pham/DsBenhNhan";
import { PhanHeXetNghiemContext } from "../context/PhanHeXetNghiemContext";
import WfButton from "../../workflows/components/WfButton";
import { WfConstTracker } from "../../workflows/Constant";
import { calculateAge } from "../../utils/FormatUtils";
import { WF_STEP } from "../const/constants";
import {
  CODE,
  KEY,
  KEY_DS_DANH_MUC_TIEP_DON,
  ORDER_TYPE_ID,
} from "../../utils/Constant";
import { getPhieuXetNghemInfo, inPhieuKetQuaCLS } from "../service/tiepNhan";
import DynamicComponentXN from "../components/DynamicComponentXN";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { getWfWorkflows } from "../../workflows/services/WfWorkflowsService";
import ButtonInPhieu from "../../component/button-in-phieu";
import LabelRequired from "../../component/LabelRequired";

type Props = {};
const LayMauBenhPham = (props: Props) => {
  const {
    benhNhanInfo,
    setBenhNhanInfo,
    phieuXetNghiemInfo,
    setPhieuXetNghiemInfo,
  } = useContext(PhanHeXetNghiemContext);
  const { patient, visit } = benhNhanInfo || {};
  const [statusChangedEvent, setStatusChangedEvent] = useState<number>();
  const [dataDynamic, setDataDynamic] = useState<any>();
  const [listICD10, setListICD10] = useState<any>([]);
  const [icdXPrimaryName, setIcdXPrimaryName] = useState<string>("");
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");

  const actionChanged = useCallback(
    (currStatusCode: string | undefined) => {
      setBenhNhanInfo({
        ...benhNhanInfo,
        isPerform: WF_STEP.TEST_START === currStatusCode,
        isReturnResult: WF_STEP.TEST_RESULT === currStatusCode,
      });
      setStatusChangedEvent(Math.random());
      handleGetPhieuXetNghiem();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo, statusChangedEvent]
  );
  const actionClick = (data: any) => {
    setDataDynamic({ ...data });
  };

  const updatePageData = async () => {
    try {
      let { data } = await getWfWorkflows(
        WfConstTracker.TEST,
        benhNhanInfo?.testGroupId
      );
      if (data?.code === CODE.SUCCESS) {
        setBenhNhanInfo({
          ...benhNhanInfo,
          isPerform: WF_STEP.TEST_START === data?.data?.currStatusCode,
          isReturnResult: WF_STEP.TEST_RESULT === data?.data?.currStatusCode,
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (benhNhanInfo?.testGroupId) updatePageData();
  }, [benhNhanInfo?.testGroupId]);

  const handleGetPhieuXetNghiem = async () => {
    const { data: dataXN } = await getPhieuXetNghemInfo(
      benhNhanInfo?.testGroupId
    );
    if (CODE.SUCCESS === dataXN?.code) {
      const thongTinPhieu = dataXN?.data;
      const dsXetNghiem = thongTinPhieu?.orders?.map((item: any) => ({
        ...item,
        ...item?.term,
        subs: item?.term?.subs?.map((sub: any) => ({
          ...sub,
          orderId: item?.orderId,
        })),
      }));
      setPhieuXetNghiemInfo({
        thongTinPhieu,
        dsXetNghiem,
      });
    }
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    let icdXPrimary = listICD10?.find(
      (item: any) =>
        item?.code === phieuXetNghiemInfo?.thongTinPhieu?.icdXPrimary
    );
    setIcdXPrimaryName(icdXPrimary?.name);
  }, [phieuXetNghiemInfo?.thongTinPhieu]);

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F7:
        e.preventDefault();
        setKeyOpenPhieuIn("F7");
        break;
      case KEY.F6:
        e.preventDefault();
        setKeyOpenPhieuIn("F6");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  return (
    <>
      <div className="reception-list bg-gray ">
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white spaces pt-10">
            <div className="flex-1">
              <DsBenhNhan statusChangedEvent={statusChangedEvent} />
            </div>
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <div className="d-flex">
              <div className="spaces w-60 border bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-1 py-5 px-10">
                  Thông tin phiếu
                </h4>
              </div>
              <div className="bg-white spaces w-40 border">
                <h4 className="text-title fw-bold fs-4 spaces mb-1 py-5 px-10">
                  Thông tin bệnh nhân
                </h4>
              </div>
            </div>
            <div className="d-flex spaces mb-6">
              <div className="bg-white spaces w-60 border py-5 px-10">
                <div className="grid-8-4 spaces gap-16 mb-1">
                  <div className="d-flex spaces">
                    <div className="spaces w-17 min-w-26-percent text-title fw-500 fw-bold fs-4 line-h-1">
                      Barcode:
                    </div>
                    <div className="spaces w-75 color-disable text-title text-truncate fw-bold fs-4">
                      {phieuXetNghiemInfo?.thongTinPhieu?.barcode || "..."}
                    </div>
                  </div>
                  <Button className="btn-fill spaces min-w-100px py-2 h-34">
                    In Barcode
                  </Button>
                </div>
                <div className="bg-white">
                  <div className="d-flex spaces mb-1">
                    <LabelRequired
                      label="Mã phiếu:"
                      className="spaces w-17 min-w-76 fw-500"
                    />
                    <div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
                      {phieuXetNghiemInfo?.thongTinPhieu?.code || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-1">
                    <LabelRequired
                      label="Chỉ định:"
                      className="spaces w-17 min-w-76 fw-500"
                    />
                    <div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
                      {phieuXetNghiemInfo?.thongTinPhieu?.indicationPerson
                        ? `${phieuXetNghiemInfo?.thongTinPhieu?.indicationPerson}`
                        : "..."}{" "}
                      -{" "}
                      {phieuXetNghiemInfo?.thongTinPhieu?.indicationTime
                        ? moment(
                            phieuXetNghiemInfo?.thongTinPhieu?.indicationTime
                          ).format("HH:mm DD/MM/YYYY")
                        : "..."}{" "}
                      -{" "}
                      {phieuXetNghiemInfo?.thongTinPhieu?.indicationDeptName
                        ? phieuXetNghiemInfo?.thongTinPhieu?.indicationDeptName
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-1">
                    <LabelRequired
                      label="Lấy mẫu:"
                      className="spaces w-17 min-w-76 fw-500"
                    />
                    <div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
                      {`${
                        phieuXetNghiemInfo?.thongTinPhieu?.specimenCollector ||
                        "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu
                          ?.specimenCollectionStartTime
                          ? moment(
                              phieuXetNghiemInfo?.thongTinPhieu
                                ?.specimenCollectionStartTime
                            ).format("HH:mm DD/MM/YYYY")
                          : "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu
                          ?.deptSpecimenCollectionDept || "..."
                      }`}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-1">
                    <LabelRequired
                      label="Xét nghiệm:"
                      className="spaces w-17 min-w-76 fw-500"
                    />
                    <div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
                      {`${
                        phieuXetNghiemInfo?.thongTinPhieu?.testingExecutor ||
                        "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu?.testingStartTime
                          ? moment(
                              phieuXetNghiemInfo?.thongTinPhieu
                                ?.testingStartTime
                            ).format("HH:mm DD/MM/YYYY")
                          : "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu?.fulfillDeptCode ||
                        "..."
                      }`}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-1">
                    <LabelRequired
                      label="Kết quả:"
                      className="spaces w-17 min-w-76 fw-500 text-danger"
                    />
                    <div className="spaces flex-grow-1 text-danger fw-500 text-truncate">
                      {`${
                        phieuXetNghiemInfo?.thongTinPhieu?.resultReporter ||
                        "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu?.resultReportingTime
                          ? moment(
                              phieuXetNghiemInfo?.thongTinPhieu
                                ?.resultReportingTime
                            ).format("HH:mm DD/MM/YYYY")
                          : "..."
                      } - ${
                        phieuXetNghiemInfo?.thongTinPhieu
                          ?.deptResultReportingDept || "..."
                      }`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white spaces w-40 py-5 border px-10">
                <div className="d-flex spaces mb-6">
                  <LabelRequired
                    label="Bệnh nhân:"
                    className="spaces w-25 fw-500"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {patient?.code || "..."} - {patient?.personName || "..."} -{" "}
                    {patient?.gender || "..."} -{" "}
                    {patient?.dobYear
                      ? calculateAge(
                          patient?.dobDay,
                          patient?.dobMonth,
                          patient?.dobYear
                        )
                      : "..."}{" "}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <LabelRequired
                    label="Mã vào khám:"
                    className="spaces w-25 fw-500"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {visit?.code || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <LabelRequired
                    label="Chẩn đoán:"
                    className="spaces w-25 fw-500"
                  />
                  <div className="flex-grow-1 w-75 text-truncate">
                    {phieuXetNghiemInfo?.thongTinPhieu
                      ?.provisionalDiagnosisName || "..."}
                  </div>
                </div>
              </div>
            </div>
            <div className="spaces pt-6 bg-white">
              <BangLayMauBenhPham />
            </div>
            <div className="d-flex flex-start spaces px-12 pb-12 pt-5 gap-10 bg-white">
              {benhNhanInfo && (
                <WfButton
                  tracker={WfConstTracker.TEST}
                  objectId={benhNhanInfo?.testGroupId}
                  additionalFunc={actionChanged}
                  handleClick={actionClick}
                ></WfButton>
              )}
              {visit?.id &&
                patient?.id &&
                phieuXetNghiemInfo?.thongTinPhieu?.orderGroupId &&
                benhNhanInfo?.isReturnResult && (
                  <ButtonInPhieu
                    className="spaces mx-3"
                    label="In kết quả (F6)"
                    fetchExport={inPhieuKetQuaCLS}
                    params={{
                      visitId: visit?.id,
                      patientId: patient?.id,
                      orderGroupId:
                        phieuXetNghiemInfo?.thongTinPhieu?.orderGroupId,
                      orderTypeId: ORDER_TYPE_ID.XET_NGHIEM,
                      testGroupId: benhNhanInfo?.testGroupId,
                    }}
                    keyOpenPhieuIn={keyOpenPhieuIn}
                    setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                    keyBtn={KEY.F6}
                  />
                )}
            </div>
          </div>
          {dataDynamic && (
            <DynamicComponentXN data={dataDynamic} setData={setDataDynamic} />
          )}
        </Row>
      </div>
    </>
  );
};

export default LayMauBenhPham;
