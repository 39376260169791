export const WfConstTracker = {
  PATIENT: "patient",
  CLINICAL: "clinical",
  TEST: "test",
  RISPACS: "rispacs",
  SURGERY: "surgery"
}

export const WfConstTrackerClinical = {
  ORDER_GROUP: "order_group",
  MEDICAL_ITEMS: "medical_items"
}