import React, { useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  KEY,
  KEY_DS_DANH_MUC_TIEP_DON,
  MAX_SIZE,
  SELECTION_MODE,
} from "../../utils/Constant";
import { PhanHeVienPhiContext } from "../context/PhanHeVienPhiContext";
import { statusOptions } from "../const/VienPhiConstant";
import { DanhSachBenhNhanInterface } from "../models/VienPhiInfoModels";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { AppContext } from "../../appContext/AppContext";
import { PhanHeTiepNhanContext } from "../../phan-he-tiep-nhan-thanh-toan/PhanHeTiepNhan";
import { Col, Row } from "react-bootstrap";
import { OctAutocomplete } from "@oceantech/oceantech-ui";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";

interface Props {
  setSelectedRow: React.Dispatch<any>;
  selectedRow: any;
  dsBenhNhan: any;
  countPaid: any;
  filter: any;
  doSearch: (filter?: any) => void;
}

const TableDanhSachBenhNhan = ({
  setSelectedRow,
  selectedRow,
  dsBenhNhan,
  countPaid,
  filter,
  doSearch,
}: Props) => {
  const { setIsLoading, currentTab } = useContext(AppContext);
  let { setInforBenhNhan, updateDanhSachBenhNhan, inforBenhNhan } =
    useContext(PhanHeVienPhiContext);
  const { thongTinBenhNhan, isPay } = useContext(PhanHeTiepNhanContext);

  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    getListBenhNhan();
  }, [page, rowsPerPage, updateDanhSachBenhNhan, currentTab]);

  useEffect(() => {
    isPay.boolean && setPage(isPay.page);
    isPay.boolean && setRowsPerPage(isPay.pageSize);
  }, [isPay]);

  const getListBenhNhan = async () => {
    // let searchObject = {
    //   pageIndex: page,
    //   pageSize: rowsPerPage,
    // };
    // try {
    //   const res = await getDsBenhNhanDangKham(searchObject);
    //   if (res?.data?.code === CODE.SUCCESS) {
    //     setDsBenhNhan({
    //       data: res?.data?.data?.content || [],
    //       totalPages: res?.data?.data?.totalPages,
    //       totalElements: res?.data?.data?.totalElements,
    //     });
    //   } else {
    //     toast.warning(RESPONSE_MESSAGE.ERROR);
    //     setIsLoading(false);
    //   }
    // } catch {
    //   toast.warning(RESPONSE_MESSAGE.ERROR);
    //   setIsLoading(false);
    // }
  };
  useEffect(() => {
    setInforBenhNhan(selectedRow[0]);
  }, [selectedRow[0]]);

  const updatePageData = async () => {
    // setIsLoading(true);
    // let searchObject = {
    //   keyword: keyword,
    //   pageIndex: page,
    //   pageSize: rowsPerPage,
    // };
    // try {
    //   let res = await getDsBenhNhanDangKham(searchObject);
    //   if (res?.data?.code === CODE.SUCCESS) {
    //     setDsBenhNhan({
    //       data: res?.data?.data?.content || [],
    //       totalPages: res?.data?.data?.totalPages,
    //       totalElements: res?.data?.data?.totalElements,
    //     });
    //   } else {
    //     toast.warning(RESPONSE_MESSAGE.ERROR);
    //   }
    //   setIsLoading(false);
    // } catch (e) {
    //   toast.error(RESPONSE_MESSAGE.ERROR);
    //   setIsLoading(false);
    // }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setKeyword(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    KEY.ENTER === event.key && updatePageData();
  };

  const getPatientName = (patient: any) => {
    return (
      <div className="fs-3">
        <div>{patient?.personName?.toUpperCase()}</div>
        <div>{`${patient?.code || ""} - ${
          patient?.visitObjectTypeName || ""
        } - ${patient?.gender || ""}`}</div>
      </div>
    );
  };

  const DanhSachBenhNhanColumns: ReadonlyArray<
    Column<DanhSachBenhNhanInterface>
  > = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"TT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "TT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={
            props.data[props.row.index].paidEnough ? (
              <i className="bi bi-circle-fill text-success"></i>
            ) : (
              <i className="bi bi-circle-fill text-danger"></i>
            )
            // : (
            //   <i className="bi bi-circle-fill text-primary"></i>
            // )
          }
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Tên bệnh nhân"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={getPatientName(props.data[props.row.index])}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Ngoài giờ"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "ngoaiGio",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.ngoaiGio}
          className="text-center"
        />
      ),
    },
  ];

  const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();

  const handleSetDefaultData = async (data: IDanhSachDanhMuc) => {
    setDanhSachDanhMuc(data);
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      handleSetDefaultData(responseDsDanhmuc);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  return (
    <>
      <div className="border-left-0 h-calc-300px">
        <TableCustom<any>
          className={"h-bang-ds-bn h-100"}
          data={dsBenhNhan.data}
          columns={DanhSachBenhNhanColumns}
          selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
          getSelectedRowsData={setSelectedRow}
          verticalScroll={true}
        />
      </div>
      <div className="d-flex flex-column spaces w-100 pb-3 gap-16 position-absolute bottom-15">
        <Row className="d-flex spaces w-100">
          <Col xs={12} className="spaces px-12">
            <OctAutocomplete
              options={danhSachDanhMuc?.listPhongKham || []}
              placeholder="Tất cả khoa phòng"
              searchObject={{ pageSize: MAX_SIZE }}
              onChange={(option) => {
                doSearch({
                  ...filter,
                  deptCode: option?.code,
                });
              }}
            />
          </Col>
        </Row>
        <Row className="d-flex spaces w-100 px-12 gap-10">
          <Col className="spaces p-0">
            <div
              className="status-1-bg text-center text-white rounded spaces px-8 py-4 cursor-pointer"
              onClick={() => {
                doSearch({ ...filter, filterBy: "paidEnough", filterValue: false });
              }}
            >
              <span className="body-normal-1">{`${
                statusOptions.chuaDongDu.name
              }: ${countPaid?.notPaidEnough || 0}`}</span>
            </div>
          </Col>
          <Col className="spaces p-0">
            <div
              className="status-4-bg text-center text-white rounded spaces px-8 py-4 cursor-pointer"
              onClick={() => {
                doSearch({ ...filter, filterBy: "paidEnough", filterValue: true });
              }}
            >
              <span className="body-normal-1">{`${
                statusOptions.daDongDu.name
              }: ${countPaid?.paidEnough || 0}`}</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { TableDanhSachBenhNhan };
