import axios from "axios";
import { paramsConfig } from "../../utils/ParamsUtils";
import { OctResponse } from "../../models/OctResponse";
import { IKetQuaCanLamSangResponse } from "../models/KetQuaCanLamSangModel";
import { IInPhieuParams } from "../../models/params";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_MEDICAL_EXAM = BASE_URL + "/medical-exam";

export const getDSKhamBenh = (searchObject: any) => {
    let url = API_PATH_MEDICAL_EXAM + "/search";
    return axios.get(url, paramsConfig(searchObject));
};
export const startMedicalExam = (data: any) => {
    let url = API_PATH_MEDICAL_EXAM+"/start" ;
    return axios.post(url, data);
}

export const getThongTinKhamBenh = (searchObject: any) => {
    let url = API_PATH_MEDICAL_EXAM;
    return axios.get(url, paramsConfig(searchObject));
}

export const createThongTinKhamBenh = (data: any) => {
    let url = API_PATH_MEDICAL_EXAM ;
    return axios.post(url, data);
}

export const updateThongTinKhamBenh = (data: any) => {
    let url = API_PATH_MEDICAL_EXAM;
    return axios.put(url, data);
}

export const getDsKetQuaDichVu = async (params: {
	visitId: string;
}): Promise<OctResponse<IKetQuaCanLamSangResponse[]>> => {
	const { data } = await axios.get<OctResponse<IKetQuaCanLamSangResponse[]>>(
		`${API_PATH_MEDICAL_EXAM}/result`,
		{
			params,
		}
	);
	return data;
};

export const cancelMedicalExam = (orderId: any) => {
    let url = API_PATH_MEDICAL_EXAM + `/cancel`;
    return axios.post(url, null, { params: { orderId } });
}

export const inPhieuChiDinhCongKham = async (params: IInPhieuParams) => {
    let url = BASE_URL + "/export/clinical";
    return await axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuChiDinhCLS = async (params: IInPhieuParams) => {
    let url = API_PATH_MEDICAL_EXAM + "/export/paraclinical";
    return await axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuPhauThuatXNCLS = async (params: IInPhieuParams) => {
    let url = BASE_URL + "/export/clinical/test/result";
    return await axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inToDieuTri = async (params: IInPhieuParams) => {
    let url = BASE_URL + "/medical-exam/export/treatment-sheet";
    return await axios.post<Blob>(url, params, { responseType: 'blob' });
};

export const inPhieuChuyenPK= async (params: IInPhieuParams) => {
    let url = BASE_URL + "/export/medical-exam";
    return await axios.post<Blob>(url, params, { responseType: 'blob' });
};