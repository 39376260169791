import { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from "react";
import { Button} from "react-bootstrap";
import { getWfWorkflows, updateWfWorkflows } from "../services/WfWorkflowsService";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import wfAxiosGenerator from "../utils/WfAxiosGenerator";
import { localStorageItem } from '../../utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts'

interface WfAction {
  componentName: string,
  nameReq: string,
  submit: () => void,
}
interface WfProps {
  tracker: string,
  objectId: string,
  additionalFunc?: (currStatusId?: string | undefined, codeButton?: string | undefined) => void,
  handleClick?: (props: WfAction) => void;
  additionalProps?: any,
  forceRender?: boolean;
  prefixFunction?: (currentStatusCode: string, currentStatusId: number | null) => Promise<any>;
}

const BASE_ENDPOINT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL || "";

const WfButton: FunctionComponent<WfProps> =  memo((wfProps: WfProps) => {
  const [statusCode, setStatusCode] = useState<any>();
  const [config, setConfig] = useState<any>();
  const [newStatusName, setNewStateName] = useState<string | undefined>();
  const [oldStatusName, setOldStatusName] =  useState<string| undefined>();
  const [wfWorkflowsReqNew, setWfWorkflowsReqNew] = useState<WfWorkflowsReq>();
  const [wfWorkflowsReqOld, setWfWorkflowsReqOld] = useState<WfWorkflowsReq>();
  const [wfAxiosRequests, setWfAxiosRequests] = useState<any>();

  const statusIdRef = useRef<Record<'old'| 'current'| 'new', number | null>>({
    old: null,
    current: null,
    new: null,
  });

  const updatePageData =  useMemo(() => async () => {
    try {
      let { data } = await getWfWorkflows(wfProps.tracker, wfProps.objectId);
      if (data?.code === CODE.SUCCESS) {
        updateStates(data?.data);
      }
    } catch (e) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    }
  },[wfProps.tracker, wfProps.objectId] );
  useEffect(() => {
    updatePageData();
  }, [wfProps.tracker, wfProps.objectId, wfProps?.forceRender, updatePageData]);

  const changeStatus = async (wfWorkflowsReq : WfWorkflowsReq | undefined, codeRequest?: string) => { 
    if (!wfWorkflowsReq) return;
    try {
      let { data } = await updateWfWorkflows(wfProps.tracker, wfWorkflowsReq);
		  data?.data && updateStates(data?.data);
		  if (wfProps.additionalFunc) wfProps.additionalFunc(codeRequest);
    } catch (error) {
      console.error(error);
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };
 
  const updateStates = (wfWorkflowsRes : WfWorkflowsRes) => {
    wfWorkflowsRes.additionalConfiguration && setConfig(JSON.parse(wfWorkflowsRes.additionalConfiguration));
    setStatusCode({ old: wfWorkflowsRes.oldStatusCode, curr: wfWorkflowsRes.currStatusCode, new:  wfWorkflowsRes.newStatusCode });
    setNewStateName(wfWorkflowsRes.newStatusName);
    setOldStatusName(wfWorkflowsRes.oldStatusName);
    setWfWorkflowsReqNew({objectId: wfProps.objectId, status: wfWorkflowsRes.newStatusId})
    setWfWorkflowsReqOld({objectId: wfProps.objectId, status: wfWorkflowsRes.oldStatusId})
    setWfAxiosRequests(wfAxiosGenerator(BASE_ENDPOINT, wfWorkflowsRes.additionalConfiguration));
    statusIdRef.current = {
      old: wfWorkflowsRes.oldStatusId,
      current: wfWorkflowsRes.currStatusId,
      new: wfWorkflowsRes.newStatusId,
    };
  }

  const handleButtonClick = (additionalButton: any) => {
    const submit = () => {
      additionalButton.handleClick(wfProps);
      wfProps.additionalFunc?.(statusCode.curr, additionalButton.code);
    }
    if (additionalButton?.componentName && wfProps.handleClick) {
      wfProps.handleClick({
        componentName: additionalButton.componentName,
        nameReq: additionalButton.name,
        submit: submit,
      });
    } else {
      submit();
    }
  };

  const handleReq = (statusReq: WfWorkflowsReq | undefined, codeReq: string, nameReq: string, componentName: string) => {
    const actions = wfAxiosRequests?.actions?.[codeReq];
    const submit = async () => {
      if (actions) {
        for (const func of actions) {
          await func(wfProps);
        }
      }

      if (wfProps.prefixFunction) {
        try {
          await wfProps.prefixFunction(statusCode.curr, statusIdRef.current.current);
          await changeStatus(statusReq, codeReq);
        } catch (error) {
           console.error(error);
           toast.error(RESPONSE_MESSAGE.ERROR);
        }
        return;
      } 
      
      await changeStatus(statusReq, codeReq);
    }

    if (componentName && wfProps.handleClick) { 
      wfProps.handleClick({
        componentName: componentName,
        nameReq: nameReq,
        submit: submit,
      });
    } else {
      submit();
    };
  };
  
  return (
      <>
        {wfAxiosRequests && wfAxiosRequests.axiosRequests && wfProps.objectId && wfAxiosRequests.axiosRequests.map((additionalButton: any) => (
          <Button className="spaces mx-3 btn btn-fill" key={additionalButton.code} onClick={() => handleButtonClick(additionalButton)}>
            {additionalButton.name}
          </Button>
        ))}
        { oldStatusName && wfProps.objectId && <Button
          className="min-w-120 spaces mx-3 btn btn-fill"
          onClick={() => handleReq(wfWorkflowsReqOld, statusCode.old, oldStatusName, config?.componentNameOldReq)}
        >
          <span>{config?.oldStatusName ? config?.oldStatusName : oldStatusName}</span>
        </Button>}
       { newStatusName && wfProps.objectId && <Button
          className="min-w-120 spaces mx-3 btn btn-fill"
          onClick={() => handleReq(wfWorkflowsReqNew, statusCode.new, newStatusName, config?.componentNameNewReq)}
        >
          <span>{config?.newStatusName ? config?.newStatusName : newStatusName}</span>
        </Button>}
      </>
);
});

export default WfButton;
