import axios from 'axios'
import { OctResponse } from '../../models/OctResponse';
import { urlParamsConfig } from '../../utils/ParamsUtils';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
import { IMauKetQuaCDHARequest, IMauKetQuaCDHASearchParams } from '../models/ModelsPhanHeCDHAVaTDCN';
import { IInPhieuParams } from '../../models/params';

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

export const getRispacsGroups = (searchObject: any) => {
  let url = BASE_URL + `/rispacs-groups/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getInforBnCDHA = (id: string) => {
  let url = BASE_URL + `/rispacs-groups/${id}/patient`
  return axios.get(url);
}

export const getInforPhieuCDHA = (id: string) => {
  let url = BASE_URL + `/rispacs-groups/${id}/detail`
  return axios.get(url);
}

export const updateObsValue = (data: any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.put(url, data);
};

export const createObsValue = (data : any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.post(url, data);
};

export const getObsValue = (searchObject : any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getStatusSummary = () => {
  let url = BASE_URL + `/wf-workflows/rispacs/status/summary`
  return axios.get(url);
}

export const convertFileToHTML = async (form: FormData): Promise<OctResponse<string>> => {
	const { data } = await axios.post<OctResponse<string>>(
		`${BASE_URL}/rispacs-obs/upload-result`,
		form
	);
	return data;
};

export const saveMauKetQuaCDHA = async (params: IMauKetQuaCDHARequest): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${BASE_URL}/rispacs-templates/save`,
		params
	);
	return data;
};

export const getDanhSachMauKetQuaCDHA = async (params: IMauKetQuaCDHASearchParams): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${BASE_URL}/rispacs-templates/search`,
		params
	);
	return data;
};

export const deleteMauKetQuaCDHA = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.delete<OctResponse<any>>(
		`${BASE_URL}/rispacs-templates/delete-by-id/${id}`,
	);
	return data;
};

export const returnResult = (orderId : any) => {
	let url = BASE_URL + `/rispacs-groups/${orderId}/release-result`
	return axios.post(url, {});
  };
  
  export const updateTimes = (objectParam: any) => {
	let url = BASE_URL + `/rispacs-groups/${objectParam?.orderId}/update-times`
	return axios.put(url, {
		resultReportingTime: objectParam?.resultReportingTime
	});
  };
  
  export const inPhieuKetQuaCLS = async (params: IInPhieuParams) => {
	let url = BASE_URL + "/export/rispacs/result";
	return await axios.post<Blob>(url, params, { responseType: 'blob' });
  };
  