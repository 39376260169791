import clsx from 'clsx';
import { useField } from 'formik';
import Fuse from 'fuse.js';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Form, FormControlProps, ListGroup, Overlay, Popover } from 'react-bootstrap';
import './style.scss';

type Props = Omit<FormControlProps, 'onChange'> & {
	label: string;
	name: string;
	options: string[];
	className: string;
	inputClassName?: string;
	labelClassName?: string;
	onChange?: (value: string) => void;
	value?: string;
	required?: boolean;
	[x: string]: any;
};

const InputAutoComplete = ({
	label,
	name,
	options,
	className,
	inputClassName,
	labelClassName,
	onChange,
	value,
	required,
	...props
}: Props) => {
	const [isShow, setIsShow] = useState<boolean>(false);
	const [suggestOptions, setSuggestOptions] = useState<string[]>([]);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const [field, { error, touched }, { setValue }] = useField(name);
	const { value: query } = field;

	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const initialOptionsRef = useRef<string[]>([]);

	const rowSelected = suggestOptions[selectedIndex];

	useEffect(() => {
		if (options.length) {
			setSuggestOptions(options);
			initialOptionsRef.current = options;
		}
	}, [options]);

	useEffect(() => {
		window.addEventListener('click', checkMouseClickOutSide, true);
		return () => {
			window.removeEventListener('click', checkMouseClickOutSide, true);
		};
	}, []);

	useEffect(() => {
		if (!query) {
			setSelectedIndex(-1);
		}
	}, [query]);

	useEffect(() => {
	  setValue(value);
	}, [value]);

	const checkMouseClickOutSide = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			setIsShow(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setValue(inputValue);
		onChange && onChange(inputValue);

		if (!inputValue) {
			setSuggestOptions(initialOptionsRef.current);
			return;
		}

		const fuse = new Fuse(initialOptionsRef.current, {
			includeScore: true,
			includeMatches: true,
			findAllMatches: true,
		});
		const result = fuse.search(inputValue).map(record => record.item);
		setSuggestOptions(result);
	};

	const renderList = (options: string[]) => {
		return (
			<Popover
				className="input-autocomplete-popover"
				style={{
					minWidth: inputRef.current?.offsetWidth,
				}}
			>
				<Popover.Body className="p-2">
					{Boolean(options.length) && (
						<ListGroup className="mt-2 spaces max-h-200 overflow-auto" tabIndex={-1}>
							{options.map((option, index) => (
								<ListGroup.Item
									className={clsx('input-autocomplete-option', {
										selected: index === selectedIndex,
									})}
									action
									key={index}
									onClick={(e: React.MouseEvent<Element>) => {
										e.preventDefault();
										onRowSelect(option);
										setSelectedIndex(index);
									}}
									tabIndex={-1}
								>
									{option}
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Popover.Body>
			</Popover>
		);
	};

	const onContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		const dataLength = suggestOptions.length;

		switch (e.key) {
			case 'ArrowDown':
				if (!isShow) {
					setIsShow(true);
					return;
				}
				setSelectedIndex(selectedIndex === dataLength - 1 ? 0 : selectedIndex + 1);
				break;

			case 'ArrowUp':
				setSelectedIndex(selectedIndex === 0 ? dataLength - 1 : selectedIndex - 1);
				break;

			case 'Enter':
				e.preventDefault();
				onRowSelect(rowSelected);
				break;
				
			case 'Tab':
			case 'Escape':
				setIsShow(false);
				break;

			default:
				break;
		}
	};

	const onRowSelect = (row: any) => {
		setValue(row);
		setIsShow(false);
	};

	return (
		<div className={className} ref={containerRef} onKeyDown={onContainerKeyDown}>
			<div className="d-flex align-items-center">
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0',
						labelClassName
					)}
					dangerouslySetInnerHTML={{
						__html: required
							? `${label}<strong class='text-danger ps-1'>(*)</strong>`
							: `${label}`,
					}}
				></Form.Label>

				<div className="position-relative w-100">
					<Form.Control
						className={clsx('spaces px-4 customs-input', {
							inputClassName,
							'is-invalid': !!error && touched,
						})}
						ref={inputRef}
						{...field}
						value={query}
						onFocus={() => {
							setIsShow(true);
						}}
						onChange={onSearch}
						{...props}
					/>
					<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
						{error}
					</Form.Control.Feedback>
				</div>
			</div>

			<Overlay
				placement="bottom-start"
				show={isShow && !!suggestOptions.length}
				target={inputRef.current}
				container={containerRef.current}
				rootClose
				offset={[0, 5]}
			>
				{renderList(suggestOptions)}
			</Overlay>
		</div>
	);
};

export default InputAutoComplete;
