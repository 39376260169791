import React, { FC, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { Column } from "react-table";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { CODE_MENU, KEY, KEY_DS_DANH_MUC_TIEP_DON, SELECTION_MODE } from "../../../utils/Constant";
import { Form, Formik, FormikErrors } from "formik";
import ContextMenu from "../../../component/ContextMenuV3";
import ModalICD10 from "../modal-icd10/ModalICD10";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";

type Props = {
  handleClose: () => void;
  handleSelectICD10: (dataDiagnosis: any, dataComorbidity: any) => void;
  listICD10: any;
};

const ModalChonBenhICD10 = (props: Props) => {
  const { handleClose, handleSelectICD10, listICD10 } = props;
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();

  const handleSubmitForm = (values: any) => {
    // TODO: submit form
    const diseaseMain = values?.clinicDiagnosis.find((value: any) => value?.isDiseaseMain === true);
    const dataDiagnosis = values?.clinicDiagnosis?.filter((value: any) => value?.isDiseaseMain === false);
    if (diseaseMain) {
      handleSelectICD10(diseaseMain, dataDiagnosis || []);
      handleClose();
    } else {
      toast.warning("Vui lòng chọn bệnh chính!");
    }
  };

  return (
    <>
      <Modal
        show
        centered
        size="lg"
        className="dialog-background"
        onHide={() => {
          handleClose();
        }}
      >
        <Formik<any>
          initialValues={{}}
          // validationSchema={validationSchema}
          validateOnChange={true}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            return errors;
          }}
          onSubmit={(values: any) => handleSubmitForm(values)}
        >
          {({
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            values,
            setValues,
          }) => (
            <FormBenhICD10
              handleSubmit={handleSubmit}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              values={values}
              handleClose={handleClose}
              setValues={setValues}
              selectedRowIndex={selectedRowIndex}
              setSelectedRowIndex={setSelectedRowIndex}
              listICD10={listICD10}
            />
          )}
        </Formik>
      </Modal>
    </>
  );
};

const FormBenhICD10: FC<any> = (props) => {
  const { 
    handleSubmit,
    setFieldValue,
    values,
    setValues,
    selectedRowIndex,
    setSelectedRowIndex,
    listICD10
  } = props; 

  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();
  const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);

  const columnsDSBenh: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className={`text-center fs-14 ${props.data[props.row.index]?.isDiseaseMain ? "text-danger" : ""}`}
        />
      )
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Mã bệnh"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Mã bệnh",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className={`text-center fs-14 ${props.data[props.row.index]?.isDiseaseMain ? "text-danger" : ""}`}
          data={props.data[props.row.index]?.termCode}
        />
      )
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Tên bệnh"}
          className=" text-center text-white align-middle bg-pri min-w-300px"
        />
      ),
      id: "Tên bệnh",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.termName}
          className={`s-2 fs-14 ${props.data[props.row.index]?.isDiseaseMain ? "text-danger" : ""}`}
        />
      )
    }
  ];

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
    setSelectedRow(row);
    setSelectedRowIndex(row.index);
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setDanhSachDanhMuc(responseDsDanhmuc);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  useEffect(() => {
    setFieldValue("clinicDiagnosis", listICD10)
  }, [listICD10]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]);


  useEffect(() => {
    let diseaseMain = values?.clinicDiagnosis?.find((item: any) => item?.isDiseaseMain);
    diseaseMain && setSelectedRow(diseaseMain);
  }, [values?.clinicDiagnosis]);

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      provisionalDiagnosisName:
        danhSachDanhMuc?.listICD10.find(
          (item: any) => item.code === e.target.value
        )?.name || ""
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === KEY.ENTER) {
      event.preventDefault();
      let hasDiagnosis = values?.clinicDiagnosis?.some((item: any) => {
        return item.termCode === values?.provisionalDiagnosis;
      });
      if(danhSachDanhMuc?.listICD10?.find((item: any) => item?.code === values?.provisionalDiagnosis)) {
        if (values?.provisionalDiagnosis && !hasDiagnosis) {
          let valueAdd = {
            termName: values?.provisionalDiagnosisName,
            termCode: values?.provisionalDiagnosis,
            isDiseaseMain: false,
          };
          let newArr = [];
          if (values?.clinicDiagnosis.length) {
            newArr = [...values?.clinicDiagnosis, valueAdd];
          } else {
            newArr = [
              {
                ...valueAdd,
                isDiseaseMain: true
              }
            ];
          }
          setFieldValue("clinicDiagnosis", newArr);
        } else {
          setFieldValue("clinicDiagnosis", [...values?.clinicDiagnosis]);
        }
      }
      else {
        toast.warning("Mã bệnh không tồn tại!")
        return
      }
      handleSetInitValue();
    }
  };

  const handleSetInitValue = () => {
    setFieldValue("provisionalDiagnosis", null);
    setFieldValue("provisionalDiagnosisName", null);
  };

  const handleDeleteItem = (selectedRow: any) => {
    let dataDSBenhUpdated = values?.clinicDiagnosis?.filter(
      (item: any, idx: number) => idx !== selectedRow?.index
    );
    setFieldValue("clinicDiagnosis", dataDSBenhUpdated);
  };

  const handleHighlightText = (rowData: any) => {
    // setFieldValue("diseaseMain", {
    //   ...value,
    //   isDiseaseMain: true
    // });
    let newClinicDiagnosis = cloneDeep(values?.clinicDiagnosis || []);
    let clinicDiagnosis = newClinicDiagnosis?.map((item: any, idx: number) => {
      if (item?.termCode === rowData?.termCode) {
        item.isDiseaseMain = true;
      } else {
        item.isDiseaseMain = false;
      }
      return item;
    });
    setFieldValue("clinicDiagnosis", clinicDiagnosis);
  };

  const handleClickOptionContextMenu = (value: any) => {
    const options = {
      [CODE_MENU.XOA_BENH]: () => handleDeleteItem(selectedRow)
    };
    options[value?.code as string]();
    setContextMenu(null);
  }

  const handleSelectFromModal = (value: any) => {
    setValues({
      ...values,
      provisionalDiagnosis: value?.code,
      provisionalDiagnosisName: value?.name
    });
  }

  const handleGetSelectedRowsData = (rowData: any) => {
    handleHighlightText(rowData);
  }

  return (
    <Form id="form-chan-doan">
      <Modal.Header closeButton className="modal-header">
        <Modal.Title className="text-transform-none">Bệnh ICD10</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs="3" className="pe-0">
            <LabelRequired label="Mã bệnh - ICD10" className="" />
          </Col>
          <Col xs="9" className="px-0">
            <LabelRequired label="Tên bệnh - ICD10" className="" />
          </Col>
        </Row>
        <Row className="spaces pe-0 align-items-center">
          <Col xs="3" className="pe-0 position-relative">
            <TextField
              name="provisionalDiagnosis"
              value={values?.provisionalDiagnosis || ""}
              onChange={(e: any) => handleChange(e)}
            />
            <div className="spaces width-4">
              <h6
                className="position-absolute muc-huong cursor-pointer"
                onClick={() => {
                    setShouldOpenModalICD10(true);
                }}
              >
                <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
              </h6>
            </div>
          </Col>
          <Col xs="9" className="spaces px-0 position-relative">
            <TextField
              name="provisionalDiagnosisName"
              value={values?.provisionalDiagnosisName || ""}
            />
          </Col>
        </Row>
        <Row className="spaces mt-3">
          <Col xs="12" className="mb-5 grid-2-10 spaces px-0">
            <LabelRequired label="Tên bệnh" className="" />
            <TextField
              name="tenBenh"
              value={values?.provisionalDiagnosisName || ""}
            />
          </Col>
          <Col xs="12" className="spaces px-0">
            <TableCustom<any>
              className={"h-bang-ds-bn spaces h-calc-vh-552"}
              data={values?.clinicDiagnosis || []}
              columns={columnsDSBenh}
              handleDoubleClick={() => {}}
              verticalScroll={true}
              handleContextMenu={handleContextMenu}
              selectionMode={SELECTION_MODE.SINGLE}
              getSelectedRowsData={(rowData: any[]) => handleGetSelectedRowsData(rowData[0])}
              rowSelected={selectedRow}
              rowKey="termCode"
              singleSelectColumnLabel="Bệnh chính"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-fill spaces W-80"
          onClick={handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>

      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={[
            {
              title: "ICD10"
            },
            {
              icon: <i className="bi bi-x-lg text-danger"></i>,
              code: CODE_MENU.XOA_BENH,
              name: "Xóa",
            },
          ]}
          target={contextMenu}
        />
      )}

      {shouldOpenModalICD10 && (
        <ModalICD10
          data={danhSachDanhMuc?.listICD10 || []}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleSelectFromModal}
          selectionMode={"radio"}
          dataChecked={listICD10}
        />
      )}
    </Form>
  );
};

export default ModalChonBenhICD10;
