import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import DanhSachPhieu from "../../../component/DanhSachPhieu";
import LabelRequired from "../../../component/LabelRequired";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import {
  CODE,
  KEY,
  KEY_DS_DANH_MUC_TIEP_DON,
  LIST_COLOR_TRANG_THAI_PHIEU,
  ORDER_TYPE_ID,
  RESPONSE_MESSAGE,
} from "../../../utils/Constant";
import { formatDateAdvanceToString } from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import WfButton from "../../../workflows/components/WfButton";
import {
  WfConstTracker,
  WfConstTrackerClinical,
} from "../../../workflows/Constant";
import { columnsDSXetNghiem } from "../../columns/tab-xet-nghiem/ColumnTabXetNghiem";
import DynamicComponentKB from "../../components/DynamicComponentKB";
import { CODE_CONTEXT_TAB_XET_NGHIEM } from "../../constants/ContextConstants";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import "../../PhanHeKhamBenh.scss";
import {
  deleteDSChiDinhDV,
  getDSDVDaChiDinh,
  getDSNhomDVDaChiDinh,
} from "../../services/ChiDinhDVService";
import {
  fetchGuiPhieu,
  fetchHuyGuiPhieu,
  getStatusDesc,
} from "../../services/KhamBenhService";
import CheckIcon from "../../components/CheckIcon";
import ButtonInPhieu from "../../../component/button-in-phieu";
import { inPhieuChiDinhCLS, inPhieuPhauThuatXNCLS } from "../../services/PhanHeTiepDonServer";
import ShowStatus from "../../../component/ShowStatus";
import { IInPhieuParams } from "../../../models/params";
import { classIdTabKhamBenh } from "../../constants/KhamBenh";

interface Iprops {
  danhSachPhieu?: boolean;
}

export const TabXetNghiem = ({}: Iprops) => {
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } =
    useContext(PhanHeTiepDonContext);
  let { visit, encounterDiagnosis, isConclusion, patient, orderId } =
    benhNhanInfo?.thongTinKhamBenh || {};
  const [dsXetNghiemDetail, setDsXetNghiemDetail] = useState<any[]>([]);
  const [dsPhieu, setDsPhieu] = useState<any[]>([]);
  const [selectedPhieu, setSelectedPhieu] = useState<any>(null);
  const [openPhieuIn, setOpenPhieuIn] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>();
  const [idTable, setIdTable] = useState("");
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [contextClientX, setContextClientX] = useState<number>(0);
  const [listICD10, setListICD10] = useState<any>([]);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [statusDesc, setStatusDesc] = useState<any>([]);
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");

  const handleGetStatusDesc = async () => {
    try {
      let { data } = await getStatusDesc(WfConstTracker.TEST);
      if (data?.code === CODE.SUCCESS) {
        setStatusDesc(data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetStatusDesc();
  }, []);
  const actionChanged = useCallback(
    async (currStatusCode: string | undefined) => {
      try {
        let res;
        switch (currStatusCode) {
          case "order_group_cancel":
            res = await fetchHuyGuiPhieu(selectedPhieu?.id);
            break;
          case "order_group_submit":
            res = await fetchGuiPhieu(selectedPhieu?.id);
            break;
          default:
            res = null;
            break;
        }
        if (CODE.SUCCESS === res?.data?.code) {
          statusChangedEventFunc?.();
          handleSelectPhieu(selectedPhieu);
          setBenhNhanInfo({
            ...benhNhanInfo,
            shouldUpdate: !benhNhanInfo?.shouldUpdate,
          });
          toast.success("Thành công");
        } else {
          toast.warning(res?.data?.message || RESPONSE_MESSAGE.ERROR);
        }
      } catch (error) {
        toast.warning(RESPONSE_MESSAGE.ERROR);
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo]
  );

  const handleContextMenu = (e: any, row: any, code?: string) => {
    setRowSelected(row);
    setIdTable(code as string);
    e.preventDefault();
    setContextClientX(e.clientX);

    const heightDropList =
      document.getElementById("drop-list")?.clientHeight || 0;
    const isOnRight = window.innerWidth - e.clientX < 200;
    const newX = isOnRight ? e.clientX - 200 : e.clientX;
    const isBottom = window.innerHeight - e.clientY < 200;
    const newY = isBottom ? e.clientY - heightDropList : e.clientY;

    setContextMenu({ x: newX, y: newY });
  };

  useEffect(() => {
    !dsPhieu.length && setDsXetNghiemDetail([]);
  }, [benhNhanInfo, dsPhieu]);

  const handleSelectPhieu = async (dataPhieu: any) => {
    try {
      setSelectedPhieu(dataPhieu);
      let { data } = await getDSDVDaChiDinh({
        orderGroupId: dataPhieu?.id,
        type: ORDER_TYPE_ID.XET_NGHIEM,
      });
      if (CODE.SUCCESS === data?.code) {
        const newData = data?.data?.map((item: any) => {
          return {
            ...item,
            terms: item?.terms?.map((term: any) => {
              if (term?.subs) {
                return {
                  ...term,
                  name: (
                    <CheckIcon
                      data={term}
                      color={item?.color}
                      className="ml-2"
                    />
                  ),
                  subs: term?.subs?.map((sub: any) => {
                    return {
                      ...sub,
                      name: <CheckIcon data={sub} color={item?.color} />,
                    };
                  }),
                };
              } else {
                return {
                  ...term,
                  name: <CheckIcon data={term} color={item?.color} />,
                };
              }
            }),
          };
        });
        setDsXetNghiemDetail(newData || []);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDSChiDinhDV(selectedPhieu?.id);
      setBenhNhanInfo({
        ...benhNhanInfo,
        shouldUpdate: !benhNhanInfo?.shouldUpdate,
      });
      statusChangedEventFunc?.();
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };

  const updataData = async () => {
    try {
      let { data } = await getDSNhomDVDaChiDinh({
        orderId,
        type: ORDER_TYPE_ID.XET_NGHIEM,
      });
      if (CODE.SUCCESS === data?.code) {
        let dsPhieuConverted = data?.data?.map((item: any) => {
          return {
            ...item,
            date: moment(item?.indicationTime).format("DD/MM/YYYY"),
          };
        });
        setDsPhieu(dsPhieuConverted || []);
        setSelectedPhieu(null);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickWfButton = (data: any) => {
    setDynamicData(data);
  };

  useEffect(() => {
    if (orderId) {
      updataData();
    }
  }, [benhNhanInfo?.shouldUpdate, orderId]);

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    let orderGroupId = selectedPhieu?.id;
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        orderGroupId,
      },
    });
  }, [selectedPhieu]);

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F2:
        e.preventDefault();
        setKeyOpenPhieuIn("F2");
        break;
      case KEY.F3:
        e.preventDefault();
        setKeyOpenPhieuIn("F3");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  return (
    <Row className="h-100">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.code || "..."}
              </span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {formatDateAdvanceToString(selectedPhieu?.indicationTime)}
              </span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">
                {selectedPhieu?.indicationPerson}
              </span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.requestDeptName || "..."}
              </span>
            </Col>
            <Col
              xs={8}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.provisionalDiagnosisName || "..."}
              </span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            handleSelectRowData={handleSelectPhieu}
            dsPhieu={dsPhieu as []}
            handleContextMenu={(e, row) =>
              handleContextMenu(e, row, CODE_CONTEXT_TAB_XET_NGHIEM.DS_PHIEU)
            }
            listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSXetNghiem}
            data={dsXetNghiemDetail || []}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-459`}
            handleClickRow={() => {}}
            isFromTabXN
          />
        </div>
        <div className="d-flex justify-content-between p-2 gap-3 bg-white">
          <div className="d-flex">
            {visit?.id && patient?.id && selectedPhieu?.id && (
              <>
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định (F2)"
                  fetchExport={inPhieuChiDinhCLS}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                    orderGroupId: selectedPhieu?.id,
                    orderTypeId: ORDER_TYPE_ID.XET_NGHIEM,
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  keyBtn={KEY.F2}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                />
                <ButtonInPhieu<IInPhieuParams>
                  label="Phiếu phẫu thuật (F3)"
                  fetchExport={inPhieuPhauThuatXNCLS}
                  params={{
                    orderGroupId: selectedPhieu?.id,
                    type: classIdTabKhamBenh.XET_NGHIEM.classId,
                  }}
                  keyBtn={KEY.F3}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                />
              </>
            )}
            {selectedPhieu && !isConclusion && (
              <WfButton
                tracker={WfConstTrackerClinical.ORDER_GROUP}
                objectId={selectedPhieu?.id}
                additionalFunc={actionChanged}
                handleClick={handleClickWfButton}
              ></WfButton>
            )}
          </div>

          <div className="flex-grow-1">
            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-end h-100"
            >
              <ShowStatus
                className="d-flex spaces gap-8"
                statuses={statusDesc}
              />
            </Stack>
          </div>
        </div>
      </div>
      {dynamicData && (
        <DynamicComponentKB
          data={dynamicData}
          setData={setDynamicData}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Row>
  );
};

export default TabXetNghiem;
