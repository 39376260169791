import axios, { AxiosResponse } from "axios";
import { IBenhNhanResponse, IUpdateStatusResponse } from "../models/DSBenhNhanKhamBenhModels";
import { searchPatientParams, updatePatientStatusParams } from "../models/IParams";
import { paramsConfig, urlParamsConfig } from "../../utils/ParamsUtils";
import { iCreateEncounter } from "../models/ThongTinKhamBenhModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_CONCEPTS = BASE_URL + "/concepts/structures";
const API_PATH_ENCOUNTERS = BASE_URL + "/encounters";
const CIS_ENCOUNTERS = process.env.REACT_APP_CIS_API_URL + "/encounters";
const CIS = process.env.REACT_APP_CIS_API_URL;
const ERM = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

export const searchByPage = () => {
  let url = API_PATH_CONCEPTS + "/TTKB";
  return axios.get(url);
};

export const encountersApi = {
  searchPatient: (
    searchObj: searchPatientParams
  ): Promise<AxiosResponse<IBenhNhanResponse>> => {
    let url: string = API_PATH_ENCOUNTERS + `/search`;
    return axios.get(urlParamsConfig(url, searchObj));
  },
  updateStatus: (params: updatePatientStatusParams): Promise<AxiosResponse<IUpdateStatusResponse>> => {
    let url: string = API_PATH_ENCOUNTERS + '/update-status';
    return axios.post(url, params);
  }
};

export const createEncounter = (data: iCreateEncounter) => {
  let url = CIS_ENCOUNTERS + "/kham-benh"
  return axios.post(url, data)
}

export const getKhamBenh = (searchObject: any) => {
  let url = CIS + "/obs/diagnosis-detail"
  return axios.get(url, paramsConfig(searchObject))
}

export const createKhamBenh = (data: any) => {
  let url = CIS + "/obs/diagnosis"
  return axios.post(url, data)
}

export const updateKhamBenh = (data: any) => {
  let url = CIS + "/obs/diagnosis/edit";
  return axios.put(url, data);
}

export const getLoaiBenh = (searchObject: any) => {
  let url = CIS + "/concepts/icd10";
  return axios.get(url, paramsConfig(searchObject));
}

export const getLichSuKham = (searchObject: any) => {
  let url = CIS + "/encounters/history";
  return axios.get(url, paramsConfig(searchObject));
}

export const getDSXuTri = () => {
  let url = CIS + "/concepts/xu-tri";
  return axios.get(url);
}

export const updateXuTri = (data: any) => {
  let url = ERM + "/medical-conclusions";
  return axios.put(url, data);
}

export const getDataXuTriService = (id: string) => {
  let url = ERM + `/medical-conclusions/${id}`;
  return axios.get(url);
}

export const fetchMoLaiBenhAn = (data: any) => {
  let url = ERM + `/medical-conclusions/cancel`;
  return axios.post(url, data);
}

export const fetchHuyGuiPhieu = (orderGroupId: string) => {
  let url = ERM + `/orders/revoke`;
  return axios.delete(url, { params: { orderGroupId } });
};

export const fetchGuiPhieu = (orderGroupId: string) => {
  let url = ERM + `/orders/send`;
  return axios.post(url, null, { params: { orderGroupId } });
};

export const getInfoBenhNhan = (searchObject: any) => {
  let url = ERM + `/visit-combined-clinic`;
  return axios.get(url, paramsConfig(searchObject));
}

export const createKhamKetHop = (data: any) => {
  let url = ERM + `/visit-combined-clinic`
  return axios.post(url, data)
}

export const getStatusDesc = (tracker: string) => {
  let url = ERM + `/wf-workflows/${tracker}/statuses`;
  return axios.get(url);
}

export const getDsPhieuChuaTraKQ = (visitId: string, searchObject: any) => {
  let url = ERM + `/orders/ongoing/${visitId}`;
  return axios.get(url, paramsConfig(searchObject));
}

export const getThongTinChanDoan = (patientId: string) => {
  let url = ERM + `/encounter-diagnosis/previous/by-patient-id/${patientId}`;
  return axios.get(url);
}
