import { resolve } from 'path';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ButtonInPhieu from '../../../../../component/button-in-phieu';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import { CODE, KEY, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import WfButton from '../../../../../workflows/components/WfButton';
import { WfConstTrackerClinical } from '../../../../../workflows/Constant';
import { WORKFLOW_MEDICAL_ITEM_STATUS_CODE } from '../../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import PageKeThuoc from '../../../../modals/modal-ke-thuoc/PageKeThuoc';
import { IInPhieuChiDinhThuocParams } from '../../../../models/ThuocModels';
import {
	deleteDonThuoc,
	huyGuiDonThuoc,
	printPhieuChiDinhThuoc,
	saveDonThuoc,
} from '../../../../services/ThuocService';
import { useTabThuocContext } from '../../context';

type Props = {
	getDanhSachDonThuoc: () => void;
};

const ThaoTacDonThuoc = ({ getDanhSachDonThuoc }: Props) => {
	const { donThuocInfo, setDonThuocSelectedIndex, actionsRef } = useTabThuocContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const [guiDonThuoc, setGuiDonThuoc] = useState<boolean>();
	const [openDonThuocDialog, setOpenDonThuocDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>('');
	const [isUpdateWorkflow, setIsUpdateWorkflow] = useState(false);

	const { patient, visit, encounter } = benhNhanInfo?.thongTinKhamBenh || {};
	const isKetThucLanKham = benhNhanInfo?.thongTinKhamBenh?.isConclusion;

	const onDeleteDonThuoc = async () => {
		try {
			const response = await deleteDonThuoc(donThuocInfo.id);
			if (CODE.SUCCESS === response.code) {
				toast.success(RESPONSE_MESSAGE.DELETE.SUCCESS);
				setOpenConfirmDialog(false);
				getDanhSachDonThuoc();
				setDonThuocSelectedIndex(0);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const handlePressKeyShortcuts = (e: any) => {
		switch (e.key) {
			case KEY.F3:
				e.preventDefault();
				setKeyOpenPhieuIn('F3');
				break;
			default:
				return;
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handlePressKeyShortcuts);
		return () => {
			window.removeEventListener('keydown', handlePressKeyShortcuts);
		};
	}, []);

	useEffect(() => {
		setGuiDonThuoc(donThuocInfo?.statusCode === WORKFLOW_MEDICAL_ITEM_STATUS_CODE.GUI_DON);
	}, [donThuocInfo?.statusCode]);

	const PrintButton = () => {
		return (
			<ButtonInPhieu<IInPhieuChiDinhThuocParams>
				fetchExport={printPhieuChiDinhThuoc}
				params={{
					fileType: 'pdf',
					patientId: patient.id,
					medicalItemGroupId: donThuocInfo.id,
					visitId: visit.id,
					type: parseInt(ORDER_TYPE_ID.THUOC),
				}}
				label="In đơn thuốc (F3)"
				className="btn-fill"
				keyOpenPhieuIn={keyOpenPhieuIn}
				setKeyOpenPhieuIn={setKeyOpenPhieuIn}
				keyBtn={KEY.F3}
			/>
		);
	};

	const WorkFlowButton = () => {
		return (
			<WfButton
				tracker={WfConstTrackerClinical.MEDICAL_ITEMS}
				objectId={donThuocInfo?.id}
				prefixFunction={(statusCode: string, statusId: number | null) => {
					if (WORKFLOW_MEDICAL_ITEM_STATUS_CODE.CHUA_GUI_DON === statusCode) {
						return saveDonThuoc({
							...donThuocInfo,
							encounterId: encounter?.id,
							orderTypeId: parseInt(ORDER_TYPE_ID.THUOC),
							orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
							lstMedicalItemOrder: donThuocInfo?.lstMedicalItemOrderRes,
						});
					}

					return huyGuiDonThuoc(donThuocInfo?.id);
				}}
				additionalFunc={(status: any) => {
					onUpdateWorkflowStatus(status);
				}}
				forceRender={isUpdateWorkflow}
			></WfButton>
		);
	};

	const onUpdateWorkflowStatus = (workflowStatus: string) => {
		switch (workflowStatus) {
			case WORKFLOW_MEDICAL_ITEM_STATUS_CODE.GUI_DON:
				toast.success('Gửi đơn thuốc thành công');
				break;
			case WORKFLOW_MEDICAL_ITEM_STATUS_CODE.CHUA_GUI_DON:
				toast.success('Hủy gửi đơn thuốc thành công');
				break;
			default:
				break;
		}
		setGuiDonThuoc(workflowStatus === WORKFLOW_MEDICAL_ITEM_STATUS_CODE.GUI_DON);
		actionsRef.current.getDetailDonThuoc(donThuocInfo?.id);
	};

	if (!donThuocInfo?.id) return <div className="bg-white spaces min-h-50"></div>;

	if (isKetThucLanKham) return <div className="d-flex gap-3 bg-white p-2">{PrintButton()}</div>;

	return (
		<div className="d-flex gap-3 justify-content-start p-2 bg-white">
			{guiDonThuoc ? (
				<>
					{PrintButton()}
					{WorkFlowButton()}
				</>
			) : (
				<>
					<Button
						className="btn-navy-outlined mr-5"
						onClick={() => setOpenDonThuocDialog(true)}
					>
						Sửa đơn thuốc
					</Button>
					{WorkFlowButton()}
					<Button
						className="btn-secondary mr-5"
						onClick={() => setOpenConfirmDialog(true)}
					>
						Xóa đơn thuốc
					</Button>
				</>
			)}

			{openDonThuocDialog && (
				<PageKeThuoc
					open={openDonThuocDialog}
					handleClose={() => {
						setOpenDonThuocDialog(false);
					}}
					itemEdit={donThuocInfo}
					onSave={() => {
						setOpenDonThuocDialog(false);
						setIsUpdateWorkflow(!isUpdateWorkflow);
						actionsRef.current.getDetailDonThuoc(donThuocInfo?.id);
					}}
				/>
			)}

			{openConfirmDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openConfirmDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa đơn thuốc này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setOpenConfirmDialog(false);
					}}
					onYesClick={onDeleteDonThuoc}
				/>
			)}
		</div>
	);
};

export default ThaoTacDonThuoc;
