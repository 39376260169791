import { IAllProps } from '@tinymce/tinymce-react';
import RichTextEditor from '../../../component/richtext-editor';

const NhapKetQuaCDHAEditor = (props: IAllProps) => {
	const isTableCellNode = (node: Node) => {
		if (node.nodeName === 'TD') return true;

		while (node.parentNode) {
			node = node.parentNode;
			if (node.nodeName === 'TD') return true;
		}

		return false;
	};

	return (
		<RichTextEditor
			onDrop={(e: DragEvent) => {
				const targetElement = e.target as HTMLElement;
				const file = e.dataTransfer?.files[0];

				if (!file) return;

				const isValidTarget = file && isTableCellNode(targetElement);

				if (isValidTarget) {
					e.preventDefault();

					const reader = new FileReader();
					reader.onload = () => {
						const base64 = reader.result as string;
						const img = document.createElement('img');
						img.style.width = `100%`;
						img.style.height = `100%`;
						img.style.objectFit = 'contain';
						img.style.display = 'block';
						img.src = base64;
						targetElement.replaceChildren(img);
					};
					reader.readAsDataURL(file);
				}
			}}
			{...props}
		/>
	);
};

export default NhapKetQuaCDHAEditor;
