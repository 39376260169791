import React, { FC, useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import CustomTabMenu from "../../../../component/CustomTabMenu";
import { CODE, KEY_DS_TAB_HOP_DONG, RESPONSE_MESSAGE } from "../../../../utils/Constant";
import ThongTinHopDong from "./ThongTinHopDong";
import ThongTinNhanVien from "./ThongTinNhanVien";
import TabKetLuan from "./TabKetLuan";
import TabCDHA from "./TabCDHA";
import TabKhamBenh from "./TabKhamBenh";
import TabXetNghiem from "./TabXetNghiem";
import { Button } from "react-bootstrap";
import "./QuanLyHopDong.scss";
import ModelThemMoiGoiKham from "./models-them-moi-goi-kham/ModelThemMoiGoiKham";
import ModelThemMoiNhanVien from "./models-them-moi-nhan-vien/ModelThemMoiNhanVien";
import ModelDanhSachNhanVien from "./models-them-moi-nhan-vien/ModelDanhSachNhanVien";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong";
import { addStartKhamBenh } from "../../../services/KhamDoanHopDongServices";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { updateWfWorkflows, getWfWorkflows } from "../../tab-kham-lam-sang/services/KhamLamSangSevice";
import { AppContext } from "../../../../appContext/AppContext";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";


type QuanLyHopDongTabProps = {
  setActiveTab?: any,
}
const QuanLyHopDongTab: FC<QuanLyHopDongTabProps> = React.memo(
  ({
    setActiveTab,
  }) => {
    const { setIsLoading } = useContext(AppContext);
    const [openThemMoiGoiKham, setOpenThemMoiGoiKham] = useState<boolean>(false);
    const [openThemMoiNhanVien, setOpenThemMoiNhanVien] = useState<boolean>(false);
    const { isClickOpenEditGK, setIsClickOpenEditGK, setIsClickOpenEditNV, setDataEditGK, isSearchNV, setIsSearchNV, setIsSearchGK, setDataEditNV, TTHopDong, TTNhanVien, setTTNhanVien } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setWorkFlowStatus, workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext)
    const [openDanhSachNhanVien, setOpenDanhSachNhanVien] = useState<boolean>(false);
    const [isShowTab, setIsShowTab] = useState<boolean>(false);
    
    useEffect(() => {
      isClickOpenEditGK && setOpenThemMoiGoiKham(true)
    }, [isClickOpenEditGK])

    const handleOpenThemMoiGoiKham = () => {
      setDataEditGK({});
      setOpenThemMoiGoiKham(true);
      setIsSearchGK(false);
      !openThemMoiGoiKham && setIsClickOpenEditGK(false)
    };
    const handleOpenThemMoiNhanVien = () => {
      setDataEditNV({});
      setIsClickOpenEditNV(false);
      setOpenThemMoiNhanVien(true);
      setIsSearchNV(!isSearchNV);
    };
    const handleOpenDanhSachNhanVien = () => {
      setOpenDanhSachNhanVien(true);
      setIsSearchNV(!isSearchNV);
      setIsSearchGK(false);
    };

    useEffect(() => {
      TTNhanVien?.id && updatePageData();
    }, [TTNhanVien?.id]);

    const updatePageData = async () => {
      try {
        let { data } = await getWfWorkflows("phy_exam", TTNhanVien?.id);
        if (data?.code === CODE.SUCCESS) {
          setWorkFlowStatus(data?.data);
        }
      } catch (e) {
        toast.error(RESPONSE_MESSAGE.ERROR);
      }
    };

    const handlerUpdateWorkFlow = () => {
      const onit = async () => {
        let { data } = await updateWfWorkflows("phy_exam", {
          status: workFlowStatus?.newStatusId,
          objectId: TTNhanVien?.id
        });
        setWorkFlowStatus(data?.data);
      }
      onit();
    }

    const handlerStartKhamBenh = async () => {
      if (TTNhanVien?.id) {
        try {
          setIsLoading(true);
          const { data } = await addStartKhamBenh(TTNhanVien?.id,);
          if (CODE.SUCCESS === data?.code) {
            handlerUpdateWorkFlow();
            setIsSearchNV(!isSearchNV);
            setIsShowTab(true);
            setTTNhanVien({
              ...TTNhanVien,
              dateStarted: data?.data?.visit?.dateStarted
            })
          } else {
            toast.warning(data.data[0]?.errorMessage);
          }
        } catch {
          toast.error(RESPONSE_MESSAGE.ERROR);
        } finally {
          setIsLoading(false);
        }
      } else {
      }
    };

    const handleCloseModalGK = () => {
      setDataEditGK({});
      setOpenThemMoiGoiKham(false);
      setIsSearchGK(true);
      setIsClickOpenEditGK(false);
    };
    const handleCloseModalNhanVien = () => {
      setDataEditNV({});
      setIsClickOpenEditNV(false);
      setOpenThemMoiNhanVien(false);
      setIsSearchNV(!isSearchNV);
    };

    const handleCloseModalDSNhanVien = () => {
      setOpenDanhSachNhanVien(false);
      setIsSearchNV(!isSearchNV);
    };

    const listTab = [
      {
        eventKey: "4",
        title: 'Thông tin hợp đồng',
        component: <ThongTinHopDong />
      },
      {
        eventKey: "5",
        title: 'Thông tin nhân viên',
        component: <ThongTinNhanVien
        />
      },
      ...((isShowTab || TTNhanVien?.patientId) ? [{
        eventKey: "6",
        title: 'Khám bệnh',
        component: <TabKhamBenh
        />
      },
      {
        eventKey: "7",
        title: 'Xét nghiệm',
        component: <TabXetNghiem
        />
      },
      {
        eventKey: "8",
        title: 'CĐHA',
        component: <TabCDHA
        />
      },
      {
        eventKey: "9",
        title: 'Kết luận',
        component: <TabKetLuan
        />
      }] : []),
    ];
    
    return (
      <div className="reception-list__container spaces   bg-white">
        <div className="d-flex border-bottom spaces pl-10 pr-15  gap-10">
          <h4 className="text-title fw-bold fs-4 spaces my-5">
            {`Quản lý hợp đồng | ${TTHopDong?.companyName}`}
          </h4>
          <div className="d-flex justify-content-end align-items-center flex-grow-1">
            <Button
              className="mx-2 spaces min-w-105 h-29 customer-buttom-dk"
              onClick={handleOpenThemMoiGoiKham}
            >
              <span className="customer-buttom-text">Gói khám</span>
            </Button>
            <Button
              className="mx-2 customer-buttom-dk  spaces min-w-124 h-29 "
              onClick={handleOpenThemMoiNhanVien}
            >
              <span className="customer-buttom-text">Đăng ký khám</span>
            </Button>
            <Button
              className="mx-2 customer-buttom-dk  mr-10 spaces min-w-105 h-29 "
              onClick={handleOpenDanhSachNhanVien}
            >
              <span className="customer-buttom-text">Nhân viên</span>
            </Button>
            {TTNhanVien?.id && (
              <>
                {workFlowStatus?.currStatusCode === WF_STATUS.CHUA_DEN_KHAM.CODE && (
                  <Button
                    className="mx-2 btn-fill  spaces min-w-105 h-29"
                    onClick={handlerStartKhamBenh}
                  >
                    <span>Đến khám</span>
                  </Button>
                )}
                {/* <WfButton tracker="phy_exam" objectId={TTNhanVien?.id} additionalFunc={handlerStartKhamBenh}/> */}
                <Button className="mx-2 btn-fill  spaces min-w-60 h-29 ">
                  <span>In</span>
                </Button>
                <Button className="mx-2 btn-fill  spaces min-w-105 h-29 ">
                  <span>Thanh toán</span>
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="bg-white spaces py-5 gap-10">
          <CustomTabMenu
            classTittle="spaces ml-10"
            danhsachTabs={listTab}
            keyDanhSachTabs={KEY_DS_TAB_HOP_DONG}
            childrenTab={false}
          />
        </div>
        {openThemMoiGoiKham && (
          <ModelThemMoiGoiKham
            show={openThemMoiGoiKham}
            onHide={handleCloseModalGK}
          />
        )}
        {openThemMoiNhanVien && (
          <ModelThemMoiNhanVien
            show={openThemMoiNhanVien}
            onHide={handleCloseModalNhanVien}
          />
        )}
        {openDanhSachNhanVien && (
          <ModelDanhSachNhanVien
            onHide={handleCloseModalDSNhanVien}
            onClickBack={() => {
              setOpenDanhSachNhanVien(false);
              setIsSearchNV(!isSearchNV);
              setIsSearchGK(true);
            }}
            show={openDanhSachNhanVien}
          />
        )}
      </div>
    );
  }
);
export default QuanLyHopDongTab;
