import axios from 'axios'
import { urlParamsConfig } from '../../utils/ParamsUtils';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
import { IInPhieuParams } from '../../models/params';

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_TEST_GROUP = BASE_URL + "/test-groups";

export const getListPatientXN = (searchObject: any) => {
  let url = API_PATH_TEST_GROUP + `/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getBenhNhanInfo = (id: any) => {
  let url = API_PATH_TEST_GROUP + `/${id}/patient`;
  return axios.get(url);
};

export const getPhieuXetNghemInfo = (id: any) => {
  let url = API_PATH_TEST_GROUP + `/${id}/detail`;
  return axios.get(url);
};

export const saveObsValue = (data: any) => {
  let url = BASE_URL + `/test-obs`
  return axios.post(url, data);
};

export const updateObsFromLis = (testGroupId : string) => {
  let url = `${BASE_URL}/test-lis-obs`
  return axios.post(url, {testGroupId});
};

export const inPhieuKetQuaCLS = async (params: IInPhieuParams) => {
  let url = BASE_URL + "/export/test/result";
  return await axios.post<Blob>(url, params, { responseType: 'blob' });
};
