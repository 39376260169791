import { IContextMenu } from "../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { templatePhieuChiDinh } from "../components/prints/TemplatePhieuChiDinh";
import TabBenhAnPTTT from "../components/tabs/TabBenhAnPTTT";
import TabEkipPTTT from "../components/tabs/TabEkipPTTT";
import TabThongTinPTTT from "../components/tabs/TabThongTinPTTT";

export const formatTrangThaiBenhNhanCDHA = (trangThai: string | undefined) => {
    <div className="text-center">
      <i
        style={{ color: trangThai }}
        className={`bi bi-circle-fill status-${trangThai}-color`}></i>
    </div>
};

export const dsTabPTTT = [
    {
        eventKey: "0",
        title: "Thông tin PTTT",
        component: <TabThongTinPTTT />,
    },
    {
        eventKey: "1",
        title: "Ê-Kip Phẫu thuật thủ thuật",
        component: <TabEkipPTTT />,
    },
    {
        eventKey: "2",
        title: "Bệnh Án",
        component: <TabBenhAnPTTT />,
    },
];

export const STATUS_DA_THANH_TOAN = "Đã thanh toán";

export const LIST_CODE_DIALOG_BANG_CDHA = {
    SUA_SO_LUONG: "suaSoLuong",
    NHAP_THONG_TIN_PTTT: "nhapThongTinPTTT",
    QUAN_LY_THUOC_VAT_TU: "quanLyThuocVatTu",
    CHI_DINH_THUOC_VAT_TU: "chiDinhThuocVatTu",
    HOAN_TRA_VAT_TU: "hoanTraVatTu",
    CHI_DINH_THUOC: "chiDinhThuoc",
    HOAN_TRA_THUOC: "hoanTraThuoc",
    KHONG_THUC_HIEN: "khongThucHien",
    MAY_THUC_HIEN_CLS: "mayThucHienCLS"
};

export const trangThaiBenhNhanCDHA = {
    choDenLuot: { code: "1", name: "Chờ đến lượt" },
    dangThucHien: { code: "2", name: "Đang thực hiện" },
    daTraKetQua: { code: "3", name: "Đã trả kết quả" },
};

export const listItemContextMenuCDHA: IContextMenu[] = [
    {
        code: "khongThucHien",
        name: "Không thực hiện",
    },
    {
        code: "suaSoLuong",
        name: "Sửa số lượng",
    },
    {
        code: "mayThucHienCLS",
        name: "Máy thực hiện CLS",
    },
    {
        code: "nhapThongTinPTTT",
        name: "Nhập thông tin PTTT",
    },
    {
        code: "thuocVatTuDiKem",
        name: "Thuốc, vật tư đi kèm",
        children: [
            { code: "quanLyThuocVatTu", name: "Quản lý thuốc, vật tư" },
            { code: "chiDinhThuocVatTu", name: "Chỉ định thuốc, vật tư" },
            { code: "hoanTraVatTu", name: "Hoàn trả vật tư" },
            { code: "chiDinhThuoc", name: "Chỉ định thuốc" },
            { code: "hoanTraThuoc", name: "Hoàn trả thuốc" },
        ],
    },
    {
        code: "thuocVatTuHaoPhi",
        name: "Thuốc, vật tư hao phí",
        children: [
            { code: "quanLyThuocVatTu", name: "Quản lý thuốc, vật tư" },
            { code: "chiDinhThuocVatTu", name: "Chỉ định thuốc, vật tư" },
            { code: "hoanTraVatTu", name: "Hoàn trả vật tư" },
            { code: "chiDinhThuoc", name: "Chỉ định thuốc" },
            { code: "hoanTraThuoc", name: "Hoàn trả thuốc" },
        ],
    },
    {
        code: "capNhatEkip",
        name: "Cập nhật Ê-kíp",
    },
]
export const LIST_PHIEU_IN = {
    PHIEU_CHI_DINH: {
        key: "phieuchidinh",
        title: "Phiếu chỉ định",
        template: templatePhieuChiDinh,
    },
}

export const benhNhanCDHAStatus = {
    choKham: { code: 1, name: "Chờ khám", className: 'bi bi-circle-fill pe-2 text-status-blue' },
    khamKetHop: { code: 2, name: "Khám kết hợp", className: 'bi bi-circle-fill pe-2 text-status-blue' },
    ketThucKham: { code: 3, name: "Kết thúc khám", className: 'bi bi-circle-fill pe-2 text-status-green' },
    daCoKQCLS: { code: 4, name: "Đã có KQCLS", className: 'bi bi-circle-fill pe-2 text-status-orange' },
    dangKham: { code: 5, name: "Đang khám", className: 'bi bi-circle-fill pe-2 text-status-orange' },
    choKQCLS: { code: 6, name: "Chờ KQCLS", className: 'bi bi-circle-fill pe-2 text-status-purple' },
  };
  
export const LIST_ORDER = {
  ASCENDING: {
    id: 1,
    value: "ASCENDING",
    name: "Tăng dần",
  },
  DERCEASE: {
    id: 2,
    value: "DERCEASE",
    name: "Giảm dần",
  },
};

export const WORK_FLOW_STATUS_CDHA = {
    DANG_THUC_HIEN: {
        name: "Đang thực hiện",
        code: "rispacs_executing"
    },
    CHUA_THUC_HIEN: {
        name: "Chưa thực hiện",
        code: "rispacs_new"
    },
    DA_TRA_KQ: {
        name: "Đã trả kết quả",
        code: "rispacs_result"
    }
}

export const TYPE_SAVE_CDHA = {
    SAVE_PRINT: {
        name: "Lưu/ In",
        code: "SAVE_PRINT"
    },
    SAVE_PRINT_RETURN: {
        name: "Lưu/ In/ Trả kết quả",
        code: "SAVE_PRINT_RETURN"
    },
}