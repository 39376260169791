import { Form, Formik, FormikProps } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AppContext } from '../../../appContext/AppContext';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../utils/Constant';
import { localStorageItem } from '../../../utils/LocalStorage';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { IKeVatTuRequest, IPhieuChiDinhVatTuItem } from '../../models/VatTuModels';
import { getDetailDonThuoc, saveDonThuoc } from '../../services/ThuocService';
import VatTuHeader from './components/header-vat-tu';
import ThongTinKeVatTu from './components/thong-tin-ke-vat-tu';
import TreeVatTu from './components/tree-vat-tu';
import { useKeVatTuContext } from './context';
import './style.scss';
import { getThongTinChanDoan } from '../../services/KhamBenhService';

type Props = {
	onClose: () => void;
	itemEdit?: any;
	onSave?: () => void;
};

const FormKeVatTu = ({ onClose, itemEdit, onSave }: Props) => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { vatTuChecked, treeValue, setVatTuChecked, setTreeValue } = useKeVatTuContext();
	const { setIsLoading } = useContext(AppContext);

	const formikRef = useRef<FormikProps<any>>(null);

	const [donVatTuDetail, setDonVatTuDetail] = useState<any>();

	const { encounterDiagnosis, visit, encounter, patient } = benhNhanInfo?.thongTinKhamBenh;
	const userInfo = localStorageItem.get('current-user');
	const validationSchema = Yup.object().shape({});
	const initialValues = {
		ngayYLenh: new Date(),
		thuTien: true,
		provisionalDiagnosis: encounterDiagnosis?.provisionalDiagnosis,
		provisionalDiagnosisName: encounterDiagnosis?.provisionalDiagnosisName,
		comorbidity: encounterDiagnosis?.comorbidity,
	};

	const handleGetThongTinChanDoan = async () => {
		try {
			const { data } = await getThongTinChanDoan(patient?.id);
			if (data?.code === CODE.SUCCESS) {
				formikRef.current &&
					formikRef.current.setValues({
						...initialValues,
						provisionalDiagnosis: data?.data?.provisionalDiagnosis || "",
						provisionalDiagnosisName: data?.data?.provisionalDiagnosisName || "",
						comorbidity: data?.data?.comorbidity || "",
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (itemEdit?.id) {
			getDetail(itemEdit.id);
		} else {
			handleGetThongTinChanDoan();
		}
	}, [itemEdit?.id]);

	const handleSubmit = async (values: any) => {
		try {
			if (!vatTuChecked.length) {
				toast.warn('Chưa có vật tư được kê cho đơn này');
				return;
			}

			if (!values?.provisionalDiagnosis) {
				toast.warn('Chưa nhập thông tin chẩn đoán.');
				return;
			}

			const emptyQuantityRow = vatTuChecked.find(row => !row.soLuong);
			if (emptyQuantityRow) {
				toast.warn(
					`Vật tư với mã vật tư ${emptyQuantityRow.maVatTu} chưa nhập số lượng kê`
				);
				return;
			}

			setIsLoading(true);

			const phieuChiDinhList: IPhieuChiDinhVatTuItem[] = vatTuChecked.map(row => {
				return {
					id: row.idBackend ?? null,
					termId: row.termId,
					visitId: visit.id,
					patientId: patient.id,
					encounterId: encounter.id,
					orderId: benhNhanInfo.thongTinKhamBenh.orderId,
					orderTypeId: parseInt(ORDER_TYPE_ID.VAT_TU),
					medicalItemId: row.idVatTu,
					medicalItemCode: row.maVatTu,
					medicalItemName: row.tenVatTu,
					name: row.tenVatTu,
					unit: row.donVi,
					totalQuantity: row.soLuong,
					deleted: row.deleted,
					visitObjectTypeId: visit.visitObjectTypeId,
					visitObjectTypeName: visit.visitObjectTypeName,
					unitPrice: row.donGia,
					intoMoney: row.donGia * row.soLuong,
					bidId: row.bidId,
				};
			});

			const requestData: IKeVatTuRequest = {
				id: donVatTuDetail?.id ?? null,
				visitId: visit.id,
				patientId: patient.id,
				encounterId: encounter.id,
				orderId: benhNhanInfo.thongTinKhamBenh.orderId,
				orderTypeId: parseInt(ORDER_TYPE_ID.VAT_TU),
				doctorOrderDay: values.ngayYLenh,
				treatmentDoctorUser: userInfo?.sub,
				treatmentDoctorName: userInfo.name,
				designatedPerson: userInfo.name,
				designatedPlace: userInfo.departments?.[0]?.name,
				designatedDate: new Date().toISOString(),
				lstMedicalItemOrder: phieuChiDinhList,
				comorbidity: values.comorbidity || encounterDiagnosis.comorbidity,
				provisionalDiagnosis:
					values.provisionalDiagnosis || encounterDiagnosis.provisionalDiagnosis,
				warehouseId: treeValue.id,
				warehouseName: treeValue.name,
			};

			const response = await saveDonThuoc(requestData);
			if (response.code === CODE.SUCCESS) {
				toast.success(
					donVatTuDetail?.id
						? RESPONSE_MESSAGE.UPDATE.SUCCESS
						: RESPONSE_MESSAGE.ADD.SUCCESS
				);
				onSave && onSave();
				onClose();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const getDetail = async (donVatTuId: string) => {
		try {
			setIsLoading(true);
			const { code, data } = await getDetailDonThuoc(donVatTuId);

			if (CODE.SUCCESS === code) {
				setDonVatTuDetail(data);
				setTreeValue({
					id: data.warehouseId,
					name: data.warehouseName,
				});
				formikRef.current?.setValues({
					...formikRef.current.initialValues,
					ngayYLenh: data.doctorOrderDay
						? new Date(`${data.doctorOrderDay}Z`)
						: new Date(),
					provisionalDiagnosis: data.provisionalDiagnosis,
					provisionalDiagnosisName: data.provisionalDiagnosisName,
					comorbidity: data.comorbidity,
				});

				if (data.lstMedicalItemOrderRes?.length) {
					const listVatTuSelected = data.lstMedicalItemOrderRes.map((row: any) => ({
						...row,
						idBackend: row.id,
						maVatTu: row.medicalItemCode,
						tenVatTu: row.name,
						idVatTu: row.medicalItemId,
						donVi: row.unit,
						soLuong: row.totalQuantity,
						donGia: row.unitPrice,
						thanhTien: row.intoMoney,
						doiTuong: row.visitObjectTypeName || visit.visitObjectTypeName
					}));
					setVatTuChecked(listVatTuSelected);
				}
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Formik<any>
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			innerRef={formikRef}
		>
			<Form className="spaces h-calc-vh-50">
				<div className="h-100">
					<Modal.Header className="py-3 header-modal">
						<VatTuHeader onClose={onClose} benhNhanInfo={benhNhanInfo} />
					</Modal.Header>

					<Modal.Body className="px-0 py-6 spaces overflow-hidden h-calc-vh-90">
						<div className="d-flex h-100">
							<div className="spaces width-20 bg-white radius-2 d-flex flex-column min-w-200">
								<TreeVatTu donVatTuDetail={donVatTuDetail} 
									disableSelectKhoVatTu={donVatTuDetail?.id || vatTuChecked.length}
								/>
							</div>
							<div className="d-flex flex-column spaces width-80 bg-light radius-2">
								<ThongTinKeVatTu />
							</div>
						</div>
					</Modal.Body>
				</div>
			</Form>
		</Formik>
	);
};

export default FormKeVatTu;
