import { FC, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { AppContext } from "../../../../../appContext/AppContext";
import { IconBack, IconMenu } from "../../../../../component/IconSvg";
import InputSearch from "../../../../../component/InputSearch";
import SelectTree from "../../../../../component/SelectTree";
import { TableCustom } from "../../../../../component/table/table-custom/TableCustom";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../../tab-quan-ly-hop-dong/QuanLyHopDong";
import { CODE, RESPONSE_MESSAGE } from "../../../../../utils/Constant";
import { DanhSachBenhNhanColumns, TreeChiDinhDichVu } from "./constants/DanhSachNhanVienColumn";
import "../QuanLyHopDong.scss";
import ModelThemMoiNhanVien from "./ModelThemMoiNhanVien";
import { getNVClasses, getListDsNhanVien, exportDSNhanVien, importDSNhanVien } from "../../../../services/KhamDoanHopDongServices"
import TextField from "../../../../../component/TextField";
import ShowErrorPopup from "./ShowErrorPopup";

interface Props {
    onHide?: any;
    show?: any;
    onClickBack: () => void;
}

const ModelDanhSachNhanVien: FC<Props> = (props) => {
    let { onHide, show, onClickBack } = props;
    const { setIsLoading } = useContext(AppContext);
    const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
    const [idSelected, setIdSelected] = useState<string>("");
    const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
    const [listNhanVien, setListNhanVien] = useState<any[]>([]);
    const { setIsClickOpenEditNV, isSearchNV, setIsSearchNV, setDataEditNV, TTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext);
    const [openThemMoiNhanVien, setOpenThemMoiNhanVien] = useState<boolean>(false);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [dataError, setDataError] = useState<any>({});

    useEffect(() => {
        !openThemMoiNhanVien && getDanhSachBenhNhanh();
    }, [TTHopDong?.id, openThemMoiNhanVien]);

    const handleConvertTreeData = (data: any[]) => {
        let convertedData: any[] = data.map((item, index) => {
            return {
                ...item,
                code: item?.code || item?.name,
                name: item?.name,
                ...(item?.subs &&
                    item?.subs?.length && {
                    filter: handleConvertTreeData(item?.subs || []),
                }),
            };
        });
        return convertedData;
    };

    const handleOpenThemMoiNhanVien = () => {
        setDataEditNV({});
        setIsClickOpenEditNV(false);
        setOpenThemMoiNhanVien(true);
        setIsSearchNV(!isSearchNV);
    };
    
    const handleCloseModalNhanVien = () => {
        setDataEditNV({});
        setIsClickOpenEditNV(false);
        setOpenThemMoiNhanVien(false);
        setIsSearchNV(!isSearchNV);
    };

    const handleGetSelectedItem = (data: any) => {
        getDanhSachBenhNhanh(data)
    };

    const getDanhSachBenhNhanh = async (filter?: any) => {
        if (TTHopDong?.id) {
            setIsLoading(true);
            let searchObject = {
                pageIndex: 1,
                pageSize: 9999999,
                [filter?.fieldName]: [filter?.[filter?.filterBy]],
                contractId: TTHopDong?.id
            };
            try {
                const { data } = await getListDsNhanVien(searchObject);
                if (CODE.SUCCESS === data?.code) {
                    setListNhanVien(data?.data?.content || []);
                } else {
                    toast.warning(RESPONSE_MESSAGE.ERROR);
                }
            } catch {
                toast.warning(RESPONSE_MESSAGE.ERROR);
            } finally {
                setIsLoading(false);
            }
        } else {
            return setListNhanVien([])
        }
    };


    const updatePageData = async () => {
        try {
            setIsLoading(true);
            let { data } = await getNVClasses(TTHopDong?.id);
            let treeDataConverted = {
                code: "all",
                name: "Tất cả dịch vụ",
                icon: <IconMenu />,
                filter: handleConvertTreeData(data?.data || []),
            };
            setTreeData(treeDataConverted);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        updatePageData();
    }, []);

    const handlerEditNhanVien = (row: any) => {
        setDataEditNV(row?.original);
        setIsClickOpenEditNV(true)
        setOpenThemMoiNhanVien(true);
        setIsSearchNV(!isSearchNV);
    };

    const handleImportDSNhanVien = async (e: any) => {
        if (!e.target.files[0]) return;
        try {
            setIsLoading(true);
            const file = e.target.files[0];
            const { data } = await importDSNhanVien(TTHopDong?.id, file);
            if (CODE.SUCCESS === data.code) {
                toast.success('Thêm danh sách nhân viên thành công');
                getDanhSachBenhNhanh();
                setIsSearchNV(!isSearchNV);
            } else {
                // toast.error(data?.message);
				setDataError(data);
				setShowErrorPopup(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        resetInput(e.target);
    };

    const resetInput = (input: HTMLInputElement) => {
        // Reset giá trị của input để có thể tải lại cùng một file
        input.value = '';
    }

    const handleExportDSNhanVien = async () => {
        try {
            setIsLoading(true);
            const res = await exportDSNhanVien(TTHopDong?.id);
            if (CODE.SUCCESS === res.status) {
                saveAs(res.data, `Danh sách nhân viên hợp đồng ${TTHopDong?.contractName}.xlsx`)
                toast.success('Lưu danh sách nhân viên thành công')
            };
        } catch (error) {
            console.error(error);
            toast.error('Có lỗi xảy ra, vui lòng thử lại !')
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="">
            <Modal
                centered
                show={show}
                onHide={onHide}
                size="sm"
                fullscreen
                className="page-full spaces h-calc-vh-50"
            >
                <Modal.Header className="py-3 header-modal">
                    <div className="d-flex gap-6">
                        <div
                            className="d-flex align-items-center gap-3 cursor-pointer "
                            onClick={onClickBack}
                        >
                            <IconBack />
                            <span className="fw-500 pr-5">Quay lại</span>
                        </div>
                        <span className="border-right"></span>
                        <h4 className="text-title fw-bold fs-4 spaces my-5">
                            {`${TTHopDong?.companyName} | Danh sách nhân viên`}
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="py-6 px-0  h-calc-vh-130 spaces scroll-hidden">
                    <div className="d-flex ">
                        <div className="spaces width-20 bg-white radius-2">
                            <div className="spaces px-10 pt-6 h-42">
                                <InputSearch
                                    placeholder="Tìm kiếm"
                                    handleChange={() => { }}
                                    className="spaces h-25 pr-4 radius-3"
                                />
                            </div>
                            <SelectTree
                                className="w-100 h-198 border py-2 ps-4 border-top-0  border-bottom-0 overflow-auto"
                                codeCollapses={codeCollapses}
                                handleChangeCollapsesCode={setCodeCollapses}
                                idSelected={idSelected}
                                handleChangeSelectId={setIdSelected}
                                getSelectedItem={handleGetSelectedItem}
                                selectTree={treeData}
                            />
                        </div>
                        <div className="d-flex spaces width-80 bg-white radius-2">
                            <div className="d-flex flex-column spaces width-100  ">
                                <div className="bg-white">
                                    <div className="spaces px-10 mt-6 h-35 ">
                                        <InputSearch
                                            placeholder="Tìm kiếm"
                                            handleChange={() => { }}
                                            className="spaces h-25 pr-4 radius-3"
                                        />
                                    </div>
                                    <div className="min-h-200 spaces bg-white xs">
                                        <TableCustom<any>
                                            className={"h-bang-ds-bn"}
                                            data={listNhanVien}
                                            columns={DanhSachBenhNhanColumns}
                                            verticalScroll={true}
                                            handleDoubleClick={handlerEditNhanVien}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="height-footer d-flex justify-content-between">
                    <div className="d-flex gap-4">
                        <Button className="btn-outline position-relative">
                            Nhập danh sách
                            <TextField
                                className="btn-file"
                                type="file"
                                name="fileDSNhanVien"
                                onChange={(e: any) => handleImportDSNhanVien(e)}
                            />
                        </Button>
                        <Button className="btn-outline" onClick={handleExportDSNhanVien}>
                            Xuất danh sách
                        </Button>
                    </div>
                    <Button className="btn-fill min-w-100px mb-2" onClick={handleOpenThemMoiNhanVien}>
                        Thêm mới
                    </Button>
                </Modal.Footer>
            </Modal>
            {openThemMoiNhanVien &&
                <ModelThemMoiNhanVien show={openThemMoiNhanVien} onHide={handleCloseModalNhanVien} />
            }

            {showErrorPopup && (
                <ShowErrorPopup show={showErrorPopup} data={dataError} onHide={() => setShowErrorPopup(false)} />
            )}
        </div>
    );
};

export default ModelDanhSachNhanVien;
