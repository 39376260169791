import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { PhanHeTiepDonContext } from "../contexts/PhanHeTiepDonContext";
import PageKeThuoc from "../modals/modal-ke-thuoc/PageKeThuoc";
import PageChiDinhDichVu from "../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu";
import CapToaChoVeModal from "../modals/modal-tab-xu-tri/ModalCapToaChoVe";
import ModalChuyenTuyenVien from "../modals/modal-tab-xu-tri/ModalChuyenTuyenVien";
import { Formik } from "formik";
import PageKeVatTu from "../modals/modal-ke-vat-tu/ModalKeVatTu";
import PageThanhToan from "../modals/modal-thanh-toan/ModalThanhToan";
import moment from "moment";
import { startMedicalExam } from "../services/PhanHeTiepDonServer";
import { KEY_DS_DANH_MUC_TIEP_DON } from "../../utils/Constant";
import { calculateAge } from "../../utils/FormatUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { handleConvertAddress } from "../../utils/AppFunction";
import ThongTinChiPhi from "./ThongTinChiPhi";

interface ThongTinBenhNhanProps {
  statusChangedEventFunc: () => void;
}

const initialValues = {};

const ThongTinBenhNhan: FunctionComponent<ThongTinBenhNhanProps> = (props) => {
  const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);
  const [openCapNhatMauChiDinhDialog, setOpenCapNhatMauChiDinhDialog] =
    useState<boolean>(false);
  const [shouldOpenChiDinhDichVuModal, setShouldOpenChiDinhDichVuModal] =
    useState<boolean>(false);
  const [shouldOpenCapToaModal, setShouldOpenCapToaModal] =
    useState<boolean>(false);
  const [shouldOpenChuyenVienModal, setShouldOpenChuyenVienModal] =
    useState<boolean>(false);
  const [shouldOpenModalKeVatTu, setShouldOpenModalKeVatTu] =
    useState<boolean>(false);
  const [shouldOpenModalThanhToan, setShouldOpenModalThanhToan] =
    useState<boolean>(false);

  const [listICD10, setListICD10] = useState<any[]>([]);

  let patient = benhNhanInfo?.thongTinKhamBenh?.patient;
  let visit = benhNhanInfo?.thongTinKhamBenh?.visit;
  let encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
  let orderId = benhNhanInfo?.thongTinKhamBenh?.orderId;
  const ICDXPrimaryValue =
    benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.icdXPrimary;

  useEffect(() => {
    handleGetListICD10();
  }, []);

  const startStartClinical = async () => {
    let { data } = await startMedicalExam({
      patientId: patient.id,
      visitId: visit.id,
      orderId: orderId,
    });
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        isExamined: data.data?.isExamined,
        encounter: data.data?.encounter,
        encounterDiagnosis: data.data?.encounterDiagnosis,
      },
    });
    props.statusChangedEventFunc();
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePatientInfo = () => {
    setBenhNhanInfo({
      ...benhNhanInfo,
      updateTab: !benhNhanInfo.updateTab,
    });
  };

  return (
    <Formik<any> initialValues={initialValues} onSubmit={() => {}}>
      <div className="bg-white mb-2">
        <div className="d-flex justify-content-start align-items-center spaces h-38 py-4">
          <div className=" justify-content-start pl-4 spaces d-flex gap-4">
            {benhNhanInfo?.thongTinKhamBenh?.isExamined &&
            !benhNhanInfo?.thongTinKhamBenh?.isConclusion ? (
              <>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenChiDinhDichVuModal(true)}
                >
                  Chỉ định DV
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setOpenCapNhatMauChiDinhDialog(true)}
                >
                  <span>Kê thuốc</span>
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenModalKeVatTu(true)}
                >
                  <span>Kê vật tư</span>
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenModalThanhToan(true)}
                  disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
                >
                  <span>Thanh toán</span>
                </Button>
              </>
            ) : (
              <>
                {/* <WfButton
                  tracker={WfConstTracker.CLINICAL}
                  objectId={orderId}
                  additionalFunc={startStartClinical}
                ></WfButton> */}
              </>
            )}
          </div>
        </div>
        <Row className="spaces h-38 px-9 pt-4">
          <Col className="border spaces py-5" xs={4}>
            <h4 className="text-pri fs-4 fw-bold mb-0">Thông tin chi phí</h4>
          </Col>
          <Col className="border spaces py-5" xs={8}>
            <h4 className="text-pri fs-4 fw-bold mb-0">Thông tin bệnh nhân</h4>
          </Col>
        </Row>
        <Row className="spaces px-9">
          <Col className="border spaces py-5" xs={4}>
            <ThongTinChiPhi />
          </Col>
          <Col className="border spaces py-5" xs={8}>
            <Row>
              <Col xs="12">
                <div className="spaces fw-5 mb-8 d-flex">
                  <span className="d-inline-block spaces min-w-100">
                    Bệnh nhân
                  </span>
                  <span className="spaces fw-4 text-truncate">
                    {patient?.personName ? (
                      <>
                        <span>
                          {patient?.code || "..."} -{" "}
                          {patient?.personName || "..."} -{" "}
                          {patient?.gender || "..."} -{" "}
                          {patient?.dobYear
                            ? calculateAge(
                                patient?.dobDay,
                                patient?.dobMonth,
                                patient?.dobYear
                              )
                            : "..."}{" "}
                          {visit?.visitObjectTypeName || "..."}{" "}
                          - {visit?.code || "..."}
                        </span>
                      </>
                    ) : (
                      "..."
                    )}
                  </span>
                </div>
              </Col>
              <Col xs="12">
                <div className="spaces fw-5 mb-8 d-flex">
                  <span className="d-inline-block spaces min-w-100">
                    Thời điểm tới
                  </span>
                  <span className="spaces fw-4 text-truncate">
                    {visit?.dateStarted
                      ? moment(visit?.dateStarted).format("DD/MM/YYYY - HH:mm")
                      : "..."}
                  </span>
                </div>
              </Col>
              <Col xs="12">
                <div className="spaces fw-5 mb-8 d-flex">
                  <span className="d-inline-block spaces min-w-100">
                    Địa chỉ
                  </span>
                  <span className="spaces fw-4 text-truncate">
                    {handleConvertAddress(patient) || "..."}
                  </span>
                </div>
              </Col>

              <Col xs="7">
                <div className="spaces fw-5 mb-8 d-flex">
                  <span className="d-inline-block spaces min-w-100">
                    Chẩn đoán
                  </span>
                  <span className="spaces fw-4 text-truncate">
                    {benhNhanInfo?.thongTinKhamBenh?.isConclusion &&
                    encounterDiagnosis?.icdXPrimary
                      ? listICD10?.find(
                          (item: any) =>
                            item?.code === encounterDiagnosis?.icdXPrimary
                        )?.name
                      : "..."}
                  </span>
                </div>
              </Col>
              <Col xs="5">
                <div className="spaces fw-5 mb-8 d-flex">
                  <span className="d-inline-block spaces min-w-50">
                    Xử trí
                  </span>
                  <span className="spaces fw-4 text-truncate">
                    {benhNhanInfo?.thongTinKhamBenh?.isConclusion
                      ? `${
                          encounterDiagnosis?.medicalConclusionModalityName
                            ? encounterDiagnosis?.medicalConclusionModalityName +
                              " - "
                            : ""
                        } 
                      ${encounterDiagnosis?.medicalConclusionName || ""}`
                      : "..."}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {openCapNhatMauChiDinhDialog && (
          <PageKeThuoc
            open={openCapNhatMauChiDinhDialog}
            handleClose={() => {
              setOpenCapNhatMauChiDinhDialog(false);
            }}
            onSave={() => {
              updatePatientInfo();
              setOpenCapNhatMauChiDinhDialog(false);
            }}
          />
        )}

        {shouldOpenModalKeVatTu && (
          <PageKeVatTu
            open={shouldOpenModalKeVatTu}
            handleClose={() => {
              setShouldOpenModalKeVatTu(false);
            }}
            onSave={() => {
              updatePatientInfo();
            }}
          />
        )}

        {shouldOpenChiDinhDichVuModal && (
          <PageChiDinhDichVu
            show={shouldOpenChiDinhDichVuModal}
            handleClose={() => {
              setShouldOpenChiDinhDichVuModal(false);
            }}
          />
        )}

        {shouldOpenCapToaModal && (
          <CapToaChoVeModal
            open={shouldOpenCapToaModal}
            handleCloseModal={() => setShouldOpenCapToaModal(false)}
            handleSubmit={() => {}}
          />
        )}

        {shouldOpenChuyenVienModal && (
          <ModalChuyenTuyenVien
            open={shouldOpenChuyenVienModal}
            handleCloseModal={() => setShouldOpenChuyenVienModal(false)}
            handleSubmit={() => {}}
          />
        )}

        {shouldOpenModalThanhToan && (
          <PageThanhToan
            open={shouldOpenModalThanhToan}
            handleClose={() => {
              setShouldOpenModalThanhToan(false);
            }}
          />
        )}
      </div>
    </Formik>
  );
};

export default ThongTinBenhNhan;
