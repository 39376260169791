import clsx from 'clsx';
import { useField } from 'formik';
import React, {
	ChangeEvent,
	FocusEvent,
	forwardRef,
	HTMLProps,
	KeyboardEvent,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { Form, FormControlProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatMoney } from '../../utils/FormatUtils';

type FormControlPropsWithoutFieldProps = Omit<
	FormControlProps,
	'onChange' | 'value' | 'name' | 'onBlur'
>;

type AdditionalInputProps = Pick<HTMLProps<HTMLInputElement>, 'autoFocus'>;

type Props = FormControlPropsWithoutFieldProps &
	AdditionalInputProps & {
		label?: string | ReactNode;
		name: string;
		className?: string;
		labelClassName?: string;
		inputClassName?: string;
		required?: boolean;
		value?: number;
		onChange?: (value: number) => void;
		onBlur?: (e: FocusEvent<HTMLInputElement>, value: number) => void;
	};

const NumberField = forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			name,
			className,
			labelClassName,
			inputClassName,
			required,
			value = 0,
			onChange,
			onBlur,
			...props
		},
		ref
	) => {
		const [valueFormat, setValueFormat] = useState<string>('');
		const [field, { error, touched }, { setValue, setTouched }] = useField(name);

		const { value: formikFieldValue } = field;

		useEffect(() => {
			return () => {
				setValue('');
			};
		}, []);

		useEffect(() => {
			setValueFormat(formatMoney(Number(value || formikFieldValue)) || '');
		}, [value, formikFieldValue]);

		const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
			const inputValue = e.target.value;
			formatValue(inputValue);
		};

		const formatValue = (inputValue: string) => {
			const rawValue = removeSeparator(inputValue);

			if (isNaN(Number(rawValue))) return;

			const valueFormated = formatMoney(Number(rawValue)) || '';
			const numberValue = Number(rawValue);

			setValueFormat(valueFormated.toString());
			setValue(numberValue || undefined);
			onChange && onChange(numberValue);
		};

		const removeSeparator = (value: string) => {
			return value.trim().replaceAll('.', '');
		};

		return (
			<Form.Group className={clsx('d-flex align-items-center spaces w-100', className)}>
				{label && (
					<Form.Label
						className={clsx(
							'spaces text-lable-input max-content-width mb-0',
							labelClassName
						)}
					>
						{required ? (
							<span>
								{label}
								<span className="text-danger"> *</span>
							</span>
						) : (
							label
						)}
					</Form.Label>
				)}
				<input type="hidden" {...field} />
				<div className="w-100 position-relative">
					<Form.Control
						ref={ref}
						className={clsx(
							'form-control customs-input w-100 text-align-right spaces px-4 py-1',
							{
								'is-invalid': !!error && touched,
								inputClassName,
							}
						)}
						name={`${name}-field`}
						value={valueFormat}
						onChange={onChangeInputValue}
						onBlur={(e: FocusEvent<HTMLInputElement>) => {
							onBlur && onBlur(e, formikFieldValue);
							setTouched(true);
						}}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
							const value = e.currentTarget.value;

							if (e.key === 'ArrowUp') {
								formatValue(Number(Number(removeSeparator(value)) + 1).toString());
							}

							if (e.key === 'ArrowDown') {
								formatValue(Number(Number(removeSeparator(value)) - 1).toString());
							}
						}}
						{...props}
					/>
					<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
						{error}
					</Form.Control.Feedback>
				</div>
			</Form.Group>
		);
	}
);

export default NumberField;
