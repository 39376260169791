import React, { useEffect } from 'react'
import ModalICD10 from './modal-icd10/ModalICD10'
import ModalChonBenhICD10 from './tab-kham-benh/ModalChonBenhICD10'

type Props = {
  shouldOpenModalICD10: boolean
  setShouldOpenModalICD10: React.Dispatch<React.SetStateAction<boolean>>
  shouldOpenModalSelectICD10: boolean
  setShouldOpenModalSelectICD10: React.Dispatch<React.SetStateAction<boolean>>
  listICD10: any[]
  listComorbidity: any[]
  setListComorbidity: React.Dispatch<React.SetStateAction<any>>
  icd10Field?: string
  comorbidityField?: string
  selectionModeICD10: "checkbox" | "radio"
  data: any
  onChange: (value: any) => void
}

const ICD10 = ({
  shouldOpenModalICD10,
  setShouldOpenModalICD10,
  shouldOpenModalSelectICD10,
  setShouldOpenModalSelectICD10,
  listICD10,
  listComorbidity = [],
  setListComorbidity,
  icd10Field = "provisionalDiagnosis",
  comorbidityField = "comorbidity",
  selectionModeICD10,
  data,
  onChange,
}: Props) => {

  useEffect(() => {
    handleConvertDiagnosis(data);
  }, [data, listICD10]);

  const handleConvertDiagnosis = (data: any) => {
    if ((data?.[icd10Field] && data?.[icd10Field + "Name"])) {
      const dataMain = {
        termCode: data?.[icd10Field],
        termName: data?.[icd10Field + "Name"],
        isDiseaseMain: true
      };

      setListComorbidity([dataMain]);
      if (data?.[comorbidityField]) {
        const newArr = data?.[comorbidityField]?.split(";");
        const arrAfter = newArr?.map((item: any) => {
          return {
            termName: item.trim(),
            termCode: listICD10.find((icd10: any) => icd10.name === item.trim())?.code,
            isDiseaseMain: false
          };
        });
        setListComorbidity([dataMain].concat(arrAfter));
      }
    } else {
      setListComorbidity([]);
    }
  };
  const handleChangeICD10 = (data: any) => {
    switch (icd10Field) {
      case icd10Field:
        const newValue = {
          termName: listICD10.find((item: any) => item.code === data?.code)
            ?.name,
          termCode: data?.code,
          isDiseaseMain: true
        };

        let newArr = [...listComorbidity].filter((item: any) => item?.termCode !== data?.code && !item?.isDiseaseMain);
        setListComorbidity([newValue, ...newArr]);

        onChange({
          [icd10Field]: data?.code,
          [icd10Field + "Name"]: listICD10.find((item: any) => item.code === data?.code)?.name,
          [comorbidityField]: newArr?.map((item: any) => item?.termName).join("; "),
        });
        break;
      default:
        break;
    }
  };

  const handleSelectICD10 = (dataDiagnosis: any, dataComorbidity: any) => {
    onChange({
      [icd10Field]: dataDiagnosis ? dataDiagnosis?.termCode : "",
      [icd10Field + "Name"]: dataDiagnosis ? dataDiagnosis?.termName : "",
      [comorbidityField]: dataComorbidity?.map((item: any) => item?.termName).join("; "),
    });

    let isDiagnosisMain = listComorbidity.some((item: any) => {
      return (
        item?.isDiseaseMain === dataDiagnosis?.isDiseaseMain
      );
    });
    if (isDiagnosisMain) {
      const newArr = listComorbidity.concat(dataComorbidity);
      setListComorbidity(newArr);
    } else {
      dataComorbidity.push(dataDiagnosis);
      setListComorbidity(dataComorbidity);
    }
  }

  return (
    <>
      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeICD10}
          selectionMode={selectionModeICD10}
          dataChecked={selectionModeICD10 === "radio" ? [
            {
              code: data?.[icd10Field],
              name: data?.[icd10Field + "Name"],
            }
          ] : listComorbidity}
        />
      )}
      {shouldOpenModalSelectICD10 && (
        <ModalChonBenhICD10
          handleClose={() => setShouldOpenModalSelectICD10(false)}
          handleSelectICD10={handleSelectICD10}
          listICD10={listComorbidity}
        />
      )}
    </>
  )
}

export default ICD10