import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import ContextMenu from '../../component/ContextMenu';
import SelectTree from '../../component/SelectTree';
import { CODE, SELECTION_MODE } from '../../utils/Constant';
import { ColumnsDsBenhNhanCDHA } from '../constants/Columns';
import { trangThaiBenhNhanCDHA } from '../constants/Constants';
import ModalLichSuKhamCDHA from './modals/ModalLichSuKhamCDHA';
import { IBenhNhanV3CDHA } from '../models/ModelsPhanHeCDHAVaTDCN';
import { TableCustom } from '../../component/table/table-custom/TableCustom';
import { formatSummaryStatus } from '../../utils/FormatUtils';

type Props = {
	setInfoPhieuCDHA: (benhNhan: IBenhNhanV3CDHA) => void;
	dataSearched: IBenhNhanV3CDHA[];
	statusSummary: any;
	doSearch?: any;
	filter?: any;
}

const BangDsPhieuCDHA = (props: Props) => {
	const { setInfoPhieuCDHA, dataSearched, statusSummary=[], doSearch = () => {}, filter } = props;
	const [contextMenu, setContextMenu] = useState<null | { x: number; y: number }>(null);
	const contextRef = useRef<HTMLDivElement | null>(null);
	const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
	const [idSelected, setIdSelected] = useState<string>("");
	const [openDialogLichSuKhamBenh, setOpenDialogLichSuKhamBenh] = useState<boolean>(false)

	useEffect(() => {
		setCodeCollapses([...MenuTree.filter.map((item) => item.code)]);
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (idSelected.includes("goiBenhNhan")) {
			toast.warning("Gọi bệnh nhân thành công!");
			handleSelected("goiBenhNhan");
		}
		if (idSelected.includes("lskb")) {
			setOpenDialogLichSuKhamBenh(true)
			handleSelected("thayTheDichVuKhac");
		}
	}, [idSelected]);

	const handleSelected = (code: string) => {
		if (idSelected === code) {
			setIdSelected("");
		} else {
			setIdSelected(code);
		}
	};
	const handleClickOutside = (e: any) => {
		if (contextRef.current && !contextRef.current.contains(e.target as Node)) {
			setContextMenu(null);
		}
	};

	const MenuTree = {
		filter: [
			{
				code: "benhNhan",
				name: "Bệnh nhân",
				filter: [{ code: "goiBenhNhan", name: "Gọi bệnh nhân" }],
			},
			{
				code: "benhAn",
				name: "Bệnh án",
				filter: [
					{ code: "lskb", name: "Lịch sử khám bệnh" },
				],
			},
		],
	};
	const handleSelectedBenhNhan = async (phieuCDHA: IBenhNhanV3CDHA[]) => {
		setInfoPhieuCDHA(phieuCDHA[0]);
	};

	const handleContextMenu = (e: any, row: any) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
	};

	const handleClickStatus = (item: any) => {
		doSearch({ ...filter, filterBy: 'currStatusCode', filterValue: item?.statusCode });
	}

	return (
		<div className="flex flex-column flex-1 pt-3 spaces h-calc-vh-210">
			<TableCustom<IBenhNhanV3CDHA>
				data={dataSearched}
				columns={ColumnsDsBenhNhanCDHA}
				selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
				getSelectedRowsData={handleSelectedBenhNhan}
				verticalScroll={true}
				handleContextMenu={handleContextMenu}
				className='flex-1 spaces h-calc-vh-245 pb-3'
			/>
			<div className="d-flex justify-content-center spaces h-30">
				<Row className="d-flex spaces w-100 mb-10">
					{
						statusSummary?.length > 0 && statusSummary.map((item: any, index: any) => {
							return <Col key={index} xs={6} className="spaces mb-3" onClick={() => handleClickStatus(item)}>
								{formatSummaryStatus(item)}
							</Col>
						})
					}
				</Row>
			</div>
		</div>
	);
};

export default BangDsPhieuCDHA;
