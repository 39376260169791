import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDebounce } from '../../../../../_metronic/helpers';
import { ConfirmDialog } from '../../../component/ConfirmDialog';
import CustomIconButton from '../../../component/custom-icon-button/CustomIconButton';
import { IconButtonSave } from '../../../component/IconSvg';
import InputSearch from '../../../component/InputSearch';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { TableCustom } from '../../../component/table/table-custom/TableCustom';
import { CODE, RESPONSE_MESSAGE, SELECTION_MODE } from '../../../utils/Constant';
import { localStorageItem } from '../../../utils/LocalStorage';
import { columnDanhSachMauChiDinhThuoc } from '../../columns/tab-thuoc/ColumnTabThuoc';
import { deleteDonThuocMau, getDanhSachDonThuocMau } from '../../services/ThuocService';

type Props = {
	open: boolean;
	handleClose: () => void;
	onSelect?: (selectedRow: any) => void;
	orderType?: number;
	tableTitle?: string;
	getRowData?: (row: any) => {};
	columns?: Readonly<any[]>;
};

const ModalMauChiDinhThuoc = ({
	open,
	handleClose,
	onSelect,
	orderType,
	tableTitle = 'Danh sách mẫu',
	getRowData,
	columns,
}: Props) => {
	const [tableData, setTableData] = useState<any[]>([]);
	const [selectedRow, setSelectedRow] = useState<any>();
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
	const [keySearch, setKeySearch] = useState('');

	const userInfo = localStorageItem.get('current-user');

	const debouncedKeySearch = useDebounce(keySearch, 500);

	useEffect(() => {
		getDanhSachDonMau(debouncedKeySearch);
	}, [debouncedKeySearch]);

	const getDanhSachDonMau = async (keySearch = '') => {
		try {
			const { code, data } = await getDanhSachDonThuocMau({
				createdBy: userInfo?.sub,
				name: keySearch,
				orderType: Number(orderType),
				pageIndex: 1,
				pageSize: 50,
			});
			if (CODE.SUCCESS === code && data) {
				const result = data.content.map((item: any) =>
					getRowData
						? getRowData(item)
						: {
								...item,
								tenNguoiTao: userInfo?.name,
						  }
				);
				setTableData(result);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onXoaDonMau = async () => {
		try {
			const rowIdDelete = selectedRow.id || selectedRow[0]?.id;
			const { code } = await deleteDonThuocMau(rowIdDelete);
			if (CODE.SUCCESS === code) {
				toast.success('Xóa đơn mẫu thành công');
				getDanhSachDonMau();
				setSelectedRow(null);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setOpenConfirmDialog(false);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
		setKeySearch(event.target.value.trim());
	};

	const columnsActions = {
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title={'Thao tác'}
				className="text-center text-light min-w-80px"
			/>
		),
		id: 'actions',
		Cell: ({ ...props }) => (
			<div className="d-flex justify-content-center">
				{/* <CustomIconButton variant="edit" title="Sửa mẫu thuốc" onClick={() => {}}>
					<i className="bi bi-pencil-square text-navy"></i>
				</CustomIconButton> */}
				<CustomIconButton
					variant="delete"
					onClick={() => {
						setSelectedRow(props.row.original);
						setOpenConfirmDialog(true);
					}}
					title="Xóa mẫu"
				>
					<i className="bi bi-trash3-fill text-danger"></i>
				</CustomIconButton>
				{/* <CustomIconButton variant="delete" onClick={() => {}} title="Sửa nhóm đơn mẫu">
					<i className="bi bi-arrows-move"></i>
				</CustomIconButton> */}
			</div>
		),
	};

	const onSave = () => {
		if (!selectedRow) {
			toast.warn('Chưa chọn đơn mẫu');
			return;
		}
		onSelect?.(selectedRow[0].id);
	};

	return (
		<Modal
			className="dialog-background"
			size="xl"
			show={open}
			animation
			centered
			backdropClassName="spaces top-50"
		>
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">{tableTitle}</Modal.Title>
				<button className="btn-close" onClick={handleClose}></button>
			</Modal.Header>

			<Modal.Body className="pt-16 pb-10 px-24 spaces">
				{/* <div className="d-flex spaces gap-10 align-items-center justify-content-between mb-16">
					<div className="d-flex gap-3">
						<Button className="btn-outline spaces h-29">
							<i className="bi bi-list m-0 p-0"></i>
						</Button>
					</div>
				</div> */}
				<Row>
					<Col xs={12}>
						<InputSearch
							handleChange={handleChange}
							placeholder="Tìm kiếm"
							type="text"
						/>
						<div className="mt-3 spaces">
							<TableCustom
								data={tableData}
								columns={columns ? columns : columnDanhSachMauChiDinhThuoc}
								columnsActions={columnsActions}
								getSelectedRowsData={setSelectedRow}
								selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
								minHeight={400}
								handleDoubleClick={row => {
									onSelect && onSelect(row.original.id);
								}}
							/>
						</div>
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end">
				<Button className="btn-outline" onClick={handleClose}>
					Đóng
				</Button>
				<Button className="btn-fill" onClick={onSave}>
					<IconButtonSave />
					Lưu
				</Button>
			</Modal.Footer>

			{openConfirmDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openConfirmDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa đơn mẫu này không ?"
					yes="Có"
					close="Không"
					onCloseClick={() => setOpenConfirmDialog(false)}
					onYesClick={() => {
						onXoaDonMau();
					}}
				/>
			)}
		</Modal>
	);
};

export default ModalMauChiDinhThuoc;
