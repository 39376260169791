import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import ModalDonThuocCu from '../../../../components/modal-ke-thuoc/ModalDonThuocCu';
import { getDrugGroupListByVisitId, getDrugListById } from '../../../../services/ThuocService';

type Props = {
	patientInfo: {
		visitId: string;
	};
	onSelect: (selectedData: any) => void;
	label?: string;
};

type DropdownItemHoverableProps = {
	medicalItem: any;
	onClick: (id: string) => void;
};

const DropdownItemHoverable = ({ onClick, medicalItem }: DropdownItemHoverableProps) => {
	const [danhSachThuocCu, setDanhSachThuocCu] = useState<any[]>([]);

	const cacheRef = useRef<Map<string, any[]>>(new Map());

	const getDanhSachThuoc = async (groupId: string) => {
		try {
			const { code, data } = await getDrugListById({
				medicalItemGroupId: groupId,
			});

			if (code === CODE.SUCCESS) {
				const result = data.content;
				setDanhSachThuocCu(result);
				cacheRef.current.set(groupId, result);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onOverlayToggle = (nextShow: boolean, medicalGroup: any) => {
		if (nextShow) {
			const groupId = medicalGroup.id;
			if (cacheRef.current.has(groupId)) {
				setDanhSachThuocCu(cacheRef.current.get(groupId) || []);
				return;
			}
			getDanhSachThuoc(groupId);
		}
	};

	return (
		<OverlayTrigger
			placement="right-start"
			rootClose
			onToggle={(nextShow: boolean) => onOverlayToggle(nextShow, medicalItem)}
			overlay={
				<Tooltip id="button-tooltip" className="dropdown-action-item-tooltip">
					<ListGroup variant="flush" className="bg-white text-start spaces">
						<ListGroup.Item className="bg-light">
							<div className="ps-2 spaces line-height-30 fw-bold">
								Chẩn đoán: {medicalItem.provisionalDiagnosis}
							</div>
						</ListGroup.Item>

						{Boolean(danhSachThuocCu.length) &&
							danhSachThuocCu.map((item: any, index: number) => (
								<ListGroup.Item key={index}>
									<div className="ps-2 spaces line-height-30">
										{`${item.medicalItemCode} - ${item.name}`}
									</div>
								</ListGroup.Item>
							))}
					</ListGroup>
				</Tooltip>
			}
		>
			<Dropdown.Item onClick={() => onClick(medicalItem.id)}>
				<div className="ps-2 spaces line-height-30">{medicalItem.date}</div>
			</Dropdown.Item>
		</OverlayTrigger>
	);
};

const ButtonDonThuocCu = ({ patientInfo, onSelect, label = 'Đơn thuốc cũ' }: Props) => {
	const [openDanhSachDonThuocCuDialog, setOpenDanhSachDonThuocCuDialog] = useState<{
		idSelected: string | undefined;
		open: boolean;
	}>({
		idSelected: '',
		open: false,
	});
	const [danhSachPhieuChiDinhCu, setDanhSachPhieuChiDinhCu] = useState<any[]>([]);
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		if (patientInfo?.visitId && visible) {
			getDanhSachPhieuChiDinhCu(patientInfo.visitId);
		}
	}, [patientInfo.visitId, visible]);

	const getDanhSachPhieuChiDinhCu = async (visitId: string) => {
		try {
			const { code, data } = await getDrugGroupListByVisitId({
				visitId,
				type: ORDER_TYPE_ID.THUOC,
			});

			if (CODE.SUCCESS === code) {
				const result = data
					.sort(
						(a: any, b: any) =>
							new Date(b.designatedDate).getTime() -
							new Date(a.designatedDate).getTime()
					)
					.map((row: any) => {
						return {
							...row,
							date: moment(new Date(`${row?.designatedDate}Z`)).format(
								'DD/MM/YYYY HH:mm'
							),
						};
					});
				setDanhSachPhieuChiDinhCu(result);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onDropdownItemClick = (groupId?: string) => {
		setOpenDanhSachDonThuocCuDialog({
			idSelected: groupId,
			open: true,
		});
	};

	return (
		<Dropdown
			className="dropdown-btn menu-icon"
			onToggle={nextShow => {
				setVisible(nextShow);
			}}
		>
			<Dropdown.Toggle className="btn-fill" id="dropdown-don-thuoc-cu">
				<i className="bi bi-caret-down-fill"></i>
				{label}
			</Dropdown.Toggle>

			<Dropdown.Menu className="dropdown-actions">
				<Dropdown.Item
					className="bg-light spaces line-height-30 text-dark fw-bold"
					onClick={() => onDropdownItemClick()}
				>
					Phiếu chỉ định cũ
				</Dropdown.Item>

				{danhSachPhieuChiDinhCu.slice(0, 3).map((item: any) => (
					<DropdownItemHoverable
						key={item.id}
						medicalItem={item}
						onClick={onDropdownItemClick}
					/>
				))}
			</Dropdown.Menu>

			{openDanhSachDonThuocCuDialog.open && (
				<ModalDonThuocCu
					open={openDanhSachDonThuocCuDialog.open}
					onClose={() => {
						setOpenDanhSachDonThuocCuDialog({
							idSelected: '',
							open: false,
						});
					}}
					onSave={(data: any) => {
						onSelect(data);
					}}
					data={danhSachPhieuChiDinhCu}
				/>
			)}
		</Dropdown>
	);
};

export default ButtonDonThuocCu;
