import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  FocusEvent,
} from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import DanhSachPhieu from "../../../component/DanhSachPhieu";
import LabelRequired from "../../../component/LabelRequired";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import {
  CODE,
  KEY,
  KEY_DS_DANH_MUC_TIEP_DON,
  LIST_COLOR_TRANG_THAI_PHIEU,
  ORDER_TYPE_ID,
  RESPONSE_MESSAGE,
} from "../../../utils/Constant";
import {
  formatDateAdvanceToString,
  formatDateDTO,
  formatDateToDDMMYYYY,
} from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import {
  WfConstTracker,
  WfConstTrackerClinical,
} from "../../../workflows/Constant";
import WfButton from "../../../workflows/components/WfButton";
import "../../PhanHeKhamBenh.scss";
import DynamicComponentKB from "../../components/DynamicComponentKB";
import { CODE_CONTEXT_TAB_XET_NGHIEM } from "../../constants/ContextConstants";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import {
  deleteDSChiDinhDV,
  getDSDVDaChiDinh,
  getDSNhomDVDaChiDinh,
} from "../../services/ChiDinhDVService";
import {
  fetchGuiPhieu,
  fetchHuyGuiPhieu,
  getStatusDesc,
} from "../../services/KhamBenhService";
import CheckIcon from "../../components/CheckIcon";
import ModalThucHienChuyenKhoa from "../../../phan-he-chuyen-khoa/components/modal-thuc-hien/ModalThucHienChuyenKhoa";
import ButtonInPhieu from "../../../component/button-in-phieu";
import { inPhieuChiDinhCLS } from "../../services/PhanHeTiepDonServer";
import ShowStatus from "../../../component/ShowStatus";
import {
  createObsValue,
  createSurgeryOrderParaclinical,
} from "../../../phan-he-chuyen-khoa/services/PhanHeChuyenKhoaService";
import { WORK_FLOW_STATUS_CHUYEN_KHOA } from "../../../phan-he-chuyen-khoa/constants/PhanHeChuyenKhoaConstants";
import { AppContext } from "../../../appContext/AppContext";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { Formik } from "formik";
import TextField from "../../../component/TextField";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

interface Iprops {
  danhSachPhieu?: boolean;
}

export const TabChuyenKhoa = ({ danhSachPhieu = true }: Iprops) => {
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } =
    useContext(PhanHeTiepDonContext);
  let { visit, patient, isConclusion, orderId } =
    benhNhanInfo?.thongTinKhamBenh || {};
  const [dsXetNghiemDetail, setDsXetNghiemDetail] = useState<any[]>([]);
  const [dsPhieu, setDsPhieu] = useState<any[]>([]);
  const [selectedPhieu, setSelectedPhieu] = useState<any>(null);
  const [openPhieuIn, setOpenPhieuIn] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>();
  const [idTable, setIdTable] = useState("");
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [contextClientX, setContextClientX] = useState<number>(0);
  const [listICD10, setListICD10] = useState<any>([]);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [shouldOpenResultModal, setShouldOpenResultModal] =
    useState<boolean>(false);
  const [ketQuaCLS, setKetQuaCLS] = useState<any>([]);
  const encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
  const [statusDesc, setStatusDesc] = useState<any>([]);
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");
  const { setIsLoading } = useContext(AppContext);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const [isHasData, setIsHasData] = useState<boolean>(false);

  const handleGetStatusDesc = async () => {
    try {
      let { data } = await getStatusDesc(WfConstTracker.SURGERY);
      if (data?.code === CODE.SUCCESS) {
        setStatusDesc(data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetStatusDesc();
  }, []);
  const actionChanged = useCallback(
    async (currStatusCode: string | undefined) => {
      try {
        let res;
        switch (currStatusCode) {
          case "order_group_cancel":
            res = await fetchHuyGuiPhieu(selectedPhieu?.id);
            break;
          case "order_group_submit":
            res = await fetchGuiPhieu(selectedPhieu?.id);
            break;
          default:
            res = null;
            break;
        }
        if (CODE.SUCCESS === res?.data?.code) {
          statusChangedEventFunc?.();
          handleSelectPhieu(selectedPhieu);
          setBenhNhanInfo({
            ...benhNhanInfo,
            shouldUpdate: !benhNhanInfo?.shouldUpdate,
          });
          toast.success("Thành công");
        } else {
          toast.warning(res?.data?.message || RESPONSE_MESSAGE.ERROR);
        }
      } catch (error) {
        toast.warning(RESPONSE_MESSAGE.ERROR);
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo]
  );

  const handleContextMenu = (e: any, row: any, code?: string) => {
    setRowSelected(row);
    setIdTable(code as string);
    e.preventDefault();
    setContextClientX(e.clientX);

    const heightDropList =
      document.getElementById("drop-list")?.clientHeight || 0;
    const isOnRight = window.innerWidth - e.clientX < 200;
    const newX = isOnRight ? e.clientX - 200 : e.clientX;
    const isBottom = window.innerHeight - e.clientY < 200;
    const newY = isBottom ? e.clientY - heightDropList : e.clientY;

    setContextMenu({ x: newX, y: newY });
  };

  useEffect(() => {
    !dsPhieu.length && setDsXetNghiemDetail([]);
  }, [benhNhanInfo, dsPhieu]);

  const handleSelectPhieu = async (dataPhieu: any) => {
    try {
      setSelectedPhieu(dataPhieu);
      let { data } = await getDSDVDaChiDinh({
        orderGroupId: dataPhieu?.id,
      });
      if (CODE.SUCCESS === data?.code) {
        const newData = data?.data?.map((item: any) => {
          return {
            ...item,
            details: `${item?.fulfillDept}, Ngày ${
              item?.indicationDate
                ? formatDateToDDMMYYYY(item?.indicationDate)
                : ""
            }`,
          };
        });
        setDsXetNghiemDetail(newData || []);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDSChiDinhDV(selectedPhieu?.id);
      setBenhNhanInfo({
        ...benhNhanInfo,
        shouldUpdate: !benhNhanInfo?.shouldUpdate,
      });
      statusChangedEventFunc?.();
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };

  const updataData = async () => {
    try {
      let { data } = await getDSNhomDVDaChiDinh({
        orderId,
        type: ORDER_TYPE_ID.CHUYEN_KHOA,
      });
      if (CODE.SUCCESS === data?.code) {
        let dsPhieuConverted = data?.data?.map((item: any) => {
          return {
            ...item,
            date: moment(item?.date).format("DD/MM/YYYY"),
          };
        });
        setDsPhieu(dsPhieuConverted || []);
        setSelectedPhieu(null);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickWfButton = (data: any) => {
    setDynamicData(data);
  };

  useEffect(() => {
    if (orderId) {
      updataData();
    }
  }, [benhNhanInfo?.shouldUpdate, orderId]);

  const handleViewResult = (data: any) => {
    if (data?.obs?.isAllowEdit) {
      setShouldOpenResultModal(true);
      setSelectedRow(data?.obs);
    } else {
      toast.warning("Dịch vụ chưa được thanh toán!");
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    let orderGroupId = selectedPhieu?.id;
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        orderGroupId,
        indicationTime: selectedPhieu?.indicationTime,
      },
    });
  }, [selectedPhieu]);

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F2:
        e.preventDefault();
        setKeyOpenPhieuIn("F2");
        break;
      case KEY.F3:
        e.preventDefault();
        //Hàm của nút In phiếu khám gọi ở đây
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  const handleSubmitForm = async (
    values: any,
    currentId?: any,
    currentTab?: any
  ) => {
    let valuesPTTT = {
      surgeryInformationEntryDate: formatDateDTO(
        values?.surgeryInformationEntryDate
      ),
      surgeryInformationEntryPerson:
        values?.surgeryInformationEntryPerson || null,
      surgeryScheduledTime: values?.surgeryScheduledTime
        ? formatDateDTO(values?.surgeryScheduledTime)
        : null,
      surgeryAnesthesiaInductionTime: values?.surgeryAnesthesiaInductionTime
        ? formatDateDTO(values?.surgeryAnesthesiaInductionTime)
        : null,
      surgeryStartTime: values?.surgeryStartTime
        ? formatDateDTO(values?.surgeryStartTime)
        : null,
      surgeryEndTime: values?.surgeryEndTime
        ? formatDateDTO(values?.surgeryEndTime)
        : null,
      surgeryInformationEntryDepartment:
        values?.surgeryInformationEntryDepartment?.id || null,
      surgeryInformationEntryRoom:
        values?.surgeryInformationEntryRoom?.code ||
        values?.surgeryInformationEntryRoom ||
        null,
      surgeryInitialDiagnosis: values?.surgeryInitialDiagnosis?.code || null,
      surgeryInitialDiagnosisText: values?.surgeryInitialDiagnosis?.name || null,
      surgeryComorbidity: values?.surgeryComorbidity?.code || null,
      surgeryComorbidityText: values?.surgeryComorbidity?.name || null,
      surgeryPreoperativeDiagnosis:
        values?.surgeryPreoperativeDiagnosis?.code || null,
      surgeryPreoperativeDiagnosisText:
        values?.surgeryPreoperativeDiagnosis?.name || null,
      surgeryPostoperativeDiagnosis:
        values?.surgeryPostoperativeDiagnosis?.code || null,
      surgeryPostoperativeDiagnosisText:
        values?.surgeryPostoperativeDiagnosis?.name || null,
      surgerySurgicalProcedure: values?.surgerySurgicalProcedure || null,
      surgerySurgicalTechnique: values?.surgerySurgicalTechnique || null,
      surgerySurgicalMethod: values?.surgerySurgicalMethod || null,
      surgerySurgicalType:
        values?.surgerySurgicalType?.id || values?.surgerySurgicalType || null,
      surgerySurgicalTypeText:
        values?.surgerySurgicalType?.name ||
        values?.surgerySurgicalTypeText ||
        null,
      surgerySurgicalStatus:
        values?.surgerySurgicalStatus?.id ||
        values?.surgerySurgicalStatus ||
        null,
      surgerySurgicalStatusText:
        values?.surgerySurgicalStatus?.name ||
        values?.surgerySurgicalStatusText ||
        null,
      surgeryAnesthesiaMethod:
        values?.surgeryAnesthesiaMethod?.id ||
        values?.surgeryAnesthesiaMethod ||
        null,
      surgeryAnesthesiaMethodText:
        values?.surgeryAnesthesiaMethod?.name ||
        values?.surgeryAnesthesiaMethodText ||
        null,
      surgeryBloodType:
        values?.surgeryBloodType?.id || values?.surgeryBloodType || null,
      surgeryBloodTypeText:
        values?.surgeryBloodType?.name || values?.surgeryBloodTypeText || null,
      surgeryRhFactor:
        values?.surgeryRhFactor?.id || values?.surgeryRhFactor || null,
      surgeryRhFactorText:
        values?.surgeryRhFactor?.name || values?.surgeryRhFactorText || null,
      surgerySurgicalComplication:
        values?.surgerySurgicalComplication?.id ||
        values?.surgerySurgicalComplication ||
        null,
      surgerySurgicalComplicationText:
        values?.surgerySurgicalComplication?.name ||
        values?.surgerySurgicalComplicationText ||
        null,

      surgeryMortality:
        values?.surgeryMortality?.id || values?.surgeryMortality || null,
      surgeryMortalityText:
        values?.surgeryMortality?.name || values?.surgeryMortalityText || null,
      surgeryDrain: values?.surgeryDrain || null,
      surgeryMeche: values?.surgeryMeche || null,
      surgeryDrainRemovalTime: values?.surgeryDrainRemovalTime
        ? formatDateDTO(values?.surgeryDrainRemovalTime)
        : null,
      surgerySutureRemovalTime: values?.surgerySutureRemovalTime
        ? formatDateDTO(values?.surgerySutureRemovalTime)
        : null,
      surgeryOther: values?.surgeryOther || null,
      surgerySurgicalDescription: values?.surgerySurgicalDescription || null,
      surgerySurgicalProcedureDiagram:
        values?.surgerySurgicalProcedureDiagram || null,
      surgeryPrimarySurgeon1st: values?.surgeryPrimarySurgeon1st || null,
      surgeryPrimarySurgeon2nd: values?.surgeryPrimarySurgeon2nd || null,
      surgerySurgicalAssistant1st: values?.surgerySurgicalAssistant1st || null,
      surgerySurgicalAssistant2nd: values?.surgerySurgicalAssistant2nd || null,
      surgerySurgicalAssistant3rd: values?.surgerySurgicalAssistant3rd || null,
      surgeryAnesthesiologist: values?.surgeryAnesthesiologist || null,
      surgeryAnesthesiaAssistant1st:
        values?.surgeryAnesthesiaAssistant1st || null,
      surgeryAnesthesiaAssistant2nd:
        values?.surgeryAnesthesiaAssistant2nd || null,
      surgeryAssistant: values?.surgeryAssistant || null,
      surgeryInstrumentTechnician: values?.surgeryInstrumentTechnician || null,
      surgeryTechnician: values?.surgeryTechnician || null,
      surgeryMedicalHistorySummary:
        values?.surgeryMedicalHistorySummary || null,
    };
    let valuesNKQ = {
      result: values?.noiDungMauKetQua || "",
      startTime: values?.startTime || null,
      endTime: values?.endTime || null,
      executionTime: values?.executionTime || "",
      minimumTime: values?.minimumTime || "",
      conclusion: values?.conclusion || "",
    };
    let valueSubmit = !currentTab ? valuesPTTT : valuesNKQ;
    let dataSubmit = {
      personId: benhNhanInfo?.thongTinKhamBenh?.patient?.personId,
      orderId: selectedRow?.orderId,
      values: valueSubmit,
    };

    try {
      setIsLoading(true);
      let { data } = await createObsValue(dataSubmit);
      if (!currentTab) {
        let items: any = [];
        ketQuaCLS.map((item: any) => {
          items.push({
            orderId: item?.orderId,
            termId: item?.id,
          });
          item?.subRows &&
            item?.subRows?.map((row: any) => {
              items.push({
                orderId: row?.orderId,
                termId: row?.id,
              });
            });
        });
        let payloadKQCLS = {
          surgeryOrderId: selectedRow?.surgeryOrderId,
          items: items,
        };
        let { data: dataKQCLS } = await createSurgeryOrderParaclinical(
          payloadKQCLS
        );
      }
      toast.success("Lưu thực hiện thành công");
      handleSelectPhieu(selectedPhieu);
      setIsHasData(true);
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const columnsDSChuyenKhoa = [
    { title: "Tên dịch vụ", field: "name", className: "spaces width-40" },
    {
      title: "SL",
      field: "quantity",
      className: "spaces width-10 text-center",
      render: (rowData: any) => {
        const DEFAULT_QUANTITY = 1;
        return DEFAULT_QUANTITY;
      },
    },
    {
      title: "Kết quả",
      field: "ketQua",
      className: "spaces width-20",
      render: (rowData: any, index: number) => {
        return (
          <TableCustomCell
            className="s-2"
            data={
              <Formik
                initialValues={{}}
                onSubmit={() => {}}
                className="spaces w-100 fs-14"
              >
                {!rowData?.obs?.isAllowEdit ? (
                  <span>{rowData?.obs?.value || ""}</span>
                ) : (
                  generateInput(rowData)
                )}
              </Formik>
            }
          />
        );
      },
    },
    { title: "Loại bệnh phẩm", field: "type", className: "spaces width-15" },
    {
      title: "Đối tượng",
      field: "doiTuong",
      className: "spaces width-15",
      render: (rowData: any) => rowData?.objectTypeName || "",
    },
  ];

  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData?.obs?.value) {
        setFieldLoading({ [rowData?.id]: true });
        const submitData = {
          personId: benhNhanInfo?.thongTinKhamBenh?.patient?.personId,
          orderId: rowData?.obs?.orderId,
          values: {
            conclusion: value || null,
          },
        };

        let res = await createObsValue(submitData);
        toast.success("Lưu kết luận thành công");
        const newData = dsXetNghiemDetail?.map((item: any) => {
          return {
            ...item,
            terms: item?.terms?.map((itemTerm: any) => {
              if (itemTerm?.id === rowData?.id) {
                return {
                  ...itemTerm,
                  obs: {
                    ...itemTerm.obs,
                    value: value,
                  },
                };
              }
              return itemTerm;
            }),
          };
        });
        setDsXetNghiemDetail(newData);
      }
    } catch (error) {
      toast.warning("Lỗi lưu kết luận, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  const generateInput = (rowData: any) => {
    return (
      <div className="d-flex align-items-center spaces w-100 py-3">
        <TextField
          className="spaces w-100"
          name="value"
          defaultValue={rowData?.obs?.value || ""}
          disabled={false}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleSaveObs(rowData, e.target.value);
          }}
        />
        {fieldLoading[rowData.id] && <span className="loading-spinner"></span>}
      </div>
    );
  };

  return (
    <Row className="h-100">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.code || "..."}
              </span>{" "}
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {formatDateAdvanceToString(selectedPhieu?.indicationTime)}
              </span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">
                {selectedPhieu?.indicationPerson}
              </span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.requestDeptName || "..."}
              </span>
            </Col>
            <Col
              xs={8}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.provisionalDiagnosisName || "..."}
              </span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            handleSelectRowData={handleSelectPhieu}
            dsPhieu={dsPhieu as []}
            handleContextMenu={(e, row) =>
              handleContextMenu(e, row, CODE_CONTEXT_TAB_XET_NGHIEM.DS_PHIEU)
            }
            listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSChuyenKhoa}
            data={dsXetNghiemDetail || []}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-465`}
            handleDoubleClick={handleViewResult}
          />
        </div>
        <div className="d-flex justify-content-between p-2 gap-3 bg-white">
          <div className="d-flex">
            {visit?.id && patient?.id && selectedPhieu?.id && (
              <>
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định (F2)"
                  fetchExport={inPhieuChiDinhCLS}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                    orderGroupId: selectedPhieu?.id,
                    orderTypeId: ORDER_TYPE_ID.CHUYEN_KHOA,
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                  keyBtn={KEY.F2}
                />
                <Button
                  className="btn-fill spaces mx-3"
                  onClick={() => setOpenPhieuIn({ phieuKetQuaXetNghiem: true })}
                >
                  Phiếu kết quả (F3)
                </Button>
              </>
            )}
            {selectedPhieu && !isConclusion && (
              <WfButton
                tracker={WfConstTrackerClinical.ORDER_GROUP}
                objectId={selectedPhieu?.id}
                additionalFunc={actionChanged}
                handleClick={handleClickWfButton}
              ></WfButton>
            )}
          </div>
        </div>
      </div>
      {dynamicData && (
        <DynamicComponentKB
          data={dynamicData}
          setData={setDynamicData}
          onConfirm={handleConfirmDelete}
        />
      )}
      {shouldOpenResultModal && (
        <ModalThucHienChuyenKhoa
          handleClose={() => {
            setShouldOpenResultModal(false);
            setKetQuaCLS([]);
            setIsHasData(false);
          }}
          handleTraKetQua={() => {}}
          infoBenhNhan={benhNhanInfo?.thongTinKhamBenh}
          selectedRow={selectedRow}
          handleSubmitForm={handleSubmitForm}
          listICD10={listICD10}
          setKetQuaCLS={setKetQuaCLS}
          ketQuaCLS={ketQuaCLS}
          isHasData={isHasData}
          setIsHasData={setIsHasData}
        />
      )}
    </Row>
  );
};

export default TabChuyenKhoa;
