import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import ContextMenu from "../../../component/ContextMenuV2";
import DanhSachPhieu from "../../../component/DanhSachPhieu";
import LabelRequired from "../../../component/LabelRequired";
import ModalOneColumn from "../../../component/ModalOneColumn";
import ModalPhieuIn from "../../../component/ModalPhieuIn";
import { stylePrint } from "../../../component/phieu-in/constant";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { IItemMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ThanhToanModel";
import { CODE, KEY, KEY_DS_DANH_MUC_TIEP_DON, MESSAGE, ORDER_TYPE_ID, PATH_NAME, SELECTION_MODE } from "../../../utils/Constant";
import "../../PhanHeKhamBenh.scss";
import { congKhamColumn } from "../../columns/tab-cong-kham/ColumnTabCongKham";
import PhieuChiDinhCongKham from "../../components/phieu-in/PhieuChiDinhCongKham";
import PhieuKhamBenh from "../../components/phieu-in/PhieuKhamBenh";
import { CODE_CONTEXT_TAB_XET_NGHIEM, listContextDichVuCongKham, listContextPhieuCongKham } from "../../constants/ContextConstants";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import PageChiDinhDichVu from "../../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu";
import { getDSCongKhamDaChiDinh, getDSNhomDVDaChiDinh } from "../../services/ChiDinhDVService";
import moment from "moment";
import { formatDateAdvanceToString } from "../../../utils/FormatUtils";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import ButtonInPhieu from "../../../component/button-in-phieu";
import { inPhieuChiDinhCongKham, inPhieuChuyenPK } from "../../services/PhanHeTiepDonServer";
import { MAP_EXPORT_PATH } from "../../../component/button-in-phieu/constant";

export type KhamBenh = {
    thongTinKhamBenh?: any;
    setThongTinKhamBenh: ((data: any) => void) | undefined;
};

export const TabCongKham = () => {
    const { pathname } = useLocation();
    const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
    let { visit, isConclusion, orderId } = benhNhanInfo?.thongTinKhamBenh || {};
    const [dsPhieu, setDsPhieu] = useState<any[]>([]);
    const [selectedPhieu, setSelectedPhieu] = useState<any>([]);
    const [selectedDichVu, setSelectedDichVu] = useState<any>([]);
    const [openPhieuIn, setOpenPhieuIn] = useState(false);
    const [codeClick, setCodeClick] = useState("");
    const [openModalSuaPhieu, setOpenModalSuaPhieu] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [idTable, setIdTable] = useState("");
    const checkListContext = idTable === CODE_CONTEXT_TAB_XET_NGHIEM.DS_PHIEU ? listContextPhieuCongKham : listContextDichVuCongKham
    const contextRef = useRef<HTMLDivElement | null>(null);
    const [contextMenu, setContextMenu] = useState<null | {
        x: number;
        y: number;
    }>(null);
    const [contextClientX, setContextClientX] = useState<number>(0);
    const [dsXetNghiemDetail, setDsXetNghiemDetail] = useState<any[]>([]);
    const [listICD10, setListICD10] = useState<any>([]);
    const encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
    const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");

    const handleContextMenu = (e: any, row: any, code: string) => {
        e.preventDefault();
        setContextClientX(e.clientX);
        setIdTable(code as string);

        const heightDropList = document.getElementById("drop-list")?.clientHeight || 0;
        const isOnRight = (window.innerWidth - e.clientX) < 200;
        const newX = isOnRight ? e.clientX - 200 : e.clientX;
        const isBottom = (window.innerHeight - e.clientY) < 200;
        const newY = isBottom ? e.clientY - heightDropList : e.clientY;

        setContextMenu({ x: newX, y: newY });
    };

    const handleClickOptionContextMenu = (value: IItemMenu) => {
        // - Chuyển loại công khám -> Chọn công khám phù hợp -> Thay đổi ở cột “Loại công khám” 
        setContextMenu(null);
        setCodeClick(value?.code as string);

    }

    const handleSelectPhieu = async (dataPhieu: any) => {
        try {
            setSelectedPhieu(dataPhieu);
            let { data } = await getDSCongKhamDaChiDinh({
                orderGroupId: dataPhieu?.id,
                type: ORDER_TYPE_ID.CONG_KHAM
            });
            if (CODE.SUCCESS === data?.code) {
                setDsXetNghiemDetail(data?.data || []);
            } else {
                setDsPhieu([]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSelectDichVu = ([data]: any) => {
        setSelectedDichVu(data);
    }

    const renderPhieuIn = (code: string) => {
        const options = {
            [CODE_CONTEXT_TAB_XET_NGHIEM.PHIEU_CHI_DINH]: {
                title: "Phiếu chỉ định",
                component: <PhieuChiDinhCongKham />
            },
            [CODE_CONTEXT_TAB_XET_NGHIEM.PHIEU_KHAM_BENH]: {
                title: "Phiếu khám bệnh",
                component: <PhieuKhamBenh />
            }
        }

        return options[code];
    }

    const handleYesClick = () => {
        setOpenConfirmDialog(false);
        toast.success(MESSAGE.SUCCESS.XOA_PHIEU)
    }

    const updataData = async () => {
        try {
            let { data } = await getDSNhomDVDaChiDinh({
                orderId,
                type: ORDER_TYPE_ID.CONG_KHAM
            })
            let dsPhieuConverted = data?.data?.map((item: any) => {
                return {
                    ...item,
                    date: moment(item?.date).format("DD/MM/YYYY"),
                }
            });
            setDsPhieu(dsPhieuConverted || []);
            setSelectedPhieu(null);
        } catch (error) {
            console.error(error);
        }
    }
    
    const handleGetListICD10 = async () => {
        try {
            let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
            setListICD10(res?.listICD10 || []);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
       if (orderId) {
           updataData();
       }
    }, [benhNhanInfo?.shouldUpdate, orderId]);

    useEffect(() => {
        handleGetListICD10();
    }, []);

    const handlePressKeyShortcuts = (e: any) => {
        switch (e.key) {
            case KEY.F1:
                e.preventDefault();
                setKeyOpenPhieuIn("F1");
                break;
            case KEY.F2:
                e.preventDefault();
                //Hàm của nút In phiếu khám gọi ở đây
                break;
            default:
                return;
        }
    };
  
    useEffect(() => {
      window.addEventListener("keydown", handlePressKeyShortcuts);
      return () => {
        window.removeEventListener("keydown", handlePressKeyShortcuts);
      };
    }, []);

    return (
        <Row className="spaces h-calc-vh-364">
            <div>
                <div className="pt-4 bg-white">
                    <Row className="mx-0">
                        <Col xs={4} className="d-flex align-items-center mb-3">
                            <LabelRequired label="Mã phiếu" className="min-w-100px" />
                            <span className="fw-light text-truncate">{selectedPhieu?.code || "..."}</span>
                        </Col>
                        <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
                            <span className="fw-light text-truncate">{formatDateAdvanceToString(selectedPhieu?.indicationTime)}</span>
                        </Col>
                        <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Người chỉ định" className="min-w-100px" />
                            <span className="fw-light">{selectedPhieu?.indicationPerson}</span>                        
                        </Col>
                        <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
                            <span className="fw-light text-truncate">{selectedPhieu?.requestDeptName || "..."}</span>
                        </Col>
                        <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Trạng thái" className="min-w-100px" />
                            <span className="fw-light text-truncate">{dsXetNghiemDetail?.[0]?.subs?.[0]?.status || "..."}</span>
                        </Col>
                        <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Nơi thực hiện" className="min-w-100px" />
                            <span className="fw-light text-truncate">{selectedPhieu?.fulfillDeptName || "..."}</span>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center text-lable-input mb-3">
                            <LabelRequired label="Chẩn đoán" className="min-w-100px" />
                            <span className="fw-light text-truncate">
                                {(isConclusion && encounterDiagnosis?.icdXPrimary)
                                    ? listICD10?.find((item: any) => item?.code === encounterDiagnosis?.icdXPrimary)?.name
                                    : "..."}
                            </span>
                        </Col>
                    </Row>
                </div>

                <div className="bg-white pt-2 mb-2">
                    <DanhSachPhieu
                        className="mb-2"
                        handleSelectRowData={handleSelectPhieu}
                        dsPhieu={dsPhieu as []} 
                        handleContextMenu={(e, row) => handleContextMenu(e, row, CODE_CONTEXT_TAB_XET_NGHIEM.DS_PHIEU)}
                        isGetFirstData
                    />

                    <div className="tableLichSu border">
                        <TableCustom<any>
                            className={`spaces h-calc-vh-${PATH_NAME.KHAM_BENH === pathname ? "297" : "335"}`}
                            selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                            columns={congKhamColumn}
                            data={dsXetNghiemDetail?.[0]?.subs || []}
                            getSelectedRowsData={handleSelectDichVu}
                            // handleContextMenu={(e, row) => handleContextMenu(e, row, CODE_CONTEXT_TAB_XET_NGHIEM.DS_DICH_VU)}
                        />

                        {contextMenu && (
                            <div ref={contextRef}>
                                <ContextMenu
                                    contextClientX={contextClientX}
                                    data={checkListContext}
                                    x={contextMenu.x}
                                    y={contextMenu.y}
                                    handleClickOptionContextMenu={handleClickOptionContextMenu}
                                    contextRef={contextRef}
                                    setContextMenu={setContextMenu}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-start spaces p-10 gap-8 bg-white position-sticky bottom-0">
                    {selectedPhieu?.id && (
                        <ButtonInPhieu
                            label="Phiếu chỉ định (F1)"
                            fetchExport={inPhieuChiDinhCongKham}
                            params={{
                                id: selectedPhieu?.id,
                                type: MAP_EXPORT_PATH.PHIEU_CHI_DINH_KHAM_BENH
                            }}
                            keyOpenPhieuIn={keyOpenPhieuIn}
                            setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                            keyBtn={KEY.F1}
                        />
                    )}
                    {selectedPhieu?.id && <ButtonInPhieu
                        label="In phiếu"
                        className="min-w-100px"
                        fetchExport={inPhieuChuyenPK}
                        params={{
                            orderGroupId: selectedPhieu?.id,
                        }}
                    />}
                </div>
            </div>

            <ModalPhieuIn
                title={renderPhieuIn(codeClick)?.title}
                size="lg"
                show={openPhieuIn}
                handleCloseDialog={() => setOpenPhieuIn(false)}
                stylePrint={stylePrint}
            >
                {renderPhieuIn(codeClick)?.component}
            </ModalPhieuIn>

            {
                openModalSuaPhieu && <PageChiDinhDichVu handleClose={() => setOpenModalSuaPhieu(false)} />
            }

            <ConfirmDialog
                title="Thông báo"
                yes="Có"
                close="Không"
                message={MESSAGE.CONFIRM.XOA_PHIEU}
                show={openConfirmDialog}
                onYesClick={handleYesClick}
                onCloseClick={() => setOpenConfirmDialog(false)}
            />

            <ModalOneColumn
                show={(CODE_CONTEXT_TAB_XET_NGHIEM.CHUYEN_NGAY_Y_LENH === codeClick)}
                handleCloseDialog={() => setCodeClick("")}
                title={"Cập nhật ngày y lệnh"}
                label={"Ngày y lệnh"}
                type="datetime-local"
                name={CODE_CONTEXT_TAB_XET_NGHIEM.CHUYEN_NGAY_Y_LENH}
            />
        </Row>
    );
};

export default TabCongKham;
