import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { Autocomplete } from '../../../component/Autocomplete';
import LabelRequired from '../../../component/LabelRequired';
import { IBenhNhanV3CDHA } from '../../models/ModelsPhanHeCDHAVaTDCN';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import ModalDSMauKetQuaThucHien from '../../components/modals/ModalDSMauKetQuaThucHien';
import ModalPickTimeBDThucHien from '../../components/modals/ModalPickTimeBDThucHien';
import ModalPickTimeKTThucHien from '../../components/modals/ModalPickTimeKTThucHien';
import { toast } from 'react-toastify';
import InfoPatientRight from '../../../phan-he-kham-benh/components/InfoPatientRight';
import TextField from '../../../component/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from '../../../appContext/AppContext';
import {
	convertFileToHTML,
	getObsValue,
	saveMauKetQuaCDHA,
} from '../../services/PhanHeCDHAVaTDCNService';
import { CODE, RESPONSE_MESSAGE } from '../../../utils/Constant';
import WebcamCapture from '../../../component/webcam-image/WebcamCapture';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';
import NhapKetQuaCDHAEditor from './NhapKetQuaCDHAEditor';
import { TYPE_SAVE_CDHA } from '../../constants/Constants';
import ModalLuuMauKetQua from './ModalLuuMauKetQua';
import { formatDateDTO } from '../../../utils/FormatUtils';
import moment from 'moment';

type Props = {
	handleClose: () => void;
	handleTraKetQua: () => void;
	infoBenhNhan?: IBenhNhanV3CDHA;
	isViewModal?: boolean;
	workFlowStatus?: any;
	handleSubmitForm: (values: any) => void;
	resultData?: any;
	selectedRow?: any;
};

const initialValues: any = {};

type UploadType = {
	code: string;
	extension: string;
};

const FILE_UPLOAD_TYPE: Record<'DOC' | 'IMAGE', UploadType> = {
	DOC: {
		code: 'DOC',
		extension: '.doc, .docx',
	},
	IMAGE: {
		code: 'IMAGE',
		extension: 'image/*',
	},
};

export default function ModalThucHienCDHA({
	handleClose,
	handleTraKetQua,
	infoBenhNhan,
	isViewModal,
	handleSubmitForm,
	resultData,
	selectedRow,
}: Props) {
	const { setIsLoading } = useContext(AppContext);

	const refForm = useRef<any>();
	const uploadFileRef = useRef<HTMLInputElement>(null);
	const editorRef = useRef<any>();
	const importFilenameRef = useRef<string>('');
	const mauKetQuaSelectedRef = useRef<any>();

	const [inforResult, setInforResult] = useState<any>({});

	const [openWebcamCapture, setOpenWebcamCapture] = useState<boolean>();
	const [imgSrcs, setImageSrcs] = useState<any[]>([]);
	const [shouldOpenModalPickTimeBDThucHien, setShouldOpenModalPickTimeBDThucHien] =
		useState<boolean>(false);
	const [shouldOpenModalPickTimeKTThucHien, setShouldOpenModalPickTimeKTThucHien] =
		useState<boolean>(false);
	const [noiDungMauKetQua, setNoiDungMauKetQua] = useState<string>('');
	const [ngay, setNgay] = useState({
		batDau: '',
		ketThuc: '',
	});
	const [openLuuMauKetQuaDialog, setOpenLuuMauKetQuaDialog] = useState<boolean>(false);
	const [openDanhSachMauKetQuaDialog, setOpenDanhSachMauKetQuaDialog] = useState(false);

	useEffect(() => {
		if (resultData) {
			setInforResult(resultData);
			if (resultData?.result) {
				setNoiDungMauKetQua(resultData.result);
			}
		} else {
			handleGetResult();
		}
	}, []);

	const handleCloseModalPickTimeBDThucHien = () => {
		setShouldOpenModalPickTimeBDThucHien(false);
	};

	const handleCloseModalPickTimeKTThucHien = () => {
		setShouldOpenModalPickTimeKTThucHien(false);
	};

	const handleLuuInTraKetQua = () => {
		handleTraKetQua();
		toast.success('Lưu / In / Trả kết quả thành công');
		handleClose();
	};

	const handleSelectMauKetQua = (mauKetQua: any) => {
		setOpenDanhSachMauKetQuaDialog(false);
		setNoiDungMauKetQua(mauKetQua);
		toast.success('Chọn mẫu kết quả thành công');
	};

	const validationSchema = Yup.object({});

	const handleGetResult = async () => {
		let searchObject = {
			orderId: infoBenhNhan?.orders
				? infoBenhNhan?.orders[0]?.orderId
				: selectedRow?.obs?.orderId,
		};
		try {
			setIsLoading(true);
			let { data } = await getObsValue(searchObject);
			if (data?.code === CODE.SUCCESS) {
				if (!data?.data?.startTime && infoBenhNhan?.executionStartTime) {
					let startTime = formatDateDTO(infoBenhNhan?.executionStartTime || new Date());
					handleSubmitForm({
						startTime,
					});
					setInforResult({
						...data?.data,
						startTime,
					});
				} else {
					setInforResult(data?.data);
				}
				if (data?.data?.result) {
					setNoiDungMauKetQua(data?.data?.result);
				}
			}
		} catch (error) {
			toast.error('Xảy ra lỗi, vui lòng thử lại!');
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeInfor = (value: any, name: string) => {
		setInforResult({
			...inforResult,
			[name]: value,
		});
	};

	useEffect(() => {
		if (!inforResult?.startTime && infoBenhNhan?.executionStartTime) {
			setInforResult({
				...inforResult,
				startTime: formatDateDTO(infoBenhNhan?.executionStartTime),
			});
		}
	}, [infoBenhNhan]);

	const onImportFileKetQua = async (e: ChangeEvent<HTMLInputElement>) => {
		const fileUpload = e.target.files;

		if (!fileUpload?.length) return;

		const file = fileUpload[0];
		importFilenameRef.current = file.name;

		const formData = new FormData();
		formData.append('file', file);

		try {
			setIsLoading(true);
			const { code, data } = await convertFileToHTML(formData);

			if (code !== CODE.SUCCESS) {
				toast.error(RESPONSE_MESSAGE.ERROR);
				return;
			}

			setNoiDungMauKetQua(data);
			setOpenLuuMauKetQuaDialog(true);
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onExportFileKetQua = async () => {
		try {
			if (noiDungMauKetQua) {
				const blobResult = (await asBlob(noiDungMauKetQua)) as Blob;
				saveAs(blobResult, importFilenameRef.current);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onLuuMauKetQua = async (value: any) => {
		try {
			const isUpdate = mauKetQuaSelectedRef.current?.id;

			const { code, data } = await saveMauKetQuaCDHA({
				id: mauKetQuaSelectedRef.current?.id ?? null,
				termId: infoBenhNhan?.orders[0].term.parentId,
				name: value.tenMauKetQua,
				content: noiDungMauKetQua,
			});

			if (CODE.SUCCESS !== code) {
				toast.error(data[0]?.errorMessage);
				return;
			}

			toast.success(
				isUpdate ? 'Cập nhật mẫu kết quả thành công' : 'Lưu mẫu kết quả thành công'
			);
			setOpenLuuMauKetQuaDialog(false);
			mauKetQuaSelectedRef.current = null;
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		switch (e.target.name) {
			case FILE_UPLOAD_TYPE.DOC.code:
				onImportFileKetQua(e);
				break;

			case FILE_UPLOAD_TYPE.IMAGE.code:
				const files = e.target.files;
				if (files) {
					const imageURIList = Array.from(files).map((file: File) => ({
						src: URL.createObjectURL(file),
					}));
					setImageSrcs(imageURIList);
				}
				break;

			default:
				break;
		}

		e.target.value = '';
	};

	const onUploadButtonClick = (type: keyof typeof FILE_UPLOAD_TYPE) => {
		if (!uploadFileRef.current) return;

		switch (type) {
			case 'DOC':
				uploadFileRef.current.name = FILE_UPLOAD_TYPE.DOC.code;
				uploadFileRef.current.accept = FILE_UPLOAD_TYPE.DOC.extension;
				uploadFileRef.current.multiple = false;
				uploadFileRef.current.click();
				break;

			case 'IMAGE':
				uploadFileRef.current.name = FILE_UPLOAD_TYPE.IMAGE.code;
				uploadFileRef.current.multiple = true;
				uploadFileRef.current.accept = FILE_UPLOAD_TYPE.IMAGE.extension;
				uploadFileRef.current.click();
				break;

			default:
				break;
		}
	};

	const handleCountDate = () => {
		let startDate = moment(inforResult?.startTime);
		let endDate = moment(inforResult?.endTime ? inforResult?.endTime : new Date());

		let duration = moment.duration(endDate.diff(startDate));

		let days = Math.floor(duration.asDays());
		let hours = duration.hours();
		let minutes = duration.minutes();
		let seconds = duration.seconds();
		let minutesDifference = [];

		if (days > 0) minutesDifference.push(`${days} ngày`);
		if (hours > 0) minutesDifference.push(`${hours} giờ`);
		if (minutes > 0) minutesDifference.push(`${minutes} phút`);
		if (seconds > 0) minutesDifference.push(`${seconds} giây`);

		return minutesDifference.join(', ');
	};

	useEffect(() => {
		if (inforResult?.startTime && inforResult?.endTime) {
			setInforResult((prev: any) => ({
				...prev,
				executionTime: handleCountDate(),
			}));
		}
	}, [inforResult?.startTime, inforResult?.endTime]);

	const handleSubmitAll = (handleSubmit: any, typeSubmit: string) => {
		handleSubmit();

		let objectSubmit = {
			noiDungMauKetQua,
			startTime: formatDateDTO(inforResult?.startTime) || null,
			endTime:
				formatDateDTO(inforResult?.endTime ? inforResult?.endTime : new Date()) || null,
			executionTime: inforResult?.executionTime || null,
			minimumTime: inforResult?.minimumTime || null,
			conclusion: inforResult?.conclusion || null,
			actionType: typeSubmit,
			handleGetResult: handleGetResult,
		};

		handleSubmitForm(objectSubmit);
	};

	const onLuuMauKetQuaButtonClick = () => {
		if (!noiDungMauKetQua) {
			toast.warn('Chưa nhập nội dung mẫu kết quả');
			return;
		}
		setOpenLuuMauKetQuaDialog(true);
	};

	return (
		<Modal
			show={true}
			onHide={handleClose}
			size="xl"
			centered
			className="modal-xl-bigger"
			contentClassName="h-100"
			enforceFocus={false}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={() => {}}
				innerRef={refForm}
			>
				{({ values, handleSubmit, setFieldValue, errors, touched, resetForm }) => (
					<Form className="bg-white">
						<Modal.Header className="p-4 header-modal header-modal-cdha">
							<Modal.Title>Kết quả chẩn đoán hình ảnh</Modal.Title>
							<button className="btn-close" onClick={handleClose}></button>
						</Modal.Header>
						<Modal.Body className="dialog-body border-bottom menu-button-cdha">
							<div
								className={`flex gap-3 underline spaces ${
									isViewModal ? 'h-50' : ''
								}`}
							>
								{!isViewModal && (
									<>
										<input
											type="file"
											hidden
											ref={uploadFileRef}
											accept=".doc,.docx"
											onChange={onFileChange}
										/>
										<Dropdown className="dropdown-btn menu-icon">
											<Dropdown.Toggle className="btn-outline">
												<i className="bi bi-list m-0 p-0"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														onUploadButtonClick('DOC');
													}}
												>
													Nhập file kết quả
												</Dropdown.Item>
												<Dropdown.Item onClick={onExportFileKetQua}>
													Xuất file kết quả
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item
													onClick={() => {
														onUploadButtonClick('IMAGE');
													}}
												>
													Lấy ảnh từ máy tính
												</Dropdown.Item>
												<Dropdown.Item>
													Lấy ảnh từ kết nối chẩn đoán hình ảnh
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item>
													Cấu hình thư viện nhận ảnh
												</Dropdown.Item>
												<Dropdown.Item>
													Cấu hình tự động crop ảnh
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item>Xuất raw kết quả</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										<Button
											className="btn-fill"
											onClick={() => {
												setOpenDanhSachMauKetQuaDialog(true);
											}}
										>
											Mẫu kết quả
										</Button>
										<Button
											className="btn-fill"
											onClick={onLuuMauKetQuaButtonClick}
										>
											{mauKetQuaSelectedRef.current?.id
												? 'Cập nhật mẫu kết quả'
												: 'Lưu mẫu kết quả'}
										</Button>
										<Button className="btn-fill">PACS</Button>
										<Button
											className="btn-fill min-w-50px"
											onClick={(e: any) => {
												handleSubmitAll(
													handleSubmit,
													TYPE_SAVE_CDHA.SAVE_PRINT.code
												);
											}}
										>
											{TYPE_SAVE_CDHA.SAVE_PRINT.name}
										</Button>
										<Button
											className="btn-fill min-w-50px"
											onClick={(e: any) => {
												handleSubmitAll(
													handleSubmit,
													TYPE_SAVE_CDHA.SAVE_PRINT_RETURN.code
												);
											}}
										>
											{TYPE_SAVE_CDHA.SAVE_PRINT_RETURN.name}
										</Button>
										<Button
											className="btn-fill min-w-50px"
											onClick={() => setOpenWebcamCapture(true)}
										>
											Chụp ảnh
										</Button>
									</>
								)}
								<div className="text-break fw-500 py-2 px-5 text-end position-absolute top-0 bottom-0 end-0 spaces w-40">
									<InfoPatientRight benhNhanInfo={infoBenhNhan} />
								</div>
							</div>
						</Modal.Body>
						<Modal.Body className="dialog-body padding-3-20">
							<Row className="h-100">
								<Col xs={4} className="flex border">
									<div className="flex-item-start pt-5 spaces w-100">
										<Row>
											<Col xs={12}>
												<div className="label-input-group mt-3 d-flex">
													<LabelRequired
														label="Bắt đầu"
														className="label fw-500 spaces fw-bold w-30"
													/>
													<TextField
														className="input flex-1"
														name="startTime"
														labelClassName="max-w-90px"
														type="dateTime-Local"
														value={
															formatDateDTO(inforResult?.startTime) ||
															''
														}
														disabled={isViewModal}
														onChange={(e: any) =>
															handleChangeInfor(
																e.target.value,
																'startTime'
															)
														}
													/>
												</div>
											</Col>
											<Col xs={12}>
												<div className="label-input-group mt-3 d-flex">
													<LabelRequired
														label="Kết thúc"
														className="label fw-500 spaces fw-bold w-30"
													/>
													<TextField
														className="input flex-1"
														name="endTime"
														labelClassName="max-w-90px"
														type="dateTime-Local"
														value={
															formatDateDTO(inforResult?.endTime) ||
															''
														}
														disabled={isViewModal}
														onChange={(e: any) =>
															handleChangeInfor(
																e.target.value,
																'endTime'
															)
														}
													/>
												</div>
											</Col>
											<Col xs={12}>
												<div className="label-input-group mt-3 d-flex">
													<LabelRequired
														label="TG thực hiện"
														className="label fw-500 spaces fw-bold w-30"
													/>
													<TextField
														className="input flex-1"
														name="executionTime"
														labelClassName="max-w-90px"
														type="text"
														value={
															inforResult?.executionTime
																? inforResult?.executionTime
																: ''
														}
														disabled={true}
														onChange={(e: any) =>
															handleChangeInfor(
																e.target.value,
																'executionTime'
															)
														}
													/>
												</div>
											</Col>
											<Col xs={12}>
												<div className="label-input-group mt-3 d-flex">
													<LabelRequired
														label="TG tối thiểu"
														className="label fw-500 spaces fw-bold w-30"
													/>
													<TextField
														className="input flex-1"
														name="minimumTime"
														labelClassName="max-w-90px"
														type="text"
														value={inforResult?.minimumTime || ''}
														disabled={isViewModal}
														onChange={(e: any) =>
															handleChangeInfor(
																e.target.value,
																'minimumTime'
															)
														}
													/>
												</div>
											</Col>
											<Col xs={12}>
												<div className="label-input-group mt-3 d-flex">
													<LabelRequired
														label="Máy thực hiện"
														className="label fw-500 spaces fw-bold w-30"
													/>
													<Autocomplete
														className="input flex-1"
														options={[]}
														name="mayThucHien"
														isDisabled={isViewModal}
													/>
												</div>
											</Col>
											<Col xs={12}>
												<div className="w-100 spaces mt-10 image-capture-preview">
													{imgSrcs.map((img: any, i: number) => (
														<img
															key={i}
															src={img?.src}
															alt="CDHA"
															className="w-100 h-auto d-block"
														/>
													))}
												</div>
											</Col>
										</Row>
									</div>
								</Col>
								<Col
									xs={8}
									className="d-flex ckeditor-custom modal-thuc-hien-cdha pe-0"
								>
									<Row className="space w-100">
										<Col xs={12} className="min-h-500px h-100">
											<NhapKetQuaCDHAEditor
												value={noiDungMauKetQua}
												disabled={isViewModal}
												onEditorChange={(value: string, editor: any) => {
													setNoiDungMauKetQua(value);
												}}
												onInit={(_evt, editor) => {
													editorRef.current = editor;
												}}
											/>
										</Col>

										<Col xs={12}>
											<div className="d-flex align-items-center gap-3 mt-2">
												<LabelRequired
													label="Kết luận"
													className="label fw-500 spaces fw-bold min-w-50"
												/>
												<TextField
													className="input spaces flex-10"
													name="conclusion"
													labelClassName="max-w-90px"
													type="text"
													value={inforResult?.conclusion || ''}
													disabled={isViewModal}
													onChange={(e: any) =>
														handleChangeInfor(
															e.target.value,
															'conclusion'
														)
													}
												/>
												{!isViewModal && (
													<span className="spaces flex-2 d-flex gap-3">
														<Button className="btn-fill min-w-75px me-3">
															Gợi ý
														</Button>
														<Button className="btn-danger min-w-75px">
															Xoá
														</Button>
													</span>
												)}
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Modal.Body>
					</Form>
				)}
			</Formik>

			{openDanhSachMauKetQuaDialog && (
				<ModalDSMauKetQuaThucHien
					selectMauKetQua={handleSelectMauKetQua}
					handleClose={() => {
						setOpenDanhSachMauKetQuaDialog(false);
					}}
					thongTinPhieu={{
						termId: infoBenhNhan?.orders[0].term.parentId,
					}}
					onEdit={(row: any) => {
						mauKetQuaSelectedRef.current = row;
						setNoiDungMauKetQua(row.content);
					}}
				/>
			)}

			{shouldOpenModalPickTimeBDThucHien && (
				<ModalPickTimeBDThucHien
					handleClose={() => handleCloseModalPickTimeBDThucHien()}
					ngay={ngay}
					handleSetNgay={setNgay}
				/>
			)}

			{shouldOpenModalPickTimeKTThucHien && (
				<ModalPickTimeKTThucHien
					handleClose={() => handleCloseModalPickTimeKTThucHien()}
					ngay={ngay}
					handleSetNgay={setNgay}
				/>
			)}

			{openWebcamCapture && (
				<WebcamCapture
					open={openWebcamCapture}
					handleClose={() => setOpenWebcamCapture(false)}
					imgSrcs={imgSrcs}
					setImageSrcs={setImageSrcs}
				/>
			)}

			{openLuuMauKetQuaDialog && (
				<ModalLuuMauKetQua
					open={openLuuMauKetQuaDialog}
					onClose={() => setOpenLuuMauKetQuaDialog(false)}
					onSave={onLuuMauKetQua}
					tenMauKetQua={mauKetQuaSelectedRef.current?.name}
				/>
			)}
		</Modal>
	);
}
