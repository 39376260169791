import { CellProps, Column } from "react-table";
import { formatMoney } from "../../../utils/FormatUtils";

const formatCellNumber = (value: string) => {
	return value ? formatMoney(Number(value)) : '';
};

export const COLUMNS: Column<any>[] = [
	{
		Header: 'Mã Thuốc',
		accessor: 'code',
		width: 100,
	},
	{
		Header: 'Tên Thuốc',
		accessor: 'name',
		width: 250,
	},
	{
		Header: 'Hàm Lượng',
		accessor: 'dosage',
		width: 150,
	},
	{
		Header: 'Hoạt Chất',
		accessor: 'actIngName',
		width: 150,
	},
	{
		Header: 'Đơn vị',
		accessor: 'unitofMeasureName',
		width: 120,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-center">{value}</div>
			)
		},
	},
	{
		Header: 'Khả dụng',
		accessor: 'iventory',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatCellNumber(value)}</div>
			)
		},
	},
	{
		Header: 'Giá BHYT',
		accessor: 'BHYTPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatCellNumber(value)}</div>
			)
		},
	},
	{
		Header: 'Giá VP',
		accessor: 'hospitalFeePrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatCellNumber(value)}</div>
			)
		},
	},
	{
		Header: 'Giá dịch vụ',
		accessor: 'requestedPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatCellNumber(value)}</div>
			)
		},
	},
	{
		Header: 'Loại Thuốc',
		accessor: 'drugTypeName',
		width: 200,
	},
];
